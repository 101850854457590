<template>
  <div>
    <h3 class="text-denim-700 text-2xl font-normal text-center">
      Things you need to do
    </h3>
    <p
      v-if="collectionMethod === 'FPK'"
      class="text-grey-700 mt-8 mb-8 leading-normal"
    >
      Here's an overview of the process of taking your blood test. The important
      thing is to activate your kit, and then as soon as possible afterwards
      collect your sample.
    </p>
    <p v-else class="text-grey-700 mt-8 mb-8 leading-normal">
      Here's an overview of the process of taking your blood test. The important
      thing is to make sure you attend your appointment to take your sample, and
      then activate your kit and complete the questionnaire.
    </p>
    <KitProgress
      :kit="activationStore.kit"
      :test="activationStore.test"
      :collection-method="collectionMethod"
    />
    <p
      v-if="collectionMethod === 'FPK'"
      class="text-grey-700 mt-8 mb-8 leading-normal"
    >
      Once you've watched the video click the button below, enter your details,
      and activate your test.
    </p>
    <p v-else class="text-grey-700 mt-8 mb-8 leading-normal">
      Click the button below, enter your details, and activate your test.
    </p>
    <router-link
      :to="{ name: 'activate-details' }"
      class="mt-4 rounded block text-center w-full py-2 px-4 font-semibold bg-cobalt-500 text-white"
    >
      Continue
    </router-link>
  </div>
</template>

<script setup>
import KitProgress from "@/components/KitProgress.vue";
import { useActivationStore } from "@/stores/activation";
import { parseSku } from "@/services/kit";

const activationStore = useActivationStore();
const { collectionMethod } = parseSku(activationStore.kit.sku);
</script>
