<template>
  <router-link
    v-if="link"
    class="tr--hover-animate border-grey-200 hover:shadow-key-hover last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer flex items-center justify-between"
    :class="`${link} ${border ? 'border-b' : ''}`"
    :to="`${$route.path}/${link}`"
  >
    <div class="pl-6 py-5 text-grey-500 whitespace-no-wrap pr-2 w-2/6">
      {{ title }}
    </div>

    <div class="w-3/6 text-grey-700">
      <slot />
    </div>

    <div class="block pr-6 py-5 flex justify-end text-cobalt-500 w-1/6">
      <AppIcon :container-classes="['w-5', 'h-5']" :icon="icon" />
    </div>
  </router-link>
  <div
    class="border-b last:border-b-0 border-grey-200 flex items-center justify-between"
    ref="tooltipTrigger"
    @click="showToolTip"
    v-else
  >
    <div class="pl-6 p-5 text-grey-500 w-2/5">
      <div class="whitespace-no-wrap">{{ title }}</div>
      <div
        ref="tooltip"
        v-show="isShowingToolTip"
        class="bg-white p-2 shadow-key rounded relative tooltip-arrow text-grey-700"
        :style="{ display: isShowingToolTip ? 'block' : 'none' }"
      >
        <slot name="tooltip" />
      </div>
    </div>

    <div class="break-all md:break-normal w-2/5 py-2">
      <slot />
    </div>

    <div class="flex justify-end w-1/5">
      <div class="block pr-6 py-5 flex justify-end">
        <AppIcon :container-classes="['w-5', 'h-5']" :icon="icon" />
      </div>
    </div>
  </div>
</template>

<script>
// import { createPopper } from "@popperjs/core";

export default {
  name: "DetailsTr",

  data() {
    return {
      isShowingToolTip: false,
      tooltip: undefined,
    };
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "chevron-right",
    },
    link: {
      type: [String, Boolean],
      required: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    if (!this.link) {
      //   this.tooltip = createPopper(
      //     this.$refs.tooltipTrigger,
      //     this.$refs.tooltip,
      //     {
      //       placement: "top",
      //     }
      //   );
    }
  },

  methods: {
    showToolTip() {
      this.isShowingToolTip = true;
      this.tooltip.update();
      setTimeout(() => {
        window.addEventListener("click", this.hideToolTip);
      }, 1);
    },

    hideToolTip() {
      this.isShowingToolTip = false;
      window.removeEventListener("click", this.hideToolTip);
    },
  },
};
</script>

<style>
.tr--hover-animate {
  transition: box-shadow ease 0.2s;
}
.tooltip-arrow:before {
  content: "";
  position: absolute;
  right: calc(50% - 7px);
  top: 100%;
  border-top: 10px solid #ffffff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
</style>
