<template>
  <ImagePanel :image-src="image">
    <router-view v-slot="{ Component }">
      <transition name="slide-left" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </ImagePanel>
</template>

<script>
import ImagePanel from "@/components/LoginPartials/ImagePanel.vue";

export default {
  components: { ImagePanel },
  data() {
    return {
      image: "",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.image = to.meta.image;
      },
    },
  },
};
</script>

<style>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
