<template>
  <div v-if="loadingStatus === 'loaded'" class="h-full flex">
    <MobileSidebar
      :modal-open="modalOpen"
      :navigation-items="navigation"
      :sidebar-open="sidebarOpen"
      @open-modal="
        (sidebarOpen = false), (modalOpen = false), (modalOpen = true)
      "
      @sidebar-closed="sidebarOpen = false"
    />

    <!-- Static sidebar for desktop -->
    <DesktopSidebar
      @discover-ancestry="handleDiscoverAncestry"
      :modal-open="modalOpen"
      :navigation-items="navigation"
      @open-modal="(modalOpen = false), (modalOpen = true)"
    />
    <div class="flex flex-col min-w-0 flex-1 overflow-hidden">
      <div class="lg:hidden">
        <div class="flex items-center justify-between px-4 py-1.5 bg-denim-700">
          <router-link :to="{ name: 'dashboard' }">
            <!-- <img
              class="h-8 w-auto"
              src="@/../public/Logo.png"
              alt="Living DNA"
            /> -->
          </router-link>
          <div>
            <button
              type="button"
              class="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
              @click="sidebarOpen = true"
            >
              <span class="sr-only">Open sidebar</span>
              <AppIcon icon="menu" class="text-white" />
            </button>
          </div>
        </div>
      </div>
      <portal-target name="navigation" multiple />
      <div class="flex-1 relative z-0 flex overflow-hidden bg-grey-50">
        <main
          class="flex-1 relative z-0 overflow-y-auto focus:outline-none"
          id="main"
        >
          <!-- Start main area-->
          <div class="absolute inset-0">
            <router-view />
          </div>
          <!-- End main area -->
        </main>
        <portal-target name="sidebar" />
      </div>
    </div>

    <ProfileSwitcherModal :open="modalOpen" @modal-closed="modalOpen = false" />
    <ThePortalHelpButton @modal-opened="sidebarOpen = false" />
    <AncestryUpsellModal
      :show="ancestryModalOpen"
      @closed="ancestryModalOpen = false"
    />
  </div>
</template>

<script>
import ThePortalHelpButton from "./ThePortalHelpButton.vue";
import DesktopSidebar from "./DesktopSidebar.vue";
import MobileSidebar from "./MobileSidebar.vue";
import { useProfileStore } from "@/stores/profile";
import { useResultsStore } from "@/stores/results";
import { usePatientStore } from "@/stores/patient";
import { useLoading } from "@/stores/loading";
import ProfileSwitcherModal from "@/components/ProfileSwitcherModal.vue";
import AncestryUpsellModal from "@/components/AncestryUpsellModal.vue";
import { enableBlood, enableGenetics } from "@/services/store";

import { ref, computed } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    DesktopSidebar,
    MobileSidebar,
    ThePortalHelpButton,
    ProfileSwitcherModal,
    AncestryUpsellModal,
  },
  setup() {
    const sidebarOpen = ref(false);
    const modalOpen = ref(false);
    const ancestryModalOpen = ref(false);
    const { loadingStatus, finishLoading } = useLoading();
    const router = useRouter();

    const profileStore = useProfileStore();
    const resultsStore = useResultsStore();
    const patientStore = usePatientStore();

    console.log(enableBlood(patientStore.patient));

    const navigation = computed(() => [
      { name: "Dashboard", href: "/dashboard", icon: "home" },
      ...(enableBlood(patientStore.patient)
        ? [{ name: "Blood Tests", href: "/blood-tests", icon: "heart" }]
        : []),
      ...(enableGenetics()
        ? [{ name: "Genetics", href: "/genetics", icon: "helix" }]
        : []),
      // { name: "Genetics", href: "/genetics", icon: "helix" },
      { name: "Store", href: "/store", icon: "shopping-cart" },
      { name: "Health profile", href: "/profile", icon: "sliders" },
      { name: "Account", href: "/account", icon: "settings" },
    ]);

    // // Load required data for portal to function
    Promise.all([profileStore.fetchProfiles(), patientStore.getPatient()])
      .then(() => resultsStore.setBloodResults(patientStore.patient))
      .catch((e) => {
        if (e.message === "No tests for this patient yet") {
          console.log(e.message);
          // router.push({ name: "dashboard-start" });
        }
        if (e?.response?.status == 404) {
          console.log("No health profile found, need to activate");
          router.push({ name: "create-profile" });
        }
        console.error(e);
      })
      .finally(finishLoading);

    function handleDiscoverAncestry() {
      if (!profileStore.profiles.length) {
        return (ancestryModalOpen.value = true);
      }
      window.location.href = "/dashboard";
    }

    return {
      navigation,
      sidebarOpen,
      modalOpen,
      loadingStatus,
      ancestryModalOpen,
      handleDiscoverAncestry,
    };
  },
};
</script>
