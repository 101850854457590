import axios from "axios";
import { usePatientStore } from "@/stores/patient";
import { sum } from "lodash";
import { useOrderStore } from "@/stores/orders";

const { VUE_APP_PREPR_URL, VUE_APP_AUTH_PROVIDER } = process.env;

const ax = axios.create();

const queryString = `
  _id
  product_name
  product_type
  subscription_options {
    body
  }
  _slug
  header_image {
    _id
    url
  }
  product_icon {
  _id
  url
  }
  product_photo {
    _id
    url
  }
  product_header
  product_introduction
  product_detail_header
  product_details
  product_page_tabs {
    name
    body
    icon {
      url
    }
    image {
      url
    }
  }
  how_it_works_header
  how_it_works_widget {
    number
    icon {
      url
    }
    title
    body
  }
  faq_header
  faqs {
    question
    answer
  }
  testimonials_header
  testimonials {
    author
    body
    rating
  }
  price_string
  price
  subscription_price_string
  subscription_price
  product_descriptor_list
  doctor_profile_image {
    _id
    url
  }
  doctor_name_and_role
  whats_included {
    panel
    markers
  }
  age_tags {
    body
  }
  gender_tags {
    body
  }
  wellbeing_goal_tags {
    body
  }
  collection_methods {
    _id
    _slug
    name
    description
    price_string
    price
    post_order_text
    icon {
      url
    }
  }
  thumbnail_image {
    url
  }
  testimonials {
    author
    body
    rating
    background {
      url
    }
  }  
  product_type
  doctor_text
  aus_price_string
  can_price_string
  aus_price_string
  eur_price_string
  aus_subscription_price_string
  can_subscription_price_string
  usa_subscription_price_string
  eur_subscription_price_string
`;

export async function getAllProducts(variables: {
  where: {
    age_tags_any: [];
    gender_tags_any: [];
    wellbeing_goal_tags_any: [any];
  };
}) {
  const res = await ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        query ExampleQuery($where: ProductPageWhereInput) {
          ProductPages(where: $where, limit: 500) {
            items {
              ${queryString}
            }
          }
        }
      `,
      variables: {
        where: {
          age_tags_any: variables.where.age_tags_any,
          gender_tags_any: variables.where.gender_tags_any,
        },
      },
    },
  });
  const orderStore = useOrderStore();
  const orders = await orderStore.getOrders();
  const orderedProductSkus = orders
    .map((order: any) => ({
      ...order,
      json: JSON.parse(order.rawJson),
    }))
    .flatMap((order: any) => {
      return order.json.cart.rows.map((cartItem: any) => {
        return cartItem.sku;
      });
    })
    .map((sku: string) => {
      const [company, type, region] = sku.split("-");
      return type;
    });
  const patientStore = usePatientStore();
  return res?.data?.data?.ProductPages?.items
    .map((item: any) => {
      const match = item.wellbeing_goal_tags.filter((tag: any) => {
        return variables.where.wellbeing_goal_tags_any.includes(tag.body);
      });

      const [company, type, region] = item._slug.split("-");

      const purchased = orderedProductSkus
        .map((s: string) => {
          return s.toLowerCase();
        })
        .includes(type.toLowerCase());

      return {
        includedInWellbeingGoals: !!match.length,
        purchased: item.product_type === "Genetic Test" ? purchased : false,
        ...item,
      };
    })
    .filter((product: any) => {
      if (
        product.product_type === "Genetic Test" &&
        (!patientStore.patient.geneticTest ||
          !patientStore.patient.geneticTest.result.hasAnyResult)
      ) {
        return false;
      }
      return true;
    })
    .sort((item: any) => {
      return item.includedInWellbeingGoals ? -1 : 1;
    });
}

export async function getProductData(productId: string) {
  const res = await ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        query ProductPage($productPageId: String) {
          ProductPage(id: $productPageId) {
            ${queryString}
          }
        }
      `,
      variables: {
        productPageId: productId,
      },
    },
  });
  return res.data.data.ProductPage;
}

export function getHeaderText(headerId: string) {
  return ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        {
          HeaderText (id: "${headerId}") { 
            _id
            title
            body
            author { 
              __typename
            name
            biography
            photos {
              url
            }
            signature {
              url
            }
          }
          }
        }
      `,
    },
  });
}

export function getStoreCategories() {
  return ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
      {
        StoreCategories {
          items {
            _id
            name
            cover_image { 
              _id
              url
            }
            icon { 
              _id
              url
            }
            sample_name
            sample_icon { 
              _id
              url
            }
            description
            minimum_price_string
            minimum_price_string
            aus_minimum_price_string
            can_minimum_price_string
            usa_minimum_price_string
            eur_minimum_price_string
            product_photo { 
              _id
              url
            }
            products { 
              __typename
            }
          }
        }
      }
      `,
    },
  });
}

export function enableBlood(patient: { region: string }) {
  return patient.region === "GBR";
}

export function enableGenetics() {
  return VUE_APP_AUTH_PROVIDER !== "clerk";
}

interface ProductWithPriceStrings {
  price_string: string;
  aus_price_string: string;
  usa_price_string: string;
  can_price_string: string;
  eur_price_string: string;
}

interface ProductWithSubscriptionPriceStrings {
  subscription_price_string: string;
  aus_subscription_price_string: string;
  can_subscription_price_string: string;
  usa_subscription_price_string: string;
  eur_subscription_price_string: string;
}

interface ProductWithMinimumPriceStrings {
  minimum_price_string: string;
  aus_minimum_price_string: string;
  can_minimum_price_string: string;
  usa_minimum_price_string: string;
  eur_minimum_price_string: string;
}

//usa aus can eur
export function getPrice(product: ProductWithPriceStrings) {
  const patientStore = usePatientStore();
  const region = patientStore.patient.region;
  if (region === "GBR") {
    return product.price_string;
  }
  if (region === "AUS") {
    return product.aus_price_string;
  }

  if (region === "CAN") {
    return product.can_price_string;
  }

  if (region === "USA") {
    return product.usa_price_string;
  }

  if (region === "EUR") {
    return product.eur_price_string;
  }
}

export function getSubscriptionPrice(
  product: ProductWithSubscriptionPriceStrings
) {
  const patientStore = usePatientStore();
  const region = patientStore.patient.region;
  if (region === "GBR") {
    return product.subscription_price_string;
  }
  if (region === "AUS") {
    return product.aus_subscription_price_string;
  }

  if (region === "CAN") {
    return product.can_subscription_price_string;
  }

  if (region === "USA") {
    return product.usa_subscription_price_string;
  }

  if (region === "EUR") {
    return product.eur_subscription_price_string;
  }
}

export function getMinimumPrice(product: ProductWithMinimumPriceStrings) {
  const patientStore = usePatientStore();
  const region = patientStore.patient.region;
  if (region === "GBR") {
    return product.minimum_price_string;
  }
  if (region === "AUS") {
    return product.aus_minimum_price_string;
  }

  if (region === "CAN") {
    return product.can_minimum_price_string;
  }

  if (region === "USA") {
    return product.usa_minimum_price_string;
  }

  if (region === "EUR") {
    return product.eur_minimum_price_string;
  }
}

export function getCurrency() {
  const patientStore = usePatientStore();
  const region = patientStore.patient.region;
  if (region === "GBR") {
    return "gbp";
  }
  if (region === "AUS") {
    return "aus";
  }

  if (region === "CAN") {
    return "can";
  }

  if (region === "USA") {
    return "usa";
  }

  if (region === "EUR") {
    return "eur";
  }
}
