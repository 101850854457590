<template>
  <form @submit.prevent="updateDateOfBirth" class="space-x">
    <h1 class="text-denim-700 font-normal text-2xl mb-2">Date of birth</h1>

    <DateOfBirth v-model="dateOfBirth" />

    <button
      class="block bg-cobalt-500 py-2 px-4 rounded text-white mt-4"
      :class="{ loading: savingStatus === 'saving' }"
      type="submit"
    >
      Submit
    </button>
  </form>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { usePatientStore } from "@/stores/patient";
import { useRouter } from "vue-router";
import DateInput from "@/components/BaseDateInput.vue";
import DateOfBirth from "@/components/Activation/DateOfBirth.vue";

const props = defineProps({
  patient: Object,
});

const patientStore = usePatientStore();
const router = useRouter();

const dateOfBirth = ref(props.patient.dateOfBirth);

function saveWrap(cb) {
  const savingStatus = ref("idle");
  return {
    action: async () => {
      savingStatus.value = "saving";
      await cb();
      savingStatus.value = "idle";
    },
    savingStatus,
  };
}

const { action, savingStatus } = saveWrap(async () => {
  await patientStore.editPatient({
    dateOfBirth: dateOfBirth.value,
  });
  await patientStore.getPatient();
  router.push({ name: "health-profile" });
});

async function updateDateOfBirth() {
  action();
}
</script>
