<template>
  <router-link
    :to="{
      name: 'genetics-category',
      params: {
        category: category._slug,
      },
    }"
    :key="category._slug"
    class="rounded overflow-hidden"
    :class="
      category._slug === route.params.category
        ? 'bg-pacific-500 text-white'
        : 'bg-white text-cobalt-500'
    "
  >
    <div
      class="fade-bottom h-36"
      :class="{
        'fade-bottom-pacific': category._slug === route.params.category,
      }"
    >
      <img
        :src="category.thumbnail_image.url"
        class="h-36 w-full object-cover"
      />
    </div>
    <div class="py-6 px-3">
      <h3 class="font-semibold text-center">
        {{ category.category_name }}
      </h3>
    </div>
    <!-- category._slug -->
  </router-link>
</template>

<script setup>
import { useRoute } from "vue-router";

const route = useRoute();

defineProps({
  category: Object,
});
</script>
