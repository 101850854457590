<template>
  <form @submit.prevent="updateName" class="space-y-4">
    <h1 class="text-denim-700 font-normal text-2xl">Name</h1>
    <p>Change the name associated with this profile.</p>
    <BaseTextInput
      label="Name"
      type="text"
      id="name"
      name="name"
      v-model="name"
      hide-label
    />
    <button
      class="block bg-cobalt-500 py-2 px-4 rounded text-white"
      :class="{ loading: savingStatus === 'saving' }"
      type="submit"
      @click="submit"
    >
      Submit
    </button>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { usePatientStore } from "@/stores/patient";
import { useRouter } from "vue-router";

const props = defineProps({
  patient: Object,
});

const router = useRouter();
const saveStatus = ref("idle");
const patientStore = usePatientStore();

const name = ref(props.patient.firstName);

function saveWrap(cb) {
  const savingStatus = ref("idle");
  return {
    action: async () => {
      savingStatus.value = "saving";
      await cb();
      savingStatus.value = "idle";
    },
    savingStatus,
  };
}

const { action, savingStatus } = saveWrap(async () => {
  await patientStore.editPatient({
    firstName: name.value,
  });
  await patientStore.getPatient();
  router.push({ name: "health-profile" });
});

async function updateName() {
  action();
}
</script>
