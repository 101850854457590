<template>
  <div
    class="bg-white rounded p-6 border-t-4"
    :class="`border-${meta.color}-500`"
  >
    <!-- text-pacific-500 border-pacific-500 border-tangerine-500 -->
    <div class="flex items-center space-x-3">
      <AppIcon :icon="meta.icon" :class="[`text-${meta.color}-500`]" />
      <h3 class="font-semibold">{{ interpretationKey }}</h3>
    </div>

    <div>
      <ul class="mt-6 flex flex-col gap-2">
        <li v-for="item in filteredResults" :key="item">
          <router-link
            :to="{ name: 'genetics-report', params: { trait: item._slug } }"
            class="flex items-center gap-3 hover:underline"
          >
            <img :src="item.trait_icon[0].url" alt="" class="h-6 w-6" />
            <span>{{ item.trait_name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  decoratedResult: Array,
  interpretationKey: String,
});

const filteredResults = computed(() =>
  props.decoratedResult.filter(
    (item) => item.interpretation === props.interpretationKey
  )
);

const meta = {
  Good: { color: "emerald", icon: "thumbs-up" },
  Typical: { color: "pacific", icon: "meh" },
  Poor: { color: "tangerine", icon: "thumbs-down" },
}[props.interpretationKey];
</script>
