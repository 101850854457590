<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      class="fixed inset-0 flex z-40 lg:hidden"
      @close="$emit('sidebar-closed')"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-grey-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div
          class="relative flex-1 flex flex-col max-w-xs w-full bg-denim-700 focus:outline-none"
        >
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                type="button"
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="$emit('sidebar-closed')"
              >
                <span class="sr-only">Close sidebar</span>
                <AppIcon icon="x" class="h-6 w-6 text-white" />
              </button>
            </div>
          </TransitionChild>
          <div class="flex-1 h-0 pb-4 overflow-y-auto">
            <div class="flex-shrink-0 flex items-center px-4 bg-denim-700 py-4">
              <img class="h-8 w-auto" :src="logos.whiteLogo" alt="Workflow" />
            </div>
            <nav aria-label="Sidebar" class="mt-5">
              <div class="px-2 space-y-1">
                <router-link
                  @click="$emit('sidebar-closed')"
                  v-slot="{ isActive }"
                  v-for="item in navigationItems"
                  :key="item.name"
                  :to="item.href"
                  class="'group flex items-center text-base font-medium rounded-md',"
                >
                  <div
                    :class="[
                      isActive
                        ? 'bg-denim-800 text-white'
                        : 'text-white hover:bg-denim-800',
                      'group flex items-center text-sm font-medium rounded-md w-full px-2 py-2',
                    ]"
                  >
                    <AppIcon :icon="item.icon" class="h-5 w-5 mr-4" />
                    {{ item.name }}
                    <span
                      v-if="item.new"
                      class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pacific-500 text-white"
                    >
                      New
                    </span>
                  </div>
                </router-link>
              </div>
            </nav>
          </div>
          <div class="px-2">
            <portal-target
              v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'"
              name="help-button"
            />
            <div>
              <div>
                <button
                  icon="log-out"
                  text="Sign out"
                  :sidebarIsMinimised="false"
                  :bottomSection="true"
                  @click="logout"
                  class="text-white group flex items-center px-2 py-2 text-sm font-medium mb-2 rounded hover:bg-denim-800 w-full"
                >
                  <AppIcon icon="log-out" class="h-5 w-5 mr-4" />
                  Sign out
                </button>
              </div>
            </div>
          </div>
          <div
            class="flex-shrink-0 flex border-t border-denim-800 p-4 bg-denim-700"
          >
            <button
              v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'"
              class="flex-shrink-0 group block w-full"
              @click="$emit('open-modal')"
            >
              <div class="flex items-center">
                <AppAvatar
                  class="inline-block h-12 w-12 rounded-full"
                  :path="null"
                />
                <div class="ml-3 w-3/4">
                  <p class="text-white mt-0 mb-2 text-left truncate">
                    {{ store.patient.firstName }} {{ store.patient.lastName }}
                  </p>
                  <p
                    class="text-sm font-medium text-white group-hover:text-white m-0 text-left"
                  >
                    Change Profile
                  </p>
                </div>
              </div>
            </button>
            <div v-else class="flex-shrink-0 group block w-full">
              <div class="flex items-center">
                <AppAvatar
                  class="inline-block h-10 w-10 rounded-full"
                  :path="null"
                />
                <div class="ml-3 w-3/4">
                  <p class="text-white mt-0 text-left truncate">
                    {{ store.patient.firstName }} {{ store.patient.lastName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { logos } from "@/assets/assetMaps";
import { defineComponent } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { useAuth, useAccountStore } from "@/stores/account";

import { usePatientStore } from "@/stores/patient";
import AppAvatar from "@/components/AppAvatar.vue";

const { VUE_APP_AUTH_PROVIDER } = process.env;

export default defineComponent({
  props: {
    navigationItems: {
      type: Array,
      required: true,
    },
    modalOpen: {
      type: Boolean,
      required: true,
    },
    sidebarOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    AppAvatar,
  },
  setup() {
    const auth = useAuth();
    const accountStore = useAccountStore();
    const store = usePatientStore();
    function logout() {
      accountStore.logout();
      auth.logout({ redirect: { name: "login" } });
    }
    return { logout, store, auth, logos, VUE_APP_AUTH_PROVIDER };
  },
});
</script>
