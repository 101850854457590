<template>
  <div>
    <h2 class="flex items-center text-lg mt-12">
      <AppIcon icon="shield" class="mr-2" />

      Your Data Privacy
    </h2>

    <div class="w-full mt-6 bg-white shadow-key rounded-lg flex-1">
      <router-link
        class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer flex items-center justify-between"
        :to="{ name: 'start-deletion' }"
      >
        <div class="pl-6 py-5 text-md text-center text-rose-500 font-semibold">
          Permanently delete health profile
        </div>

        <div>
          <a
            class="block pr-6 py-5 text-right flex"
            href="https://support.livingdna.com/hc/en-us/articles/360012499411-Who-do-I-speak-to-about-deleting-my-account-data-"
            target="_blank"
          >
            <AppIcon
              icon="chevron-right"
              class="h-5 w-5 fill-current text-rose-500 m-auto"
            />
          </a>
        </div>
      </router-link>
    </div>
  </div>
</template>
