import { defineStore } from "pinia";
import { makeHealthRequest } from "@/services/healthBackend";
import { usePatientStore } from "./patient";
import { getProductData } from "@/services/store";

interface SubscriptionProduct {
  name: string;
  formattedPrice: string;
  description: string;
  plan: PriceablePlan;
  photo: string;
}

export interface Subscription {
  id: string;
  nextDue: Date;
  product: SubscriptionProduct;
  collectionMethod: SubscriptionProduct;
  formattedTotalCost: string;
}

export interface PriceablePlan {
  amount: number;
  currency: string;
}

interface SummableSubscription {
  items: PriceablePlan[];
  currency: string;
}

export function formatStripeProductPrice(product: PriceablePlan) {
  if (product.currency === "gbp") {
    return `£${(product.amount / 100).toFixed(2)}`;
  }
}

function sumPlan(items: Array<PriceablePlan>) {
  return items.map((plan) => plan.amount).reduce((a, b) => a + b, 0);
}

export function getTotalCostOfStripeSubscription(
  subscription: SummableSubscription
) {
  const sum = sumPlan(subscription.items);
  if (subscription.currency === "gbp") {
    return `£${(sum / 100).toFixed(2)}`;
  }
}

export const useSubscriptionStore = defineStore("subscription", {
  state: () => ({
    subscriptions: [] as Subscription[],
  }),
  actions: {
    async getSubscriptions() {
      const patientStore = usePatientStore();
      const res = await makeHealthRequest({
        method: "GET",
        path: `/subscriptions/patient/${patientStore.patient.userUuid}`,
      });
      this.subscriptions = await Promise.all<Subscription[]>(
        res.data.map(async (item: any) => {
          const preprProduct = await getProductData(item.productId);
          const collectionMethod = preprProduct.collection_methods.find(
            (method: any) =>
              method._slug.toLowerCase() === item.collectionMethod.toLowerCase()
          );
          const subscription = (
            await makeHealthRequest({
              method: "GET",
              path: `/subscriptions/${item.subscriptionId}`,
            })
          ).data;

          return {
            id: subscription.id,
            nextDue: new Date(subscription.current_period_end * 1000),
            formattedTotalCost: getTotalCostOfStripeSubscription({
              items: [
                subscription.items.data[0].plan,
                subscription.items.data[1].plan,
              ],
              currency: "gbp",
            }),
            product: {
              photo: preprProduct.product_photo[0].url,
              name: preprProduct.product_name,
              description: preprProduct.product_details,
              plan: subscription.items.data[0].plan,
              formattedPrice: formatStripeProductPrice(
                subscription.items.data[0].plan
              ),
            },
            collectionMethod: {
              photo: collectionMethod.icon[0].url,
              name: collectionMethod.name,
              description: collectionMethod.description,
              plan: subscription.items.data[1].plan,
              formattedPrice: formatStripeProductPrice(
                subscription.items.data[1].plan
              ),
            },
          };
        })
      );
    },
    async cancelSubscription(subscriptionId: string) {
      const patientStore = usePatientStore();
      await makeHealthRequest({
        method: "DELETE",
        path: `/subscriptions/${subscriptionId}/patient/${patientStore.patient.userUuid}`,
      });
    },
  },
});
