
import { ref } from "vue";
import { useProfileStore } from "@/stores/profile";
import { usePatientStore } from "@/stores/patient";
import { kitNames } from "@/services/static";

import AppAvatar from "@/components/AppAvatar.vue";

export default {
  components: {
    AppAvatar,
  },
  setup() {
    const profileStore = useProfileStore();
    const patientStore = usePatientStore();
    const saveStatus = ref("idle");

    const mergedTestAndProfile = profileStore.profiles;
    const patient = patientStore.patient;

    async function handleProfileClick(kit: { permission: { id: string } }) {
      saveStatus.value = "saving";
      await profileStore.setDefaultProfile(kit.permission.id);
      saveStatus.value = "idle";
      window.location.replace("/../");
    }

    return {
      mergedTestAndProfile,
      kitNames,
      handleProfileClick,
      saveStatus,
      patient,
    };
  },
};
