<template>
  <div v-if="clerk.isLoaded">
    <div v-if="clerk.user">
      clerk login - {{ clerk.user?.firstName }}
      <button
        @click="handleSignOut"
        class="block mt-4 bg-cobalt-500 text-white rounded py-2 px-4"
      >
        Sign out
      </button>
      <button
        @click="getToken()"
        class="block mt-4 bg-cobalt-500 text-white rounded py-2 px-4"
      >
        Log token
      </button>
    </div>
    <div v-else>
      <form @submit.prevent="handleSignIn">
        <input
          class="border rounded w-full block mt-2 border-gray-300"
          type="text"
          v-model="identifier"
        />
        <input
          class="border rounded w-full block mt-2 border-gray-300"
          type="text"
          v-model="password"
        />
        <button class="block mt-4 bg-cobalt-500 text-white rounded py-2 px-4">
          Log in
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useClerk } from "@/services/clerk";
import { useRouter } from "vue-router";

const identifier = ref("felipei@livingdna.com");
const password = ref("oiud&*ytd87aftuydftaiudfytauydf");

const clerk = useClerk();
const router = useRouter();

async function getToken() {
  const token = await clerk.value.session?.getToken();
  console.log(token);
}

async function handleSignIn() {
  const result = await clerk.value.signIn.create({
    identifier: identifier.value,
    password: password.value,
  });
  const res = await clerk.value.setActive({ session: result.createdSessionId });

  const token = await clerk.value.session?.getToken();
  console.log(token);
  alert(token);
  // router.push("dashboard");
}

async function handleSignOut() {
  clerk.value.signOut();
}
</script>
