const { VUE_APP_API } = process.env;

export default {
  matches: `${VUE_APP_API}/matches`,
  getMatches: (barcode: string) => `${VUE_APP_API}/matches/${barcode}`,
  barcodeMatches: (barcode: string) => `${VUE_APP_API}/matches/${barcode}`,
  match: `${VUE_APP_API}/match`,
  getMatch: (currentBarcode: string, matchBarcode: string) =>
    `${VUE_APP_API}/match/${currentBarcode}/${matchBarcode}`,
  sharedMatches: `${VUE_APP_API}/shared-matches`,
  getSharedMatches: (currentBarcode: string, matchBarcode: string) =>
    `${VUE_APP_API}/shared-matches/${currentBarcode}/${matchBarcode}`,
  profile: `${VUE_APP_API}/profile`,
  getProfile: (barcode: string) => `${VUE_APP_API}/profile/${barcode}`,
  reuploadDnaFile: `${VUE_APP_API}/kit-upload/re-upload`,
  profileStatus: (barcode: string) =>
    `${VUE_APP_API}/profile/${barcode}/status`,
  allConsents: (barcode: string) =>
    `${VUE_APP_API}/profile/${barcode}/consents`,
  profiles: `${VUE_APP_API}/profiles`,
  chromosomes: `${VUE_APP_API}/shared-chromosomes`,
  getSharedDna: (currentProfileId: string, matchProfileId: string) =>
    `${VUE_APP_API}/shared-chromosomes/${currentProfileId}/${matchProfileId}`,
  tags: `${VUE_APP_API}/tags`,
  verifyActivation: `${VUE_APP_API}/tests/activation`,
  kitActivation: `${VUE_APP_API}/kit-activation`,
  kitActivationActivate: `${VUE_APP_API}/kit-activation/activate`,
  kitActivationComplete: `${VUE_APP_API}/kit-activation/complete/`,
  kitUploadComplete: (barcode: string) =>
    `${VUE_APP_API}/kit-upload/complete/${barcode}`,
  account: `${VUE_APP_API}/account`,
  accountConfirm: `${VUE_APP_API}/account/confirm`,
  accountAddPartner: `${VUE_APP_API}/account/add-partner`,
  accountConfirmResend: `${VUE_APP_API}/account/confirm/resend`,
  accountCreatePassword: `${VUE_APP_API}/account/create-password`,
  checkNewPasswordToken: `${VUE_APP_API}/account/check-token`,
  accountResetPassword: `${VUE_APP_API}/account/reset-password`,
  testerDetails: `${VUE_APP_API}/kit-activation/tester-details`,
  kitUploadTesterDetails: `${VUE_APP_API}/kit-upload/tester-details`,
  loginApi: `${VUE_APP_API}/login_check`,
  validateEmail: `${VUE_APP_API}/validate-email`,
  refreshToken: `${VUE_APP_API}/refresh-token`,
  activationStatus: `${VUE_APP_API}/activation`,
  activationGuid: `${VUE_APP_API}/activation/guid/`,
  acceptInvitation: `${VUE_APP_API}/kit-activation/accept-invitation/`,
  bookletDeliveryAddress: `${VUE_APP_API}/booklet-delivery-address/`,
  adminUsers: `${VUE_APP_API}/admin/users`,
  adminActivations: `${VUE_APP_API}/admin/activations`,
  adminBarcodes: `${VUE_APP_API}/admin/barcodes`,
  login: "/login_check",
  checkPassword: `${VUE_APP_API}/login_check`,
  consents: "consents",
  tree: "tree",
  downloads: `${VUE_APP_API}/tests/download`,
  tests: `${VUE_APP_API}/tests`,
  getBrand: `${VUE_APP_API}/partner/brand`,
  users: `${VUE_APP_API}/user`,
  checkActivationCode: `${VUE_APP_API}/tests/activation`,
  ancestryDashboardData: (barcode: string) =>
    `${VUE_APP_API}/tests/${barcode}/dashboard`,
  kitTests: `${VUE_APP_API}/kit/tests`,
  testsList: `${VUE_APP_API}/tests/list`,
  wellbeingPreview: (barcode: string) =>
    `${VUE_APP_API}/tests/wellbeing/${barcode}`,
  wellbeingTrait: (barcode: string, trait: string) =>
    `${VUE_APP_API}/tests/wellbeing/${barcode}/${trait}`,
  vitaminReport: (barcode: string) =>
    `${VUE_APP_API}/tests/wellbeing/${barcode}/getvitaminlab`,
  upgradeTest: (barcode: string, test: string) =>
    `${VUE_APP_API}/tests/${barcode}/${test}/update`,
  getPreviousResult: (test: string) => (barcode: string) =>
    `${VUE_APP_API}/tests/${barcode}/${test}/previousVersions`,
  getPreviousAutosomalResult(barcode: string) {
    return this.getPreviousResult("autosomal")(barcode);
  },
  getPreviousResultPdf: (barcode: string) =>
    `${VUE_APP_API}/tests/${barcode}/autosomal/previousVersionsPdf`,
  research: (barcode: string) => `${VUE_APP_API}/tests/${barcode}/research`,
  manager: `${VUE_APP_API}/profile/manager`,
  profileManager: (barcode: string) =>
    `${VUE_APP_API}/profile/${barcode}/manager`,

  avatar: (barcode: string) => `${VUE_APP_API}/profile/${barcode}/avatar`,
  uploadReferral: (barcode: string) =>
    `${VUE_APP_API}/profile/${barcode}/upload-referral`,
  hydratedContacts: (barcode: string) =>
    `${VUE_APP_API}/matches/${barcode}/contacts`,
  relationshipNotes: `${VUE_APP_API}/relationship-notes`,
  relationshipNotesByBarcode(barcode: string) {
    return `${this.relationshipNotes}/${barcode}`;
  },
  familyNetworkSharingPermissions: (barcode: string) =>
    `${VUE_APP_API}/profile/${barcode}/family-network-sharing`,
  vikingResult: (barcode: string) => `${VUE_APP_API}/tests/viking/${barcode}`,
  vikingCms: (type: string) => `${VUE_APP_API}/tests/viking/result/${type}`,
  archaicResult: (barcode: string) => `${VUE_APP_API}/tests/archaic/${barcode}`,
  archaicCms: (type: string) => `${VUE_APP_API}/tests/archaic/result/${type}`,
  healthBackend: `${VUE_APP_API}/health`,
};
