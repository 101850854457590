<template>
  <form @submit.prevent="updateName" class="space-y-4">
    <h1 class="text-denim-700 font-normal text-2xl">Biological sex</h1>
    <p>
      We ask for this because it is used to provide you with high-quality,
      accurate DNA reports.
    </p>

    <BaseRadioInput
      :options="[
        { id: 'Male', value: 'Male', title: 'Male' },
        { id: 'Female', value: 'Female', title: 'Female' },
      ]"
      label="Biological sex"
      hide-label
      v-model="biologicalSex"
    />

    <button
      class="block bg-cobalt-500 py-2 px-4 rounded text-white"
      :class="{ loading: savingStatus === 'saving' }"
      type="submit"
      @click="submit"
    >
      Submit
    </button>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { usePatientStore } from "@/stores/patient";
import { useRouter } from "vue-router";

const props = defineProps({
  patient: Object,
});

const biologicalSex = ref(props.patient.bioSex);
const router = useRouter();
const patientStore = usePatientStore();

function saveWrap(cb) {
  const savingStatus = ref("idle");
  return {
    action: async () => {
      savingStatus.value = "saving";
      await cb();
      savingStatus.value = "idle";
    },
    savingStatus,
  };
}

const { action, savingStatus } = saveWrap(async () => {
  await patientStore.editPatient({
    bioSex: biologicalSex.value,
  });
  await patientStore.getPatient();
  router.push({ name: "health-profile" });
});

async function updateName() {
  action();
}
</script>
