<template>
  <div class="space-y-4">
    <BaseTextInput
      type="text"
      id="verification_code"
      name="verification_code"
      v-model="verificationCode"
      label="Verification code"
    />
    <div
      v-if="errorText"
      class="bg-rose-50 text-rose-500 text-sm rounded p-4 mt-4"
    >
      {{ errorText }}
    </div>
    <button
      @click="verifySignup(verificationCode)"
      class="bg-cobalt-500 text-white rounded w-full py-2 px-4 font-semibold"
    >
      Verify email
    </button>

    <button @click="clerk.signUp.prepareEmailAddressVerification()">
      Resend code
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useAccountStore } from "@/stores/account";
import { useAuth } from "@/stores/account";
import { useClerk } from "@/services/clerk";

const accountStore = useAccountStore();
const auth = useAuth();
const router = useRouter();
const clerk = useClerk();

const verificationCode = ref("");
const errorText = ref("");

async function verifySignup(code) {
  try {
    const result = await accountStore.verifyEmail(code);
    await clerk.value.setActive({ session: result.createdSessionId });
    router.push({ name: "dashboard" });
  } catch (error) {
    if (error.errors) {
      errorText.value = error.errors[0].longMessage;
    }
    console.error(error);
  }
}
</script>
