<template>
  <AppPage>
    <div class="flex items-center mx-auto text-denim-700 //mt-16">
      <AppIcon icon="heart" :classes="['h-6', 'w-6']" />
      <h1 class="ml-4 text-2xl font-normal">
        {{ patientStore.patient.firstName }}, your blood test overview
      </h1>
    </div>
    <div class="mt-16 space-y-8">
      <template v-if="patientStore.patient.tests.length">
        <template v-for="test in patientStore.patient.tests" :key="test.id">
          <router-link
            v-if="test.result"
            :to="{
              name: 'blood-test-details',
              params: { barcode: test.barcode },
            }"
            class="bg-white rounded shadow p-6 flex-1 flex flex-col border-l-4"
            :class="
              test.result.testFailed ? 'border-rose-500' : 'border-emerald-500'
            "
          >
            <div>
              <div class="md:flex max-md:space-y-2 justify-between mb-4">
                <div class="flex items-center space-x-4">
                  <div>{{ test.name }}</div>
                  <span
                    class="inline-flex items-center rounded-full px-3 py-0.5 text-sm"
                    :class="
                      test.result.testFailed
                        ? 'bg-rose-50 text-rose-500'
                        : 'bg-green-100 text-green-700'
                    "
                  >
                    {{ test.instance }} test
                  </span>
                </div>
                <div>
                  <div
                    class="flex items-center space-x-2 text-grey-700 max-md:text-sm"
                  >
                    <AppIcon icon="calendar" />
                    <span>
                      Completed
                      {{
                        new Date(test.result.date).toLocaleDateString("en-GB")
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-html="test.result.medicalReportSummary" />

              <HealthTrafficLights
                v-if="!test.result.testFailed"
                :bloodTest="test.result"
                v-slot="{ inRange, outOfRange, colors }"
              >
                <div class="md:flex md:space-x-4 max-md:space-y-2 mt-6">
                  <div class="flex items-center">
                    <div
                      :style="`background-color: ${colors['rangeLow']}`"
                      class="h-4 w-4 rounded-full mr-2"
                    ></div>
                    {{ outOfRange }}
                    x out of range
                  </div>
                  <div class="flex items-center">
                    <div
                      :style="`background-color: ${colors['rangeNormal']}`"
                      class="h-4 w-4 rounded-full mr-2"
                    ></div>
                    {{ inRange }}
                    x normal range
                  </div>
                </div>
              </HealthTrafficLights>
            </div>
          </router-link>
          <div v-else>
            <div
              class="bg-white rounded shadow p-6 flex-1 flex flex-col border-l-4 border-cobalt-500"
            >
              <div>
                <div class="md:flex max-md:space-y-2 justify-between mb-4">
                  <div class="flex items-center space-x-4">
                    <div>{{ test.name }}</div>
                    <span
                      class="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800"
                    >
                      {{ test.instance }} test
                    </span>
                  </div>
                  <div>
                    <div
                      class="flex items-center space-x-2 text-grey-700 max-md:text-sm"
                    >
                      <AppIcon icon="calendar" />
                      <span>
                        Activated
                        {{
                          new Date(test.createdAt).toLocaleDateString("en-GB")
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  We're currently testing your sample. We'll be in touch when
                  your results are ready
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <div v-else>
        <div class="">
          <div class="max-w-5xl mx-auto //-mt-8 relative z-40 space-y-16">
            <div>
              <div class="rounded bg-white shadow">
                <div
                  class="md:flex max-md:space-y-2 justify-between p-6 bg-cobalt-50 text-cobalt-500"
                >
                  <div class="flex items-center space-x-4 font-semibold">
                    <AppIcon icon="alert-circle" />
                    <div>No blood tests activated</div>
                  </div>
                </div>
                <div class="p-6">
                  You haven't activated any blood tests yet. If you have a test
                  please activate it, or check out your options below
                  <div class="flex items-center space-x-4 mt-4">
                    <router-link
                      :to="{ name: 'store-index' }"
                      class="font-semibold bg-cobalt-500 text-white rounded hover:bg-cobalt-700 py-2 px-4 block"
                    >
                      Explore options
                    </router-link>
                    <router-link
                      :to="{ name: 'activate' }"
                      class="font-semibold text-cobalt-500"
                    >
                      Activate a test
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div>
              <h2 class="text-lg mb-2">Discover precision health tests</h2>

              <DashboardTestTypeCardList />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </AppPage>
</template>

<script setup>
import { usePatientStore } from "@/stores/patient";
import HealthTrafficLights from "@/components/HealthTrafficLights.vue";
import DashboardTestTypeCardList from "@/components/DashboardTestTypeCardList.vue";

const patientStore = usePatientStore();
</script>
