<template>
  <div class="fade-bottom fade-bottom-grey">
    <div class="h-64 relative fade-bottom fade-bottom-grey">
      <div class="absolute inset-0 bg-black opacity-20 z-10"></div>
      <span class="inset-0 z-20 absolute flex px-4">
        <span class="m-auto text-white text-2xl font-semibold">
          {{ patientStore.patient.firstName }}, your health and wellbeing
          dashboard
        </span>
      </span>
      <AppImage
        class="h-full w-full object-cover"
        :public-id="getAgeGroup()"
        width="1000"
      />
    </div>
  </div>
</template>

<script setup>
import { usePatientStore } from "@/stores/patient";
const patientStore = usePatientStore();

function getAgeGroup() {
  const age =
    new Date().getFullYear() -
    new Date(patientStore.patient.dateOfBirth).getFullYear();
  if (age < 30) {
    return "Portal Banner Images/Under30";
  }
  if (age < 50) {
    return "Portal Banner Images/Under50V2";
  }
  return "Portal Banner Images/Over50s";
}
</script>
