<template>
  <form @submit.prevent="updateEmail" class="space-y-4">
    <h1 class="text-denim-700 font-normal text-2xl">Email</h1>
    <p>Set the email associated with your account.</p>
    <div class="p-4 bg-cobalt-50 text-cobalt-500 rounded">
      For security reasons after you change the email you will need to log in
      again.
    </div>
    <BaseTextInput
      type="email"
      id="email"
      name="email"
      v-model="email"
      label="Email"
      hide-label
    />
    <button
      class="block bg-cobalt-500 py-2 px-4 rounded text-white"
      :class="{ loading: saveStatus === 'saving' }"
      type="submit"
      @click="submit"
    >
      Submit
    </button>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useAuth, useAccountStore } from "@/stores/account";
import { useRouter } from "vue-router";

const props = defineProps({
  account: Object,
});

const store = useAccountStore();
const auth = useAuth();
const router = useRouter();
const saveStatus = ref("idle");

const email = ref(props.account.email);

async function updateEmail() {
  await store.setAccountFields({
    account: props.account,
    fields: {
      email: email.value,
    },
  });
  await auth.fetch();
  router.push({ name: "account-view" });
}
</script>
