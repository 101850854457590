<template>
  <form :action="getCheckoutUrl()" method="POST">
    <input
      type="hidden"
      name="patient_uuid"
      :value="patientStore.patient.userUuid"
    />
    <input
      type="hidden"
      name="patient_region"
      :value="patientStore.patient.region"
    />
    <input type="hidden" name="product_id" :value="product._id" />
    <input type="hidden" name="mode" :value="mode" />
    <input type="hidden" name="collection_method" :value="collectionMethod" />
    <input type="hidden" name="interval" :value="interval" />
    <input type="hidden" name="quantity" value="1" />
    <input type="hidden" name="cancel_url" :value="cancelUrl" />
    <input type="hidden" name="success_url" :value="getSuccessUrl()" />
    <slot />
  </form>
</template>

<script setup>
import { usePatientStore } from "@/stores/patient";
const { VUE_APP_API, VUE_APP_AUTH_PROVIDER, VUE_APP_HEALTH_API } = process.env;

const props = defineProps({
  product: Object,
  collectionMethod: String,
  mode: String,
  interval: String,
});

const patientStore = usePatientStore();

const cancelUrl = window.location.href;

function getSuccessUrl() {
  const origin = window.location.origin;
  if (VUE_APP_AUTH_PROVIDER === "clerk") {
    return `${origin}/store/success?product_id=${props.product._id}&session_id={CHECKOUT_SESSION_ID}`;
  }
  return `${origin}/health/store/success?product_id=${props.product._id}&session_id={CHECKOUT_SESSION_ID}`;
}

function getCheckoutUrl() {
  if (VUE_APP_AUTH_PROVIDER === "clerk") {
    return `${VUE_APP_API}/checkout/session`;
  }
  return `${VUE_APP_HEALTH_API}/checkout/session`;
}
</script>

{ ProductPage (id: {PRODUCT_ID}) { _id product_name product_description sku
usa_sku eur_sku aus_sku can_sku product_type price_string price usa_price_string
usa_price aus_price_string aus_price eur_price_string eur_price can_price_string
can_price subscription_price_string subscription_price
usa_subscription_price_string usa_subscription_price
aus_subscription_price_string aus_subscription_price
eur_subscription_price_string eur_subscription_price
can_subscription_price_string can_subscription_price currency } }
