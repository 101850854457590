<template>
  <div class="rounded bg-white shadow">
    <div class="md:flex max-md:space-y-2 justify-between p-6 border-b">
      <div class="flex items-center space-x-4">
        <div>{{ test.name }}</div>
        <span
          class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium"
          :class="
            test.result.testFailed
              ? 'bg-rose-50 text-rose-500'
              : 'bg-blue-100 text-blue-800'
          "
        >
          {{ test.instance }} test
        </span>
      </div>
      <div>
        <div class="flex items-center space-x-2 text-base text-grey-700">
          <AppIcon icon="calendar" />
          <span>
            Completed
            {{ new Date(test.result.date).toLocaleDateString("en-GB") }}
          </span>
        </div>
      </div>
    </div>
    <div class="p-6">
      <HealthTrafficLights
        v-if="!test.result.testFailed"
        :bloodTest="test.result"
        class="mb-6"
        v-slot="{
          inRange,
          outOfRange,
          colors,
          slightlyElevated,
          slightlyReduced,
        }"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div class="flex items-center">
            <div
              :style="`background-color: ${colors['rangeLow']}`"
              class="h-4 w-4 rounded-full mr-2"
            ></div>
            {{ outOfRange }}
            x out of range
          </div>

          <div v-if="slightlyReduced > 0" class="flex items-center">
            <div
              :style="`background-color: ${colors['rangeLowNormal']}`"
              class="h-4 w-4 rounded-full mr-2"
            ></div>
            {{ slightlyReduced }}
            x slightly reduced
          </div>
          <div v-if="slightlyElevated > 0" class="flex items-center">
            <div
              :style="`background-color: ${colors['rangeHighNormal']}`"
              class="h-4 w-4 rounded-full mr-2"
            ></div>
            {{ slightlyElevated }}
            x slightly elevated
          </div>
          <div class="flex items-center">
            <div
              :style="`background-color: ${colors['rangeNormal']}`"
              class="h-4 w-4 rounded-full mr-2"
            ></div>
            {{ inRange }}
            x normal range
          </div>
        </div>
      </HealthTrafficLights>
      <div class="space-y-6 mt-4">
        <div v-html="test.result.medicalReportSummary"></div>

        <DoctorDetails v-if="!test.result.testFailed" />

        <router-link
          :to="{
            name: 'blood-test-details',
            params: { barcode: test.barcode },
          }"
          class="font-semibold text-cobalt-500 mt-4 flex items-center space-x-3"
        >
          View test
          <AppIcon icon="chevron-right" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useResultsStore } from "@/stores/results";
import DoctorDetails from "@/components/DoctorDetails.vue";
import HealthTrafficLights from "@/components/HealthTrafficLights.vue";

const resultsStore = useResultsStore();

defineProps({
  test: Object,
});
</script>
