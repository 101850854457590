<template>
  <div class="space-y-4 text-grey-700">
    <div
      v-for="step in getSteps(kit, test)"
      :key="step.text"
      class="flex space-x-2 items-center"
    >
      <AppIcon
        v-if="step.completed"
        icon="check-circle"
        class="text-emerald-500"
      />
      <AppIcon v-else icon="circle" class="text-grey-500" />
      <div :class="{ 'line-through': step.completed }">{{ step.text }}</div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  kit: Object,
  test: Object,
  collectionMethod: String,
  videoWatched: Boolean,
  questionnaireCompleted: Boolean,
});

const steps = {
  FPK: [
    {
      text: "Activate your kit",
      completed(kit, test) {
        return kit.activated;
      },
    },
    {
      text: "Fill out the health questionnaire",
      completed(kit, test, videoWatched, questionnaireCompleted) {
        return questionnaireCompleted;
      },
    },
    {
      text: "Watch video",
      completed(kit, test, videoWatched) {
        return videoWatched;
      },
    },
    {
      text: "Take your sample",
      completed(kit, test) {
        return false;
      },
    },
    {
      text: "Return your sample",
      completed(kit, test) {
        return false;
      },
    },
  ],
  VPU: [
    {
      text: "Attend appointment",
      completed(kit, test) {
        return kit.activated;
      },
    },
    {
      text: "Activate your kit",
      completed(kit, test) {
        return kit.activated;
      },
    },
    {
      text: "Fill out the health questionnaire",
      completed(kit, test, videoWatched, questionnaireCompleted) {
        return questionnaireCompleted;
      },
    },
    {
      text: "Return your sample",
      completed(kit, test) {
        return false;
      },
    },
  ],
  VPB: [
    {
      text: "Have blood collected",
      completed(kit, test) {
        return kit.activated;
      },
    },
    {
      text: "Activate your kit",
      completed(kit, test) {
        return kit.activated;
      },
    },
    {
      text: "Fill out the health questionnaire",
      completed(kit, test, videoWatched, questionnaireCompleted) {
        return questionnaireCompleted;
      },
    },
    {
      text: "Return your sample",
      completed(kit, test) {
        return false;
      },
    },
  ],
};

function getSteps(kit, test) {
  const foundSteps = steps[props.collectionMethod];
  console.log(foundSteps);
  if (!steps) {
    throw Error("Steps could not be found");
  }
  return foundSteps.map((step) => ({
    ...step,
    completed: step.completed(
      kit,
      test,
      props.videoWatched,
      props.questionnaireCompleted
    ),
  }));
}
</script>
