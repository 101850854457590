import { ref } from "vue";

export function useLoading() {
  const loadingStatus = ref("loading");
  function finishLoading() {
    loadingStatus.value = "loaded";
    return "done";
  }
  return { loadingStatus, finishLoading };
}

export function useSaving() {
  const saveStatus = ref("idle");
  const errorMessage = ref("");
  function startSaving() {
    errorMessage.value = "";
    saveStatus.value = "saving";
  }
  function finishSaving() {
    saveStatus.value = "idle";
  }
  return { saveStatus, errorMessage, startSaving, finishSaving };
}
