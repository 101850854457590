<template>
  <div>
    <h3 class="text-denim-700 text-2xl font-normal text-center">Log in</h3>
    <form @submit.prevent="logIn" class="mt-8">
      <div>
        <label
          for="email"
          class="text-grey-700 font-semibold block w-full"
          style="font-size: 14px"
        >
          Email address
        </label>
        <input
          v-model="credentials.username"
          type="email"
          id="email"
          class="mt-1 border bg-grey-50 border-grey-500 rounded-sm w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
        />
      </div>
      <div class="mt-2">
        <PasswordInput v-model="credentials.password" />
      </div>
      <div
        v-if="errorText"
        class="bg-rose-50 text-rose-500 text-sm rounded p-4 mt-4"
      >
        {{ errorText }}
      </div>
      <button
        :class="{ loading: saveStatus === 'saving' }"
        class="mt-4 bg-cobalt-500 rounded-sm w-full py-2 px-4 text-white font-semibold test-shadow"
      >
        Log in
      </button>
    </form>
    <div class="mt-6 text-center">
      Don't have an account?
      <router-link class="text-cobalt-500 font-semibold" to="/signup">
        Sign up
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAuth } from "@/stores/account";
import PasswordInput from "@/components/PasswordInput.vue";

const credentials = ref({
  username: "",
  password: "",
});
const saveStatus = ref("idle");
const errorText = ref("");

let redirect;

const auth = useAuth();
async function logIn() {
  saveStatus.value = "saving";
  errorText.value = "";
  try {
    await auth.login({
      data: {
        _username: credentials.value.username,
        _password: credentials.value.password,
      },
      // _username: "felipei@livingdna.com",
      // _password: "oiud&*ytd87aftuydftaiudfytauydf",
      redirect: {
        path: redirect ? redirect.from.fullPath : "dashboard",
      },
    });
  } catch (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "User account is disabled."
    ) {
      errorText.value =
        "Your account is not yet verified. Please follow the link that we've just sent to your email.";
    } else if (error.response && error.response.status === 401) {
      errorText.value =
        "The email address or password you entered is not correct. Please try again or Sign Up below.";
    } else if (error.response && error.response.status === 404) {
      errorText.value =
        "You have requested deletion of an account with this email address. Please contact us";
    } else if (error.errors) {
      errorText.value = error.errors[0].longMessage;
    } else {
      errorText.value = "Something went wrong while logging in";
      console.error(error);
    }
  } finally {
    saveStatus.value = "idle";
  }
}
</script>

<!-- 0ed13fae-789e-4974-8c10-c998adc8b096 -->
<!-- user_2NNFGNg0z9AeKNyQAjygRs7YTYg -->
