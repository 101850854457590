const { VUE_APP_AUTH_PROVIDER } = process.env;

import { useAuth as vueAuth } from "@websanova/vue-auth/src/v3.js";
import { useClerk } from "@/services/clerk";
import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/endpoints";
import { useActivationStore } from "./activation";
import { usePatientStore } from "./patient";
import { useProfileStore } from "./profile";
import { useResultsStore } from "./results";
import { useRouter } from "vue-router";

// @ts-ignore
let useAuth: () => {
  login: () => any;
  verifyEmail: (code: string) => any;
  user: () => {
    uuid: string;
  };
  register: (args: any) => any;
  logout: () => void;
};

if (VUE_APP_AUTH_PROVIDER === "clerk") {
  // @ts-ignore
  useAuth = () => {
    const clerk = useClerk();
    const router = useRouter();
    return {
      async login(arg: any) {
        const result = await clerk.value.signIn.create({
          identifier: arg.data._username,
          password: arg.data._password,
        });

        const res = await clerk.value.setActive({
          session: result.createdSessionId,
        });

        router.push({ name: "dashboard" });
      },
      async register(args: any) {
        const result = await clerk.value.signUp.create({
          firstName: args.data.account.firstName,
          lastName: args.data.account.lastName,
          emailAddress: args.data.account.email,
          password: args.data.account.plainPassword.first,
        });
        await result.prepareEmailAddressVerification();
      },
      async verifyEmail(code: string) {
        return await clerk.value.signUp.attemptEmailAddressVerification({
          code,
        });
      },
      async logout() {
        await clerk.value.signOut();
        router.push({ name: "login" });
      },
      user: () => {
        if (clerk.value.user) {
          return {
            ...clerk.value.user,
            uuid: clerk.value.user.id,
            email: clerk.value.user.emailAddresses[0].emailAddress,
          };
        }
        return false;
      },
      check() {
        console.log(this.user());
        return !!this.user();
      },
      method: VUE_APP_AUTH_PROVIDER,
    };
  };
} else {
  useAuth = vueAuth;
}

console.log(useAuth);

export { useAuth };

interface Account {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  partner: object;
  country: {
    code: string;
  };
  acceptMarketing: boolean;
  acceptTerms: boolean;
}

export const useAccountStore = defineStore("account", {
  state: () => ({
    emailAddress: "",
  }),
  actions: {
    async setAccountFields({
      account,
      fields,
    }: {
      account: { email: string; username: string };
      fields: object;
    }) {
      await axios.put(endpoints.account, {
        account: {
          email: account.email,
          username: account.username,
          ...fields,
        },
      });
    },
    async updatePassword({
      account,
      currentPassword,
      newPassword,
    }: {
      account: { email: string; username: string };
      currentPassword: string;
      newPassword: string;
    }) {
      return await saveNewPasswordForAccount(
        account.email,
        account.username,
        currentPassword,
        newPassword
      );
    },
    async registerAccount(account: Account, auth: any) {
      this.emailAddress = account.emailAddress;
      return auth.register({
        data: {
          account: {
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.emailAddress,
            username: account.emailAddress,
            plainPassword: {
              first: account.password,
              second: account.password,
            },
            partner: account.partner,
            country: account.country.code,
            marketing: account.acceptMarketing,
            termsOfService: account.acceptTerms,
            privacyStatement: account.acceptTerms,
            originalEmail: account.emailAddress,
          },
        },
      });
    },
    async verifyEmail(code: string) {
      const auth = useAuth();
      return await auth.verifyEmail(code);
    },
    async resendVerificationEmail() {
      axios.post(endpoints.accountConfirmResend, {
        email: this.emailAddress,
      });
    },
    logout() {
      const activationStore = useActivationStore();
      const patientStore = usePatientStore();
      const profileStore = useProfileStore();
      const resultsStore = useResultsStore();

      activationStore.$reset();
      patientStore.reset();
      profileStore.$reset();
      resultsStore.reset();

      this.$reset();
    },
  },
});

export async function saveNewPasswordForAccount(
  email: string,
  username: string,
  currentPassword: string,
  newPassword: string
) {
  try {
    const token = localStorage.getItem("token") as string;
    const response = await fetch(endpoints.account, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.split("--")[0]}`,
      },
      body: JSON.stringify({
        account: {
          email,
          username,
          oldPassword: currentPassword,
          password: newPassword,
        },
      }),
    });

    if (response.status === 401) {
      throw new Error(
        "The current password you provided does not match our records"
      );
    }

    if (!response.ok) {
      throw new Error("Something went wrong saving your password");
    }

    return "done";
  } catch (e) {
    console.error(e);
    throw e;
  }
}
