<template>
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div class="flex flex-col" style="width: 18rem">
      <div
        class="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-denim-700 bg-no-repeat"
        style="background-size: 240%; background-position: top -40% left 30%"
        :style="`background-image: url('${bgImage}')`"
      >
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <router-link
              :to="{ name: 'dashboard' }"
              class="flex items-center space-x-2"
            >
              <img
                class="w-auto"
                :class="VUE_APP_AUTH_PROVIDER === 'clerk' ? 'h-8' : 'h-10'"
                :src="logos.whiteLogo"
                alt="Living DNA Logo"
              />
            </router-link>
            <!-- <router-link
              :to="{ name: 'dashboard' }"
              class="flex flex-col items-start //items-center //space-x-2 space-y-2 text-sm"
            >
              <img
                class="h-8 w-auto"
                src="@/../public/Logo.png"
                alt="Living DNA Logo"
              />
              <span class="bg-rose-500 text-white px-2 rounded py-px"
                >Health</span
              >
            </router-link> -->
          </div>

          <div
            class="flex-shrink-0 flex border-t border-b border-denim-800 p-4 mt-5"
          >
            <button
              v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'"
              class="flex-shrink-0 group block w-full"
              @click="$emit('open-modal')"
            >
              <div class="flex items-center">
                <AppAvatar
                  class="inline-block h-12 w-12 rounded-full"
                  :path="null"
                />
                <div class="ml-3 w-3/4">
                  <p class="text-white mt-0 mb-2 text-left truncate">
                    {{ store.patient.firstName }} {{ store.patient.lastName }}
                  </p>
                  <p
                    class="text-sm font-medium text-white group-hover:text-white m-0 text-left"
                  >
                    Change Profile
                  </p>
                </div>
              </div>
            </button>
            <div v-else class="flex-shrink-0 group block w-full">
              <div class="flex items-center">
                <AppAvatar
                  class="inline-block h-10 w-10 rounded-full text-sm"
                  :path="null"
                />
                <div class="ml-3 w-3/4">
                  <p class="text-white mt-0 text-left truncate">
                    {{ store.patient.firstName }} {{ store.patient.lastName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <nav class="mt-5 flex-1" aria-label="Sidebar">
            <div class="px-2 space-y-1">
              <router-link
                v-slot="{ isActive }"
                v-for="item in navigationItems"
                :key="item.name"
                :to="item.href"
              >
                <div
                  :class="[
                    isActive
                      ? 'bg-denim-800 text-white'
                      : 'text-white hover:bg-denim-800',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md mb-2 rounded',
                  ]"
                >
                  <AppIcon :icon="item.icon" class="h-5 w-5 mr-4" />
                  {{ item.name }}
                  <span
                    v-if="item.new"
                    class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pacific-500 text-white"
                  >
                    New
                  </span>
                </div>
              </router-link>
            </div>
          </nav>

          <div class="px-2 mt-6">
            <div>
              <div v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'" class="">
                <button
                  @click="$emit('discover-ancestry')"
                  class="px-2 text-white py-3 rounded flex items-center w-full text-sm space-x-4 hover:bg-denim-800"
                >
                  <AppIcon icon="helix" class="text-white" />
                  <span>Ancestry</span>
                  <span
                    class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pacific-500 text-white"
                  >
                    Explore
                  </span>
                </button>
              </div>
              <portal-target
                v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'"
                name="help-button"
              />
              <div>
                <button
                  icon="log-out"
                  text="Sign out"
                  :sidebarIsMinimised="false"
                  :bottomSection="true"
                  @click="logout"
                  class="text-white group flex items-center px-2 py-2 text-sm font-medium mb-2 rounded hover:bg-denim-800 w-full"
                >
                  <AppIcon icon="log-out" class="h-5 w-5 mr-4" />
                  Sign out
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'"
            class="py-3 //mt-3 border-t border-denim-800"
          >
            <div class="text-white px-4 text-xs mb-1">powered by</div>
            <div class="flex items-center flex-shrink-0 px-4">
              <img
                class="h-7 w-auto"
                src="@/../public/dna-workplace-logo.png"
                alt="Living DNA Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { VUE_APP_AUTH_PROVIDER } = process.env;
import { logos } from "@/assets/assetMaps";
import { defineComponent } from "vue";
import AppAvatar from "@/components/AppAvatar.vue";
import { usePatientStore } from "@/stores/patient";
import { useAccountStore, useAuth } from "@/stores/account";
import bgImage from "@/../public/bubbles-30.png";

export default defineComponent({
  props: {
    navigationItems: {
      type: Array,
      required: true,
    },
    modalOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const accountStore = useAccountStore();
    const auth = useAuth();
    const store = usePatientStore();

    function logout() {
      accountStore.logout();
      auth.logout({ redirect: { name: "login" } });
    }
    return { store, logout, bgImage, auth, logos, VUE_APP_AUTH_PROVIDER };
  },
  components: { AppAvatar },
});
</script>
