<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="none"
    stroke-width="1"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <g fill-rule="evenodd">
      <path
        d="M17.8167994,8 C17.8167994,10.9315713 15.4315713,13.3167994 12.5,13.3167994 C9.56842867,13.3167994 7.18320058,10.9315713 7.18320058,8 C7.18320058,5.06842867 9.56842867,2.68320058 12.5,2.68320058 C15.4315713,2.68320058 17.8167994,5.06842867 17.8167994,8 M20,8 C20,3.86386676 16.6361332,0.5 12.5,0.5 C8.36386676,0.5 5,3.86386676 5,8 C5,11.7038378 7.74475018,14.8732802 11.3997104,15.4120203 L11.3997104,17.4637944 L8.48660391,17.4637944 C8.19659667,17.4648805 7.92396814,17.5789283 7.71650978,17.788559 C7.30811007,18.2078204 7.29507603,18.8790731 7.69370022,19.3211441 L7.72411296,19.3493845 C7.82621289,19.4493121 7.94569153,19.5264301 8.0782042,19.5796524 C8.2096307,19.6339609 8.34757422,19.6611151 8.48986242,19.6622013 L11.3997104,19.6622013 L11.3997104,22.6230992 C11.3986242,22.7686459 11.4257784,22.9087618 11.4779146,23.0391021 C11.5300507,23.179218 11.6093411,23.3041274 11.717958,23.4170891 C11.8244026,23.5224475 11.9471398,23.6017379 12.0807386,23.653874 C12.2132513,23.7081825 12.352281,23.7353367 12.4945692,23.7353367 C12.6357712,23.7353367 12.7737147,23.7092686 12.9018827,23.6560463 C13.0398262,23.6017379 13.1636495,23.5224475 13.275525,23.410572 C13.3787111,23.3041274 13.4569153,23.1803041 13.5068791,23.0445329 C13.5611875,22.9109341 13.5883418,22.7697321 13.5872556,22.6241854 L13.5872556,19.6567705 L16.5003621,19.6567705 C16.6328747,19.6524258 16.7784214,19.6328747 16.9120203,19.5785663 C17.05105,19.5242578 17.1748733,19.4438812 17.2856626,19.3341781 C17.3888487,19.2266474 17.4670529,19.102824 17.5170167,18.968139 C17.6256336,18.6976828 17.6256336,18.3989862 17.5170167,18.1274439 C17.4887762,18.0590152 17.4529327,17.9916727 17.410572,17.9286749 L17.2704562,17.7776973 C17.0651702,17.5713251 16.7925416,17.4561912 16.5025344,17.455105 L13.5829109,17.455105 L13.5829109,15.4141926 C17.2476466,14.8841419 20,11.7136133 20,8"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-female",
};
</script>
