<script setup>
import { ref } from "vue";
import { makeHealthRequest } from "@/services/healthBackend";
import DateInput from "@/components/Activation/DateOfBirth.vue";
import { useSaving } from "@/stores/loading";
import { useAuth } from "@/stores/account";
import { method } from "lodash";
import { useRouter } from "vue-router";

const { saveStatus, errorMessage, startSaving, finishSaving } = useSaving();
const auth = useAuth();
const router = useRouter();

const pendingAppointments = makeHealthRequest({
  path: `/orders/patient/${auth.user().email}/missingAppointmentData`,
  method: "GET",
});

const ecommerceId = ref("");

pendingAppointments.then((res) => {
  const orderDetails = JSON.parse(res.data[0].rawJson);
  ecommerceId.value = orderDetails.orderEcommerceId;
});

const appointmentDetails = ref({
  userUuid: auth.user().uuid,
  firstName: auth.user().firstName,
  lastName: auth.user().lastName,
  email: auth.user().email,
  bioSex: "",
  dateOfBirth: "",
  title: "",
});

async function saveAppointment() {
  try {
    startSaving();
    await makeHealthRequest({
      path: `/orders/${ecommerceId.value}/appointment`,
      method: "PUT",
      body: appointmentDetails.value,
    });
    router.push({ name: "dashboard" });
  } catch (e) {
    errorMessage.value = "Something went wrong saving the form";
  } finally {
    finishSaving();
  }
}
</script>

<template>
  <div class="space-y-6">
    <h3 class="text-denim-700 text-2xl font-normal text-center mb-8">
      Tell us about yourself
    </h3>
    <p>
      We need to know a little information about you to arrange your blood
      collection appointment with a healthcare professional. Once you have
      completed your appointment and activated your test you will find this
      information in your portal
    </p>
    <BaseSelect
      class="w-24"
      label="Title"
      :options="[
        {
          label: 'Miss',
          value: 'Miss',
        },
        {
          label: 'Mrs',
          value: 'Mrs',
        },
        {
          label: 'Ms',
          value: 'Ms',
        },
        {
          label: 'Mr',
          value: 'Mr',
        },
      ]"
      v-model="appointmentDetails.title"
    />

    <BaseRadioInput
      :options="[
        { title: 'Male', value: 'Male', id: 'Male' },
        { title: 'Female', value: 'Female', id: 'Female' },
      ]"
      label="Biological sex"
      id=" bioSex"
      required
      v-model="appointmentDetails.bioSex"
      inline-options
    />

    <div>
      <div class="block font-semibold //text-gray-700 text-sm mb-1">
        Date of birth
      </div>
      <DateInput v-model="appointmentDetails.dateOfBirth" />
    </div>

    <button
      @click="saveAppointment"
      class="w-full py-2 px-4 mt-6 rounded flex items-center justify-center bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
      :class="{ loading: saveStatus === 'saving' }"
    >
      Save
    </button>
  </div>
</template>
