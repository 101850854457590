<template>
  <div v-if="loadingStatus === 'loaded'" v-html="icon" class="marker-icon" />
  <div v-else />
</template>

<script setup>
import { ref } from "vue";
import { useLoading } from "@/stores/loading";
import { usePatientStore } from "@/stores/patient";

const props = defineProps({
  marker: Object,
});

const { loadingStatus, finishLoading } = useLoading();
const patientStore = usePatientStore();
const icon = ref("");
const path = props.marker.code.toLowerCase();

import(`./svgs/Biomarkers/${path}.js`)
  .then((i) => (icon.value = i.default))
  .then(finishLoading)
  .catch((e) => {
    // some icons are different per sex, this should be the case here, so we'll try again appending the sex to the icon name
    const sex = patientStore.patient.bioSex.toLowerCase();
    import(`./svgs/Biomarkers/${path}-${sex}.js`)
      .then((i) => (icon.value = i.default))
      .then(finishLoading);
  });
</script>

<style>
.marker-icon svg {
  @apply h-6 w-6 fill-current;
  stroke-width: 2;
}
</style>
