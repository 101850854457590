<template>
  <div class="bg-white min-h-screen p-6 pt-12">
    <div v-if="loadingStatus === 'loaded'">
      <router-link
        :to="{ name: 'health-profile' }"
        class="bg-white font-semibold flex items-center"
      >
        <AppIcon icon="chevron-left" class="h-5 w-5 mr-2" />

        {{ patient.firstName }}'s Health Profile
      </router-link>

      <div
        class="mx-auto pt-16"
        :class="$route.meta.fullWidth ? 'max-w-3xl' : 'max-w-xs'"
      >
        <router-view :patient="patient" :partners="{}" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { usePatientStore } from "@/stores/patient";
import { useLoading } from "@/stores/loading";

const patientStore = usePatientStore();
const patient = ref({});

const { loadingStatus, finishLoading } = useLoading();

onMounted(async () => {
  patient.value = await patientStore.patient;
  finishLoading();
});
</script>
