<template>
  <div class="content">
    <slot />

    <div class="flex flex-wrap mt-6">
      <div
        v-if="
          !patientStore.patient?.questionnaireAnswers?.wellbeing_goals?.length
        "
      >
        You haven't added any wellbeing goals yet
      </div>
      <div
        v-for="goal in patientStore.patient.questionnaireAnswers
          ?.wellbeing_goals"
        :key="goal"
        class="bg-cobalt-700 text-white py-2 px-4 //text-white rounded flex items-center space-x-2 mr-2 mb-2"
      >
        <YourHealthGoalsIcon class="text-white" :health-goal="getGoal(goal)" />
        <span>{{ getGoal(goal).name }}</span>
      </div>
      <button
        @click="showModal = true"
        class="block w-full mt-2 font-semibold text-left text-cobalt-500 text-sm"
      >
        Update health goals
      </button>
    </div>
  </div>
  <AppModal :open="showModal" @closed="showModal = false">
    <div>
      <form @submit.prevent="saveAnswers" class="mt-4">
        <BaseCheckbox
          v-model="patientAnswers.wellbeing_goals"
          label="Wellbeing goals"
          :options="wellbeingGoals"
          hide-label
        />
        <div
          v-if="errorMessage"
          class="bg-rose-50 text-rose-500 text-sm rounded p-4 mt-4"
        >
          {{ errorMessage }}
        </div>
        <SubmitButton class="mt-4" :loading="saveStatus === 'saving'" hide-icon>
          Save
        </SubmitButton>
      </form>
    </div>
  </AppModal>
</template>

<script setup>
import { ref } from "vue";
import { usePatientStore } from "@/stores/patient";
import YourHealthGoalsIcon from "./YourHealthGoalsIcon.vue";
import AppModal from "./AppModal.vue";
import { useSaving } from "@/stores/loading";
import { useQuestionnaireStore } from "@/stores/questionnaire";
import { useAuth } from "@/stores/account";
import { wellbeingGoals } from "@/stores/dropdowns";
import SubmitButton from "./SubmitButton.vue";

const emit = defineEmits(["wellbeing-goals-updated"]);

const { saveStatus, startSaving, finishSaving, errorMessage } = useSaving();

const authStore = useAuth();
const questionnaireStore = useQuestionnaireStore();
const patientStore = usePatientStore();
const showModal = ref(false);

const initialData = {
  exercise_days: null,
  exercise_hours: null,
  alcohol_units: null,
  cigarettes: {},
  pregnant: null,
  pregnancy_trimester: null,
  menstrual_cycle: null,
  wellbeing_goals: [],
  purpose: null,
  height: {
    value: null,
    unit: "cm",
  },
  weight: {
    value: null,
    unit: "kg",
  },
  blood_pressure: {
    systolic: null,
    diastolic: null,
  },
  past_conditions: [],
  allergies: [],
  past_supplements: [],
  past_surgeries: [],
  family_medical_conditions: [],
  symptoms_past_month: [],
  prescription_drugs: [],
  recreational_drugs: [],
  diet_style: [],
  personal_health: "",
};

const patientAnswers = ref(
  patientStore.patient.questionnaireAnswers
    ? patientStore.patient.questionnaireAnswers
    : initialData
);

function getGoal(goal) {
  return wellbeingGoals.find((g) => g.id === goal);
}

async function saveAnswers() {
  if (patientAnswers.value.wellbeing_goals.length > 3) {
    errorMessage.value = "Please select 3 or fewer health goals";
    return;
  }
  startSaving();
  questionnaireStore
    .saveAnswers({
      answers: patientAnswers.value,
      userUuid: authStore.user().uuid,
    })
    .then(() => {
      finishSaving();
      showModal.value = false;
      emit("wellbeing-goals-updated");
      // router.push({ name: "create-profile-success" });
    })
    .catch((error) => {
      console.error(error);
      finishSaving();
    });
}
</script>
