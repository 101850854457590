<template>
  <div>
    <h3 class="text-denim-700 text-2xl font-normal text-center">
      Enter activation code
    </h3>
    <p class="text-grey-700 text-center mt-4 mb-8 leading-normal">
      The activation code can be found on the test ID card included with your
      health testing kit.
    </p>
    <form @submit.prevent="submitActivationCode">
      <div>
        <label for="activation-code" class="sr-only">Activation code</label>
        <input
          type="text"
          name="activation-code"
          v-model="activationCode"
          class="mt-1 border border-grey-300 rounded w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
        />
      </div>
      <div
        v-if="errorMessage"
        class="p-4 text-rose-500 bg-rose-50 rounded mt-4"
      >
        {{ errorMessage }}
      </div>
      <!-- <div v-if="error" class="text-sm text-rose-500 mt-2">{{ error }}</div> -->
      <button
        :class="{
          loading: status === 'submitting',
          'bg-grey-200 text-grey-500': !codeValid,
          'bg-cobalt-500 text-white': codeValid,
        }"
        :disabled="!codeValid"
        class="mt-4 rounded w-full py-2 px-4 font-semibold"
      >
        Continue
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useActivationStore } from "@/stores/activation";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const activationStore = useActivationStore();

const status = ref("idle");
const activationCode = ref("");
const errorMessage = ref("");

const route = useRoute();

if (Object.keys(route.query).length) {
  activationCode.value = Object.keys(route.query)[0];
}

const codeValid = computed(() => {
  return activationCode.value.length === 10;
});

async function submitActivationCode() {
  status.value = "submitting";
  errorMessage.value = "";
  try {
    await activationStore.checkActivationCode(activationCode.value);
    if (activationStore.kit.activated) {
      throw new Error("This kit has already been activated");
    }
    activationCode.value = "";
    router.push({ name: "activate-todo" });
  } catch (e) {
    console.error(e.message);
    if (e.response?.status === 404) {
      errorMessage.value = "No kit found matching that activation code";
    } else if (e.message === "This kit has already been activated") {
      errorMessage.value = e.message;
    } else {
      errorMessage.value = "Something went wrong activating your kit";
    }
  } finally {
    status.value = "idle";
  }
}
</script>
