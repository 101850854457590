/* eslint-disable */
const SPLITNAME = "--REFRESHTOKEN--";

export default {
  request: function (req: any, token: string) {
    let splittoken = token.split(SPLITNAME);
    if (splittoken.length === 2) {
      // @ts-ignore
      if (req.url === this.options.refreshData.url) {
        req.data = {
          refresh_token: splittoken[1],
        };
      }
    }

    // @ts-ignore
    if (req.url !== this.options.refreshData.url) {
      // @ts-ignore
      this.drivers.http.setHeaders.call(this, req, {
        Authorization: "Bearer " + splittoken[0],
      });
    }
  },

  response: function (res: any) {
    if (res.data.token && res.data.refresh_token) {
      const token = `${res.data.token}${SPLITNAME}${res.data.refresh_token}`;
      return token;
    }
  },
};
