<template>
  <div>
    <div class="flex flex-stretch min-h-screen relative">
      <div class="w-full md:max-w-lg p-8 flex flex-col flex-stretch">
        <IconRow />
        <div class="flex-1 flex">
          <div class="mx-auto md:m-auto w-full max-w-md">
            <div class="mt-12 //md:-mt-12">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
      <div
        :style="{ backgroundImage: `url(${imageSrc}` }"
        class="bg-cover bg-center flex-1 hidden md:flex flex-col relative"
      >
        <div
          style="
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 100%
            );
            top: 60%;
          "
          class="absolute inset-0"
        ></div>

        <footer-links white />
      </div>
    </div>
    <footer-links class="pl-8 md:hidden" />
  </div>
</template>

<script>
import IconRow from "@/components/LoginPartials/IconRow.vue";
import FooterLinks from "@/components/LoginPartials/FooterLinks.vue";

export default {
  name: "ImagePanel",
  props: {
    imageSrc: {
      required: true,
      type: String,
    },
  },
  components: { IconRow, FooterLinks },
};
</script>
