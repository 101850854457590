<template>
  <div class="flex flex-col">
    <div class="bg-green-100 rounded-full p-2 inline-flex mx-auto">
      <AppIcon
        icon="check"
        class="fill-current text-green-600 m-auto h-8 w-8"
        :containerClasses="['flex']"
        :classes="['m-auto']"
      />
    </div>
    <h1 class="text-denim-700 text-2xl font-normal text-center mt-4">
      You have completed your health questionnaire
    </h1>
    <p class="mt-4 mb-0 text-grey-700">
      You've completed your health questionnaire, please go ahead and take your
      sample.
      <br />
      <br />
      Remember to do this in the morning, Monday to Thursday, and post your
      sample back to us the same day
    </p>
    <KitProgress
      :kit="activation.kit"
      :test="activation.test"
      :collection-method="collectionMethod"
      :video-watched="videoWatched"
      questionnaire-completed
      class="my-8"
    />

    <div
      v-if="collectionMethod === 'FPK'"
      ref="playerElement"
      id="video-player"
      style="height: 252px"
      class="bg-grey-100 mb-6"
    />

    <router-link
      :to="{ name: 'dashboard' }"
      class="bg-cobalt-500 text-white rounded py-2 text-center"
    >
      <span class="text-white">Visit your dashboard</span>
    </router-link>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import KitProgress from "@/components/KitProgress.vue";
import { useActivationStore } from "@/stores/activation";
import Player from "@vimeo/player";
import { getTestDetailsForKit } from "@/stores/patient";
import { parseSku } from "@/services/kit";

const activation = useActivationStore();
const videoWatched = ref(false);
const playerElement = ref(null);
const { collectionMethod } = parseSku(activation.kit.sku);

const kitDetails = getTestDetailsForKit(activation.kit);

const videoUrls = {
  DNAWA04: "https://vimeo.com/805893710/d7db5cc7f6",
  DNAWA05: "https://vimeo.com/805899938/2e19f86c9e",
  DNAWA01: "https://vimeo.com/805893710/d7db5cc7f6",
  DNAWA02: "https://vimeo.com/805899938/2e19f86c9e",
};

if (collectionMethod === "FPK") {
  onMounted(() => {
    console.log(1, activation.kit);
    console.log(playerElement.value.offsetWidth);
    const options = {
      url: videoUrls[kitDetails["Living Kit Type"]],
      width: playerElement.value.offsetWidth,
    };
    var videoPlayer = new Player("video-player", options);
    videoPlayer.on("play", function () {
      videoWatched.value = true;
    });
  });
}
</script>
