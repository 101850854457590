import { defineStore } from "pinia";
import { useAuth } from "@/stores/account";
import { makeHealthRequest } from "@/services/healthBackend";
import { ref, computed } from "vue";
import { mapResult } from "./transformation";
import { testDetails, TestDetails } from "@/services/static";
import { wellbeingGoals as staticGoals } from "@/stores/dropdowns";
import { mapGeneticResult } from "./geneticTransformation";

interface Test {
  name: string;
  image: string;
  result: any;
  barcode: string;
  craftSlug: string;
}
interface NextBloodTest {
  name: string;
  dueDate: Date;
  shouldOrderDate: Date;
  image: string;
  craftSlug: string;
}

interface CreatePatientDetails {
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  bioSex: string;
  dateOfBirth: string;
}

interface GeneticTest {
  result: {
    results: any[];
    hasAnyResult: boolean;
  };
}

type GeneticTestResultType = GeneticTest | false;

interface Patient {
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  bioSex: string;
  dateOfBirth: string;
  questionnaireAnswers: object;
  tests: Array<Test>;
  appointmentInfo: string;
  ageGroup: string;
  geneticTest: GeneticTestResultType;
  phone: string;
  region: string;
  linkedProfile: string;
}

function mostRecentFirst(a: { createdAt: string }, b: { createdAt: string }) {
  return Date.parse(b.createdAt) - Date.parse(a.createdAt);
}

export function getTestDetailsForKit(kit: { sku: string }) {
  let details;
  details = testDetails.find((test: TestDetails) => test.SKU === kit.sku);
  if (!details) {
    details = testDetails.find(
      (test: TestDetails) => test["WP SKU"] === kit.sku
    );
    if (!details) {
      throw Error(`Details could not be found for ${kit.sku}`);
    }
  }
  return details;
}

async function getTestsForUser(user: any) {
  return await makeHealthRequest({
    method: "GET",
    path: `/patients/${user.uuid}/tests`,
  });
}

async function getKitAndOrderForTest(test: any) {
  const { data } = await makeHealthRequest({
    method: "GET",
    path: `/kits/${test.barcode}`,
  });
  const order = JSON.parse(data.order.rawJson);
  return { kit: data, order };
}

async function getResult(test: any, user: any) {
  return await makeHealthRequest({
    method: "GET",
    path: `/reports/${test.barcode}/patients/${user.uuid}`,
  });
}

function formatTestWithResult(test: any, result: any, kit: any, order: any) {
  const parsedResult = JSON.parse(result.data.rawJson);
  return {
    ...test,
    name: kit.testType === "bloodTest" ? getTestDetailsForKit(kit).Name : "",
    image: kit.testType === "bloodTest" ? getTestDetailsForKit(kit).Image : "",
    questionnaireAnswers: JSON.parse(test.questionnaireAnswers),
    result:
      kit.testType === "bloodTest"
        ? mapResult(parsedResult)
        : mapGeneticResult(parsedResult),
    kit,
    order,
    craftSlug:
      kit.testType === "bloodTest"
        ? getTestDetailsForKit(kit)["Craft Slug"]
        : "",
  };
}

function formatTestWithoutResult(test: any, kit: any, order: any) {
  return {
    ...test,
    name: kit.testType === "bloodTest" ? getTestDetailsForKit(kit).Name : "",
    image: kit.testType === "bloodTest" ? getTestDetailsForKit(kit).Image : "",
    craftSlug:
      kit.testType === "bloodTest"
        ? getTestDetailsForKit(kit)["Craft Slug"]
        : "",
    questionnaireAnswers: JSON.parse(test.questionnaireAnswers),
    result: false,
    kit,
    order,
  };
}

const instanceDict = {
  1: "First",
  2: "Second",
  3: "Third",
  4: "Fourth",
  5: "Fifth",
  6: "Sixth",
  7: "Seventh",
  8: "Eigth",
  9: "Ninth",
  10: "Tenth",
};

type InstanceKeys = keyof typeof instanceDict;

function getInstanceName(location: InstanceKeys) {
  return instanceDict[location];
}

function getAgeGroupForPatient(patient: Patient) {
  const age =
    new Date().getFullYear() - new Date(patient.dateOfBirth).getFullYear();
  if (age < 30) {
    return "18-29";
  }
  if (age < 40) {
    return "30-39";
  }
  if (age < 50) {
    return "40-49";
  }
  if (age < 60) {
    return "50-59";
  }
  if (age < 70) {
    return "60-69";
  }
  if (age < 80) {
    return "70-79";
  }
  if (age < 90) {
    return "80-89";
  }
  if (age < 100) {
    return "90-99";
  }
  return "over100";
}

export const usePatientStore = defineStore("patient", () => {
  const auth = useAuth();

  const initialPatient = {
    userUuid: "",
    firstName: "",
    lastName: "",
    email: "",
    bioSex: "",
    dateOfBirth: "",
    appointmentInfo: "",
    tests: [] as any[],
    questionnaireAnswers: {},
    ageGroup: "",
    geneticTest: { result: { results: [], hasAnyResult: false } },
    phone: "",
    region: "",
    linkedProfile: "",
  };

  const patient = ref<Patient>({ ...initialPatient });
  const mostRecentTest = ref({} as Test);

  function reset() {
    patient.value = { ...initialPatient };
    mostRecentTest.value = {} as Test;
  }

  async function createPatient(patientDetails: CreatePatientDetails) {
    return await makeHealthRequest({
      path: "/patients",
      method: "POST",
      body: patientDetails,
    });
  }

  async function getPatient() {
    const fullPatient = await makeHealthRequest({
      path: `/patients/${auth.user().uuid}`,
      method: "GET",
    });

    const tests = await getTestsForUser(auth.user());

    const formattedTests = (
      await Promise.all(
        tests.data.map(
          async (test: { questionnaireAnswers: string; barcode: string }) => {
            const { kit, order } = await getKitAndOrderForTest(test);

            try {
              const result = await getResult(test, auth.user());

              return formatTestWithResult(test, result, kit, order);
            } catch (e) {
              return formatTestWithoutResult(test, kit, order);
            }
          }
        )
      )
    )
      .sort(mostRecentFirst)
      .map((test, index, fullArr) => {
        return {
          ...test,
          instance: getInstanceName(
            (fullArr.filter((test) => test.kit.testType === "bloodTest")
              .length - index) as InstanceKeys
          ),
        };
      });

    const formattedBloodTests = formattedTests.filter((test) => {
      {
        return test.kit.testType === "bloodTest";
      }
    });

    const [latestTest] = formattedBloodTests;

    const geneticTest = formattedTests.find((test) => {
      return test.kit.testType === "geneticTest";
    });

    patient.value = {
      ...fullPatient.data,
      ageGroup: getAgeGroupForPatient(fullPatient.data),
      tests: formattedBloodTests,
      questionnaireAnswers: JSON.parse(fullPatient.data.questionnaireAnswers),
      geneticTest: geneticTest ? geneticTest : false,
    };

    if (patient.value.geneticTest && patient.value.geneticTest.result) {
      patient.value.geneticTest.result.hasAnyResult =
        patient.value.geneticTest.result.results.filter(
          (r) => !["Locked", "Awaiting Results"].includes(r.result_value)
        ).length > 0;
    }

    mostRecentTest.value = latestTest;

    return patient.value;
  }

  const nextBloodTest = computed<NextBloodTest>(() => {
    if (mostRecentTest.value.result) {
      let nextTestDue;
      const lastTestDate = new Date(mostRecentTest.value.result.date);
      if (mostRecentTest.value.result.nextTestDate) {
        nextTestDue = new Date(mostRecentTest.value.result.nextTestDate);
      } else {
        nextTestDue = new Date(
          lastTestDate.setMonth(lastTestDate.getMonth() + 3)
        );
      }

      const copy = new Date(nextTestDue);

      return {
        name: mostRecentTest.value.name,
        dueDate: nextTestDue,
        shouldOrderDate: new Date(copy.setDate(copy.getDate() - 7)),
        image: mostRecentTest.value.image,
        craftSlug: mostRecentTest.value.craftSlug,
      };
    }
    return {
      name: "Undefined",
      dueDate: new Date(),
      shouldOrderDate: new Date(),
      image: "Undefined",
      craftSlug: "Not available",
    };
  });

  function getGoal(goal: string) {
    return staticGoals.find((g) => g.id === goal);
  }

  const wellbeingGoals = computed(() => {
    const answers = patient.value.questionnaireAnswers as {
      wellbeing_goals: [];
    };
    return answers?.wellbeing_goals?.map(getGoal) ?? [];
  });

  function getTestByBarcode(barcode: string) {
    return patient.value.tests.find((test: Test) => {
      return test.barcode === barcode;
    });
  }

  async function editPatient(updatedFields: object) {
    // we only need a subset of fields from the patient, so can't use patient.value by itself
    const existingPatient = {
      userUuid: patient.value.userUuid,
      firstName: patient.value.firstName,
      lastName: patient.value.lastName,
      email: patient.value.email,
      bioSex: patient.value.bioSex,
      dateOfBirth: patient.value.dateOfBirth,
      appointmentInfo: patient.value.appointmentInfo,
      phone: patient.value.phone,
      region: patient.value.region,
      linkedProfile: patient.value.linkedProfile,
    };

    await makeHealthRequest({
      method: "PUT",
      path: `/patients/${patient.value.userUuid}`,
      body: {
        ...existingPatient,
        ...updatedFields,
      },
    });
  }

  return {
    getPatient,
    patient,
    mostRecentTest,
    getTestByBarcode,
    nextBloodTest,
    editPatient,
    createPatient,
    wellbeingGoals,
    reset,
  };
});
