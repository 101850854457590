<template>
  <v-select>
    <template v-slot:selected-option="option">
      {{ option.label }}
    </template>
    <template v-slot:option="option">
      {{ option.label }}
    </template>
  </v-select>
</template>

<script setup></script>

<style>
.vs__dropdown-toggle {
  @apply rounded-md border-gray-300 shadow-sm focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm !important;
  height: 40px;
}

.vs__selected-options .vs__selected {
  @apply flex mt-0 pl-2;
  height: 36px;
}

.vs__search {
  @apply border-transparent bg-transparent outline-none ring-0 !important;
}

.vs__clear,
.vs__open-indicator {
  @apply -mt-1;
}

.vs__actions {
  @apply pr-3 !important;
}
</style>
