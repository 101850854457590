<template>
  <div
    v-if="VUE_APP_AUTH_PROVIDER === 'clerk'"
    class="bg-cobalt-500 text-white rounded-full flex justify-center items-center"
  >
    {{ patientStore.patient.firstName?.substring(0, 1)
    }}{{ patientStore.patient.lastName?.substring(0, 1) }}
  </div>
  <img
    v-else
    :src="imagePath || defaultAvatar"
    alt=""
    class="rounded-full //w-full"
  />
</template>

<script setup>
// import { pngUrlToJpg } from "@/services/cloudinary";
import { computed } from "vue";
import defaultAvatar from "@/assets/images/avatar-default.png";
import { usePatientStore } from "@/stores/patient";
const { VUE_APP_AUTH_PROVIDER } = process.env;

const patientStore = usePatientStore();

function convertedImagePath(path) {
  return path;
  //   return pngUrlToJpg(path);
}
const props = defineProps({
  path: String,
});

const imagePath = computed(() => convertedImagePath(props.path));
</script>
