<template>
  <AppPage class="bg-white min-h-screen">
    <div class="container">
      <!-- <TopOfPageBackLink :to="{ name: 'profiles' }">
        Profiles
      </TopOfPageBackLink> -->
    </div>
    <div class="max-w-2xl mx-auto">
      <ProfileViewHeader :patient="patient" class="pt-16" />
      <div class="w-full pt-16">
        <div class="flex flex-col-reverse md:flex-row">
          <div class="w-full space-y-12">
            <PatientViewDetails :patient="patient" />
            <PatientViewHealthDetails />
            <PatientViewSubscriptions />
            <PatientViewHealthPrivacy />
          </div>
          <!-- <ProfileOverview :patient="patient" /> -->
        </div>
      </div>
    </div>
  </AppPage>
</template>

<script setup>
import { usePatientStore } from "@/stores/patient";

import ProfileViewHeader from "./ProfileViewHeader.vue";
import ProfileOverview from "@/views/Profiles/ProfileView/ProfileOverview.vue";
import PatientViewDetails from "./PatientViewDetails.vue";
import PatientViewHealthDetails from "./PatientViewHealthDetails.vue";
import PatientViewHealthPrivacy from "./PatientViewHealthPrivacy.vue";
import PatientViewSubscriptions from "./PatientViewSubscriptions.vue";

const patientStore = usePatientStore();
const patient = patientStore.patient;
</script>
