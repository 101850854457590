<template>
  <div>
    <label
      :for="id"
      class="block font-semibold text-sm"
      :class="{ 'sr-only': hideLabel, 'text-grey-600': reduceLabel }"
    >
      {{ label }}
    </label>
    <p v-if="description" class="text-sm leading-5 text-gray-500">
      {{ description }}
    </p>
    <fieldset :class="{ 'mt-2': !hideLabel }">
      <div class="space-y-4">
        <select
          :id="id"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
          v-model="selected"
        >
          <option
            :value="option.value"
            v-for="option in options"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </fieldset>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  options: Array,
  label: String,
  description: Array,
  id: String,
  required: Boolean,
  modelValue: null,
  hideLabel: Boolean,
  reduceLabel: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const selected = ref(props.modelValue);

watch(selected, () => {
  emit("update:modelValue", selected.value);
});
</script>
