<template>
  <div class="bg-white min-h-screen p-6 pt-12">
    <div v-if="loadingStatus === 'loaded'">
      <router-link
        :to="{ name: 'account-view' }"
        class="bg-white font-semibold flex items-center"
      >
        <AppIcon icon="chevron-left" class="h-5 w-5 mr-2" />

        {{ `${account.firstName} ${account.lastName}` }}'s Account
      </router-link>

      <div
        class="mx-auto pt-16"
        :class="$route.meta.fullWidth ? 'max-w-3xl' : 'max-w-xs'"
      >
        <router-view :account="account" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuth } from "@/stores/account";

const loadingStatus = ref("loading");

const auth = useAuth();
const account = auth.user();

onMounted(async () => {
  loadingStatus.value = "loaded";
});
</script>
