<template>
  <div>
    <div
      :style="{ backgroundImage: `url('${headerBg}')` }"
      class="h-48 flex items-center justify-center"
    >
      <h1 class="text-2xl text-white">How to take your test</h1>
    </div>
    <div class="max-w-2xl m-auto pt-16">
      <p class="mb-4">
        For instructions on how to take your test you have the option of:
      </p>
      <ul class="list-disc pl-8">
        <li>Watching the video below or;</li>
        <li>Follow our written instructions with pictures instead here.</li>
      </ul>

      <div class="py-16">
        <div
          ref="playerElement"
          id="video-player"
          style="height: 352px"
          class="bg-grey-100 mb-6"
        />
      </div>
    </div>
    <div v-if="false" class="bg-grey-50 py-16">
      <div class="//max-w-2xl //m-auto">img-1</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Player from "@vimeo/player";
import headerBg from "@/assets/images/header-bg.webp";

const playerElement = ref(null);

onMounted(() => {
  const options = {
    url: "https://vimeo.com/805491487/9646c9bf62",
    width: playerElement.value.offsetWidth,
  };
  var videoPlayer = new Player("video-player", options);
});
</script>
