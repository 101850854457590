import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { getResult } from "@/stores/transformation";
import { usePatientStore } from "./patient";

interface Result {
  id: string;
  testName: string;
  date: string;
  doctor: Doctor;
  medicalReport: string;
  panels: Array<Panel>;
  testFailed: boolean;
}

interface Doctor {
  name: string;
  photo: string;
}

interface Panel {
  name: string;
  markers: Array<Marker>;
}

interface Marker {
  panel?: string;
  name: string;
  description: string;
  icon: string;
  result: number;
  resultUnit: string;
  resultDescription: string;
  range: RangeGroup;
  resultRangeStage: Stage;
}

interface RangeGroup {
  lowestPossible: number;
  highestPossible: number;
  stages: Array<Stage>;
}

interface Stage {
  id?: string;
  name: stageName;
  lowest: number;
  middle?: number;
  highest: number;
}

type stageName =
  | "rangeLow"
  | "rangeLowNormal"
  | "rangeNormal"
  | "rangeHighNormal"
  | "rangeHigh";

function mostRecentFirst(a: Result, b: Result) {
  return Date.parse(a.date) - Date.parse(b.date);
}

type ResultList = Array<Result>;

export const useResultsStore = defineStore("results", () => {
  const bloodResults = ref<ResultList>([]);

  function reset() {
    bloodResults.value = [];
  }

  async function setBloodResults(patient: any) {
    const results = patient.tests.map((t: any) => t.result).filter(Boolean);
    bloodResults.value = results;
  }

  const bloodResultsMostRecentFirst = computed(() => {
    return [...bloodResults.value].sort(mostRecentFirst);
  });

  const latestBloodResult = computed(() => {
    const [latest] = bloodResultsMostRecentFirst.value;
    return latest;
  });

  function getBloodResultById(id: string) {
    return bloodResults.value.find((results) => results.id === id);
  }

  return {
    setBloodResults,
    bloodResultsMostRecentFirst,
    bloodResults,
    latestBloodResult,
    getBloodResultById,
    reset,
  };
});

export function getLowestRangeForPanel(panel: Panel) {
  const stages = panel.markers.map((marker) => marker?.resultRangeStage?.name);
  if (stages.includes("rangeLow") || stages.includes("rangeHigh")) {
    return "rangeLow";
  }
  if (stages.includes("rangeLowNormal") || stages.includes("rangeHighNormal")) {
    return "rangeLowNormal";
  }
  return "rangeNormal";
}
