<template>
  <div>
    <div
      :style="{ backgroundImage: `url('${headerBg}')` }"
      class="h-48 flex items-center justify-center"
    >
      <h1 class="text-2xl text-white">How to take your test</h1>
    </div>
    <div class="max-w-2xl m-auto pt-16">
      <p class="mb-4">
        For instructions on how to take your test you have the option of:
      </p>
      <ul class="list-disc pl-8">
        <li>Watching the video below or;</li>
        <li>Follow our written instructions with pictures instead here.</li>
      </ul>

      <div class="py-16">
        <div
          ref="playerElement"
          id="video-player"
          style="height: 352px"
          class="bg-grey-100 mb-6"
        />
      </div>
    </div>
  </div>
  <div v-if="false" class="bg-grey-50 py-16">
    <div class="max-w-5xl m-auto">
      <div class="relative flex mb-48">
        <img :src="step1" alt="" class="w-1/2" />
        <div
          class="relative w-1/2 bg-white shadow p-4 content"
          style="top: 40px; left: -40px"
        >
          <div>
            <h3 class="mb-2 font-semibold">Step 1</h3>
            <p>
              Lay out the contents of your testing kit on a clean table, and
              check you have all the components.
            </p>
            <p>
              If any component is missing, call +44 (0) 203 9895 654 or email
              health@dnaworkplace.com for a replacement kit.
            </p>
          </div>
        </div>
      </div>

      <div class="relative flex items-start">
        <img :src="step1" alt="" class="w-1/2" />
        <div
          class="relative w-1/2 bg-white shadow p-4 content"
          style="top: 40px; left: -40px"
        >
          <div>
            <h3 class="mb-2 font-semibold">Step 3</h3>
            <p>
              Read the specific pre-test requirements emailed to you & activate
              your test at my.dnaworkplace.com/activate*
            </p>

            <p>
              Please note: Even if you may have done a fingerprick blood test
              before, it is important to follow these instructions carefully to
              ensure your sample is suitable for testing in our laboratories.
            </p>

            <p>
              *We cannot process your sample until you have completed this step
              and signed the declaration that the sample belongs to you.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Player from "@vimeo/player";
import headerBg from "@/assets/images/header-bg.webp";
import step1 from "@/assets/images/instructions/one-vial/step-1.webp";

const playerElement = ref(null);

onMounted(() => {
  const options = {
    url: "https://vimeo.com/805508949/a142b9632b",
    width: playerElement.value.offsetWidth,
  };
  var videoPlayer = new Player("video-player", options);
});
</script>
