import { defineStore } from "pinia";
import { makeHealthRequest } from "@/services/healthBackend";
interface Questionnaire {
  exercise_days: number;
  exercise_minutes: number;
  cigarettes: number;
  alcohol_units: number;
  pregnant: boolean; // any chance of maybe?
  pregnancy_trimester: trimester;
  weight: number;
  weight_units: WeightUnits;
  height: number;
  height_units: HeightUnits;
  bmi: number; // don't send, WP calculate?
  blood_pressure_sys: number;
  blood_pressure_dia: number;
  menstrual_cycle: MenstrualCycle;
  wellbeing_goals: WellbeingGoals;
  first_test: boolean;
  purpose: string;

  past_conditions: Array<number>;
  past_conditions_other: Array<string>;
  regular_supplements: Array<number>;
  regular_supplements_other: Array<string>;
  previous_surgery: Array<number>;
  previous_surgery_other: Array<string>;
  previous_surgery_date: Date;
  family_medical_relative: Array<string>; // relatives
  family_medical_relative_other: string;
  family_medical_condition: Array<string>;
  family_medical_condition_other: Array<string>;
  past_month_symptoms: Array<number>;
  past_month_symptoms_other: Array<string>;
  perscription_drug_type: Array<string>;
  perscription_drug_type_other: Array<string>;
  perscription_drug_frequency: string; // get union
  perscription_drug_date_last_taken: Date;
  recreational_drugs: Array<RecreationalDrug>;
  recreational_drug_type_other: Array<RecreationalDrug>;
  diet_style: string; // get union
  diet_style_other: string;
  diet_start_date: Date;
}

type trimester = "first_trimester" | "second_trimester" | "third_trimester";

type WeightUnits = "kg" | "lbs";
type HeightUnits = "cm" | "ft";

type MenstrualCycle = "menstruation" | "follicular" | "ovulation" | "luteal";

type WellbeingGoals = Array<WellbeingGoal>;

interface WellbeingGoal {
  name:
    | "I want to improve my general wellbeing"
    | "I want to monitor known health condition(s)"
    | "I want to improve control of my health condition(s)"
    | "I want to improve my cardiovascular health (this includes lowering cholesterol)"
    | " I want to lose weight"
    | "I want to become more active"
    | "I want to reduce my risks of developing a medical illness"
    | "I want to feel less tired"
    | "I want to feel less stressed"
    | "I want to manage my hormones (including improving fertility);";
}

type PrescriptionDrugFrequency =
  | "once per day"
  | "twice per day"
  | "more than three times per day"
  | "once per week"
  | "two to four times per week"
  | "five to six times per week"
  | "one to three times per month"
  | "Less than once per month";

type RecreationalDrugFrequency =
  | "once per day"
  | "twice per day"
  | "more than three times per day"
  | "once per week"
  | "two to four times per week"
  | "five to six times per week"
  | "one to three times per month"
  | "Less than once per month";

interface RecreationalDrug {
  recreational_drug_type: string;
  recreational_drug_frequency: RecreationalDrugFrequency;
  recreational_drug_date_last_taken: Date;
}

type Relative =
  | "Father"
  | "Mother"
  | "Son"
  | "Daughter"
  | "Uncle"
  | "Aunty"
  | "Cousin"
  | "Grandfather"
  | "Grandmother";

type DietStyles =
  | "Paleo"
  | "Vegan"
  | "Vegetarian"
  | "Pescatarian"
  | "Gluten free"
  | "Dairy free"
  | "Ketogenic"
  | "Low-carb"
  | "Carnivore"
  | "Atkins"
  | "Plant-based"
  | "Low Fodmap";

interface StoreQuestionnaireOptions {
  answers: object;
  userUuid: string;
}

export const useQuestionnaireStore = defineStore("questionnaire", () => {
  async function saveAnswers({ answers, userUuid }: StoreQuestionnaireOptions) {
    const { data } = await makeHealthRequest({
      path: `/patients/${userUuid}/answers`,
      method: "PUT",
      body: {
        userUuid,
        answers: JSON.stringify(answers),
      },
    });
    return data;
  }
  return { saveAnswers };
});
