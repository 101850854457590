<template>
  <div>
    <slot
      :outOfRange="numberOfResultsInRange(['rangeLow', 'rangeHigh'])"
      :inRange="numberOfResultsInRange('rangeNormal')"
      :slightlyElevated="numberOfResultsInRange('rangeHighNormal')"
      :slightlyReduced="numberOfResultsInRange('rangeLowNormal')"
      :colors="colorMap"
    >
      <div class="space-y-2">
        <div class="flex items-center">
          <div
            :style="`background-color: ${colorMap['rangeLow']}`"
            class="h-4 w-4 rounded-full mr-2"
          ></div>
          {{ numberOfResultsInRange(["rangeLow", "rangeHigh"]) }}
          x out of range
        </div>
        <div
          v-if="numberOfResultsInRange(['rangeLowNormal']) > 0"
          class="flex items-center"
        >
          <div
            :style="`background-color: ${colorMap['rangeLowNormal']}`"
            class="h-4 w-4 rounded-full mr-2"
          ></div>
          {{ numberOfResultsInRange(["rangeLowNormal"]) }}
          x slightly reduced
        </div>
        <div
          v-if="numberOfResultsInRange(['rangeHighNormal']) > 0"
          class="flex items-center"
        >
          <div
            :style="`background-color: ${colorMap['rangeHighNormal']}`"
            class="h-4 w-4 rounded-full mr-2"
          ></div>
          {{ numberOfResultsInRange(["rangeHighNormal"]) }}
          x slightly elevated
        </div>
        <div class="flex items-center">
          <div
            :style="`background-color: ${colorMap['rangeNormal']}`"
            class="h-4 w-4 rounded-full mr-2"
          ></div>
          {{ numberOfResultsInRange("rangeNormal") }}
          x normal range
        </div>
      </div>
    </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  bloodTest: Object,
});

const colorMap = {
  rangeLow: "#ef4e4e",
  rangeLowNormal: "#ff9466",
  rangeNormal: "#3ebd93",
  rangeHighNormal: "#ff9466",
  rangeHigh: "#ef4e4e",
};

function numberOfResultsInRange(range) {
  if (typeof range === "string") {
    return props.bloodTest.panels
      .flatMap((panel) =>
        panel.markers.map((marker) => marker?.resultRangeStage?.name)
      )
      .filter((rangeName) => rangeName === range).length;
  }
  if (Array.isArray(range)) {
    // console.log("this", props.bloodTest);
    return props.bloodTest.panels
      .flatMap((panel) =>
        panel.markers.map((marker) => marker?.resultRangeStage?.name)
      )
      .filter((rangeName) => range.includes(rangeName)).length;
  }
}
</script>
