import ProfileView from "@/views/Profiles/ProfileView/ProfileView.vue";
import ProfileEdit from "@/views/Profiles/ProfileEdit/ProfileEdit.vue";
import EditFirstName from "@/views/Profiles/ProfileEdit/details/EditFirstName.vue";
import EditLastName from "@/views/Profiles/ProfileEdit/details/EditLastName.vue";
import EditBiologicalSex from "@/views/Profiles/ProfileEdit/details/EditBiologicalSex.vue";
import EditLifetime from "@/views/Profiles/ProfileEdit/details/EditDateOfBirth.vue";
import StartDeletion from "@/views/Profiles/ProfileEdit/details/Delete/StartDeletion.vue";
import DeleteProfile from "@/views/Profiles/ProfileEdit/details/Delete/DeleteProfile.vue";
import RequestDeletion from "@/views/Profiles/ProfileEdit/details/Delete/RequestDeletion.vue";
import ConfirmDeletion from "@/views/Profiles/ProfileEdit/details/Delete/ConfirmDeletion.vue";
import ReceivedDeletion from "@/views/Profiles/ProfileEdit/details/Delete/ReceivedDeletion.vue";

export default [
  {
    path: "/profile",
    name: "health-profile",
    component: ProfileView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile/edit",
    component: ProfileEdit,
    children: [
      {
        path: "first-name",
        component: EditFirstName,
      },
      {
        path: "last-name",
        component: EditLastName,
      },
      {
        path: "biological-sex",
        component: EditBiologicalSex,
      },
      {
        path: "lifetime",
        component: EditLifetime,
      },
      {
        path: "delete",
        component: DeleteProfile,
        redirect: "delete/start",
        children: [
          {
            path: "start",
            component: StartDeletion,
            name: "start-deletion",
          },
          {
            path: "request",
            component: RequestDeletion,
            name: "request-deletion",
          },
          {
            path: "confirm",
            component: ConfirmDeletion,
            name: "confirm-deletion",
          },
          {
            path: "received",
            component: ReceivedDeletion,
            name: "received-deletion",
          },
        ],
      },
    ],
  },
];
