const { VUE_APP_AUTH_PROVIDER } = process.env;

import { createApp } from "vue";
import router from "./router";
import { createPinia } from "pinia";
import { createAuth } from "@websanova/vue-auth";
import axios from "axios";
import endpoints from "./endpoints";

import VueGtag from "vue-gtag";

// import VueClickAway from "vue3-click-away";

import App from "./App.vue";
import PortalVue from "portal-vue";
import AppIcon from "@/components/Icon/AppIcon.vue";
import BaseTextInput from "@/components/BaseTextInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseRadioInput from "@/components/BaseRadioInput.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import AppButton from "@/components/AppButton.vue";
import AppImage from "@/components/AppImage.vue";
import PageLoader from "@/components/PageLoader.vue";
import AppPage from "@/components/AppPage.vue";
import SectionTitle from "@/components/SectionTitle.vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "./assets/tailwind.css";

const app = createApp(App);
const pinia = createPinia();

import driverAuthBearer from "@/bearerDriver";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";

if (VUE_APP_AUTH_PROVIDER !== "clerk") {
  const auth = createAuth({
    plugins: {
      http: axios,
      router,
    },
    drivers: {
      http: driverHttpAxios,
      auth: driverAuthBearer,
      router: driverRouterVueRouter,
    },
    options: {
      parseUserData(data: object) {
        return data || {};
      },
      rolesKey: "type",
      notFoundRedirect: { path: "/dashboard" },
      authRedirect: { path: "/signin" },
      loginData: {
        url: endpoints.loginApi,
        method: "POST",
        redirect: false,
        fetchUser: true,
        staySignedIn: true,
      },
      refreshData: {
        url: endpoints.refreshToken,
        method: "POST",
        enabled: true,
        interval: 30,
      },
      registerData: {
        url: endpoints.account,
        method: "POST",
        redirect: false,
      },
      fetchData: {
        url: endpoints.account,
        method: "GET",
        enabled: true,
      },
      stores: ["storage"],
      tokenDefaultKey: "token",
    },
  });

  app.use(auth);
}

app.use(router);
app.use(PortalVue);
// app.use(VueClickAway);
app.component("AppIcon", AppIcon);
app.component("BaseTextInput", BaseTextInput);
app.component("BaseRadioInput", BaseRadioInput);
app.component("BaseCheckbox", BaseCheckbox);
app.component("BaseSelect", BaseSelect);
app.component("vSelect", vSelect);
app.component("AppButton", AppButton);
app.component("AppImage", AppImage);
app.component("PageLoader", PageLoader);
app.component("AppPage", AppPage);
app.component("SectionTitle", SectionTitle);

app.use(pinia);

if (process.env.NODE_ENV === "production") {
  app.use(
    VueGtag,
    {
      config: {
        id: "G-K49H7CHXPK",
      },
    },
    router
  );
}

app.mount("#app");
