<template>
  <AppPage class="bg-white min-h-screen pt-16">
    <div class="max-w-3xl mx-auto md:px-12">
      <h1 class="text-2xl text-denim-700 font-normal">Your Account</h1>

      <div class="pt-16 w-full">
        <h2 class="flex items-center text-lg">
          <AppIcon icon="user" class="mr-2" />

          Details
        </h2>

        <div
          class="w-full mt-6 shadow-key rounded-lg flex-1"
          style="border-spacing: 0"
        >
          <router-link
            :to="{ name: 'account-name' }"
            class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b cursor-pointer last:border-b-0 first:rounded-t-lg last:rounded-b-lg flex items-center"
          >
            <div class="pl-6 py-5 text-grey-500 text-sm font-semibold w-2/5">
              Name
            </div>

            <div class="px-4 w-2/5 text-grey-700">
              {{ auth.user().firstName }} {{ auth.user().lastName }}
            </div>

            <div class="w-1/5">
              <div class="flex pr-6 py-5">
                <AppIcon
                  icon="chevron-right"
                  class="text-cobalt-500 my-auto ml-auto"
                />
              </div>
            </div>
          </router-link>

          <router-link
            :to="{ name: 'account-email' }"
            class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b cursor-pointer last:border-b-0 first:rounded-t-lg last:rounded-b-lg flex items-center"
          >
            <div class="pl-6 py-5 text-grey-500 text-sm font-semibold w-2/5">
              Email address
            </div>

            <div class="px-4 w-2/5 truncate text-grey-700">
              {{ auth.user().email }}
            </div>

            <div class="w-1/5">
              <div class="flex pr-6 py-5">
                <AppIcon
                  icon="chevron-right"
                  class="text-cobalt-500 my-auto ml-auto"
                />
              </div>
            </div>
          </router-link>

          <router-link
            :to="{ name: 'account-password' }"
            class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer flex items-center"
          >
            <div class="pl-6 py-5 text-grey-500 text-sm font-semibold w-2/5">
              Password
            </div>

            <div class="px-4 w-2/5 text-grey-700">********</div>

            <div class="w-1/5 pr-6 flex justify-end">
              <AppIcon icon="chevron-right" />
            </div>
          </router-link>
        </div>

        <template v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'">
          <h2 class="flex items-center text-lg mt-12">
            <AppIcon icon="mail" class="mr-2" />

            Email Settings
          </h2>

          <div class="w-full mt-6 bg-white shadow-key rounded-lg flex-1">
            <div
              class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer flex items-center"
              @click="toggleMarketing()"
            >
              <div
                class="pl-6 py-5 text-grey-500 text-sm font-semibold w-3/5"
                style="width: 160px"
              >
                Marketing
              </div>

              <div v-if="!auth.user().marketing" class="p-4 w-3/5">
                <strong>Opted out of marketing -</strong>
                click to opt in
              </div>

              <div v-else class="p-4">
                <strong>Opted into marketing -</strong>
                click to opt out
              </div>
            </div>
          </div>
        </template>

        <h2 class="flex items-center text-lg mt-12">
          <AppIcon icon="shield" class="mr-2" />

          Your Data Privacy
        </h2>

        <div class="w-full mt-6 bg-white shadow-key rounded-lg flex-1">
          <router-link
            class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer flex items-center justify-between"
            :to="{ name: 'start-account-deletion' }"
          >
            <div
              class="pl-6 py-5 text-md text-center text-rose-500 font-semibold"
            >
              Permanently delete account
            </div>

            <div>
              <a
                class="block pr-6 py-5 text-right flex"
                href="https://support.livingdna.com/hc/en-us/articles/360012499411-Who-do-I-speak-to-about-deleting-my-account-data-"
                target="_blank"
              >
                <AppIcon
                  icon="chevron-right"
                  class="h-5 w-5 fill-current text-rose-500 m-auto"
                />
              </a>
            </div>
          </router-link>
        </div>

        <template v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'">
          <h2 class="flex items-center text-lg mt-12">
            <AppIcon icon="help-circle" class="mr-2" />

            FAQ
          </h2>

          <table class="w-full mt-6 bg-white shadow-key rounded-lg flex-1">
            <FAQ
              title="How do I delete my account data?"
              link="https://support.livingdna.com/hc/en-us/articles/360012499411-Who-do-I-speak-to-about-deleting-my-account-data-"
            />

            <FAQ
              title="How do I change my portal email address?"
              link="https://support.livingdna.com/hc/en-us/articles/360012527511-How-do-I-change-my-portal-email-address-"
            />

            <FAQ
              title="I've forgotten my password."
              link="https://support.livingdna.com/hc/en-us/articles/360012498691-Help-I-have-forgotten-my-password-"
            />
          </table>
        </template>
      </div>
    </div>
  </AppPage>
</template>

<script>
const { VUE_APP_AUTH_PROVIDER } = process.env;

import FAQ from "@/components/AccountFAQ.vue";
import { useAccountStore, useAuth } from "@/stores/account";

export default {
  name: "ProfileView",

  components: {
    FAQ,
  },

  setup() {
    const auth = useAuth();
    async function toggleMarketing() {
      await this.accountStore.setAccountFields({
        account: auth.user(),
        fields: { marketing: !auth.user().marketing },
      });
      await auth.fetch();
    }
    return { toggleMarketing, auth, VUE_APP_AUTH_PROVIDER };
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      accountData: {},
    };
  },

  computed: {
    accountStore() {
      return useAccountStore();
    },
  },

  methods: {
    openLink(link) {
      window.open(link);
    },
  },

  async created() {
    this.accountData = "";
  },
};
</script>
<style>
.password-button {
  min-width: 100% !important;
}
</style>
