<template>
  <div v-if="loadingStatus === 'loading'">
    <PageLoader>Loading</PageLoader>
  </div>
  <div class="pb-16" v-else>
    <div class="fade-bottom fade-bottom-grey relative">
      <div class="h-96 relative fade-bottom fade-bottom-grey">
        <img
          :src="productData.header_image[0].url"
          alt=""
          class="object-cover object-center h-96 w-full"
        />
      </div>
    </div>
    <!-- max-w-5xl mx-auto  -->
    <AppPage
      class="relative z-50 px-4 flex flex-col-reverse md:flex-row items-start md:space-x-12"
    >
      <main class="space-y-16 flex-shrink">
        <div class="space-y-6">
          <router-link
            v-if="$route.query.return"
            :to="{
              name: 'genetics-category',
              params: {
                category: $route.query.return,
              },
            }"
            class="flex items-center space-x-2 text-cobalt-500 font-semibold"
          >
            <AppIcon icon="arrow-left" />
            <span>Back to result</span>
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'store-index',
            }"
            class="flex items-center space-x-2 text-cobalt-500 font-semibold"
          >
            <AppIcon icon="arrow-left" />
            <span>Back to product list</span>
          </router-link>
          <div class="flex items-center space-x-4">
            <img :src="productData.product_icon[0].url" class="h-12 w-12" />
            <div class="text-2xl font-semibold md:text-right">
              {{ productData.product_name }}
            </div>
          </div>
          <h1 class="text-xl font-semibold">
            {{ productData.product_header }}
          </h1>
          <div v-html="productData.product_introduction" />
          <h2 class="text-xl font-semibold">
            {{ productData.product_detail_header }}
          </h2>
          <div class="space-y-4">
            <div v-html="productData.product_details" />
            <button
              @click="readMore"
              class="text-cobalt-500 font-semibold flex items-center space-x-1"
            >
              <span>Read more</span>
              <AppIcon icon="chevron-right" />
            </button>
          </div>
        </div>

        <div>
          <TabGroup>
            <TabList>
              <div class="flex flex-wrap my-4 justify-center">
                <Tab
                  as="template"
                  v-slot="{ selected }"
                  v-for="tab in productData.product_page_tabs"
                  :key="tab.name"
                >
                  <div
                    class="px-6 py-2 my-2 border-t-4 cursor-pointer font-semibold"
                    :class="
                      selected
                        ? 'border-cobalt-500 text-cobalt-500'
                        : 'border-grey-200 text-grey-600'
                    "
                  >
                    {{ tab.name }}
                  </div>
                </Tab>
              </div>
            </TabList>
            <TabPanels>
              <TabPanel
                v-for="tab in productData.product_page_tabs"
                :key="tab.name"
              >
                <div
                  class="flex max-md:flex-col-reverse items-center space-x-4"
                >
                  <div class="md:w-1/2">
                    <div class="flex mb-4">
                      <img
                        :src="tab.icon?.[0].url"
                        alt=""
                        class="h-16 mx-auto"
                      />
                    </div>

                    <div v-html="tab.body" />
                  </div>
                  <div class="md:w-1/2 max-md:my-12">
                    <img
                      :src="tab.image[0].url"
                      style="mask-size: 100% 100%"
                      :style="{ 'mask-image': `url('${maskImage}')` }"
                    />
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>

        <div>
          <h2 class="text-xl font-semibold mb-6">
            {{ productData.how_it_works_header }}
          </h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div
              v-for="item in productData.how_it_works_widget"
              :key="item.number"
              class="bg-grey-100 rounded p-6"
            >
              <div class="flex items-center space-x-4 mb-4">
                <div class="text-5xl font-semibold">
                  {{ item.number }}
                </div>
                <div>
                  <img class="h-12" :src="item.icon[0].url" alt="" />
                </div>
              </div>

              <h3 class="font-semibold mb-2">{{ item.title }}</h3>
              <div class="text-sm" v-html="item.body" />
            </div>
          </div>
        </div>
        <div>
          <h2 class="text-xl font-semibold mb-6">
            {{ productData.faq_header }}
          </h2>
          <div>
            <div v-for="faq in productData.faqs" :key="faq.question">
              <AppDisclosure>
                <template v-slot:title="{ open }">
                  <div
                    class="bg-grey-100 p-6 w-full text-left rounded flex items-center justify-between mt-4 t"
                  >
                    <h1 class="flex items-center">
                      {{ faq.question }}
                    </h1>
                    <AppIcon
                      :icon="`chevron-${open ? 'up' : 'down'}`"
                      class="h-6 w-6"
                    />
                  </div>
                </template>
                <template #body>
                  <div class="p-6" v-html="faq.answer" />
                </template>
              </AppDisclosure>
            </div>
          </div>
        </div>
        <div>
          <h2 class="text-xl font-semibold mb-6">
            {{ productData.testimonials_header }}
          </h2>
          <div
            class="flex flex-col-reverse md:flex-row md:space-x-4 bg-white rounded overflow-hidden"
          >
            <div class="md:w-1/2 space-y-3 max-md:mt-4 p-6">
              <div class="flex space-x-1">
                <div
                  v-for="index in Number(productData.testimonials[0].rating)"
                  :key="index"
                >
                  <AppIcon icon="star" />
                </div>
              </div>
              <div>{{ productData.testimonials[0].body }}</div>
              <h3 class="font-semibold">
                {{ productData.testimonials[0].author }}
              </h3>
            </div>
            <div class="md:w-1/2 fade-left">
              <img
                class="object-cover w-full h-full"
                :src="productData.testimonials[0].background[0].url"
                alt=""
              />
            </div>
          </div>
        </div>
      </main>
      <aside
        class="flex-none max-md:mb-16 md:w-72 md:-mt-32 //z-50 md:sticky top-0 pt-4"
      >
        <div>
          <div class="mb-8">
            <img :src="productData.product_photo[0].url" class="max-w-x px-6" />
          </div>

          <div class="mb-4 font-semibold text-lg">
            {{ productData.product_name }}
          </div>

          <div class="flex space-x-4 items-center mb-6">
            <router-link
              :to="{
                name: 'store-collection-method',
                params: {
                  product: productData._id,
                },
              }"
              class="bg-cobalt-500 hover:bg-cobalt-700 py-2 px-6 rounded flex items-center justify-center text-white font-semibold"
            >
              Order
            </router-link>
            <div class="space-y-//1">
              <div class="text-sm">from</div>
              <div class="text-xl font-semibold text-center">
                {{ getPrice(productData) }}
              </div>
            </div>
          </div>

          <div
            class="list-disc-child pl-4"
            v-html="productData.product_descriptor_list"
          />

          <div
            class="mt-6 bg-cobalt-50 border border-cobalt-500 text-cobalt-700 rounded p-6"
          >
            <div v-html="productData.doctor_text" />
            <div class="flex items-center space-x-4 mt-4">
              <div class="rounded-full overflow-hidden h-12 w-12">
                <img :src="productData.doctor_profile_image[0].url" />
              </div>
              <div class="flex-1">
                <div
                  v-html="productData.doctor_name_and_role"
                  class="text-sm"
                />
              </div>
            </div>
            <div class="mt-6">
              <div class="">
                <div
                  v-for="goal in filteredProductGoals"
                  :key="goal"
                  class="bg-cobalt-700 text-white py-2 px-4 //text-white rounded flex items-center space-x-2 mr-2 mb-2"
                >
                  <!-- <AppIcon :icon="getGoal(goal).icon" /> -->
                  <YourHealthGoalsIcon class="text-white" :health-goal="goal" />
                  <span>{{ goal.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="max-md:hidden flex space-x-4 items-center mt-6">
            <router-link
              :to="{
                name: 'store-collection-method',
                params: {
                  product: productData._id,
                },
              }"
              class="bg-cobalt-500 hover:bg-cobalt-700 py-2 px-6 rounded flex items-center justify-center text-white font-semibold"
            >
              Order
            </router-link>
            <div class="space-y-//1">
              <div class="text-sm">from</div>
              <div class="text-xl font-semibold text-center">
                {{ getPrice(productData) }}
              </div>
            </div>
          </div>
        </div>
      </aside>
    </AppPage>
    <!-- sm:max-w-xl -->
    <AppModal :open="showModal" width="xl" close-icon-color="#777">
      <h2 class="mb-4 text-lg pr-16">
        What's included |
        <span class="font-semibold">{{ productData.product_name }}</span>
      </h2>
      <div class="space-y-1">
        <div v-for="item in productData.whats_included" :key="item.panel">
          <AppDisclosure>
            <template v-slot:title="{ open }">
              <div
                class="bg-grey-100 px-6 py-3 w-full text-left rounded flex items-center justify-between mt-4"
              >
                <h1 class="flex items-center">
                  {{ item.panel }}
                </h1>
                <AppIcon
                  :icon="`chevron-${open ? 'up' : 'down'}`"
                  class="h-6 w-6"
                />
              </div>
            </template>
            <template #body>
              <div class="p-6">
                {{ item.markers }}
              </div>
            </template>
          </AppDisclosure>
        </div>
      </div>
    </AppModal>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { usePatientStore } from "@/stores/patient";
import { getProductData } from "@/services/store";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { getPrice } from "@/services/store";
import YourHealthGoalsIcon from "@/components/YourHealthGoalsIcon.vue";
import maskImage from "@/assets/mask.svg";
import AppModal from "@/components/AppModal.vue";
import AppDisclosure from "@/components/Disclosure/AppDisclosure.vue";

const patientStore = usePatientStore();

const route = useRoute();

const selectedGoals = patientStore.wellbeingGoals;

const filteredProductGoals = computed(() => {
  return patientStore.wellbeingGoals.filter((goal) => {
    return productData.value.wellbeing_goal_tags
      .map((tag) => tag.body)
      .includes(goal.id);
  });
});

console.log(selectedGoals);

const productData = ref({});
const loadingStatus = ref("loading");

getProductData(route.params.product).then((res) => {
  console.log(res);
  productData.value = res;
  loadingStatus.value = "loaded";
});

const showModal = ref(false);

function readMore() {
  showModal.value = true;
}
</script>

<style>
.feather.feather-star {
  fill: rgb(216, 180, 0) !important;
  stroke: rgb(216, 180, 0) !important;
}
</style>
