<template>
  <PageLoader v-if="loadingStatus === 'loading'">Loading</PageLoader>
  <div v-else-if="storeCategories.length">
    <h2 class="text-lg mb-2">Discover precision health tests</h2>
    <div class="md:flex md:space-x-4 max-md:space-y-4">
      <div
        v-for="category in storeCategories"
        :key="category._id"
        class="flex flex-col rounded bg-white shadow overflow-hidden"
        style="max-width: 50%"
      >
        <div class="fade-bottom h-48 overflow-hidden">
          <img
            v-if="category.cover_image"
            :src="category.cover_image[0]?.url"
            alt=""
            class="w-full"
          />
          <!-- <AppImage
        class="object-cover object-center h-48 w-full"
        :public-id="product.product_photo[0].url"
      /> -->
        </div>

        <div class="p-6">
          <div class="content">
            <h3
              class="font-semibold text-lg leading-6 flex items-center justify-between"
            >
              <span>{{ category.name }}</span>
              <img
                v-if="category.icon"
                :src="category.icon[0]?.url"
                alt=""
                class="w-6"
              />
            </h3>
            <hr class="my-3" />
            <div v-html="category.description" />
          </div>
        </div>
        <div class="font-semibold mt-auto mb-0 px-6 pb-6">
          <div class="flex justify-between space-x-6">
            <div class="shrink-0">
              <div class="flex items-center space-x-4 mb-4">
                <img
                  v-if="category.sample_icon"
                  :src="category.sample_icon[0]?.url"
                  alt=""
                  class="w-6"
                />
                <div class="text-sm">{{ category.sample_name }}</div>
              </div>
              <div class="flex items-center mb-6 justify-between">
                <div class="font-semibold">
                  From {{ getMinimumPrice(category) }}
                </div>
                <!-- <img :src="product.product_photo[0].url" alt="" class="w-24" /> -->
              </div>
              <router-link
                :to="{ name: 'store-index' }"
                class="bg-cobalt-500 hover:bg-cobalt-700 px-4 py-2 rounded text-white font-semibold"
              >
                View tests
              </router-link>
            </div>

            <div>
              <img
                v-if="category.product_photo"
                :src="category.product_photo[0]?.url"
                alt=""
                class="w-auto"
                style="max-width: 120px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useLoading } from "@/stores/loading";
import { getStoreCategories } from "@/services/store";
import { enableBlood } from "@/services/store";
import { usePatientStore } from "@/stores/patient";
import { getMinimumPrice } from "@/services/store";

const patientStore = usePatientStore();
const { loadingStatus, finishLoading } = useLoading();
const storeCategories = ref([]);

getStoreCategories().then((res) => {
  if (enableBlood(patientStore.patient)) {
    storeCategories.value = res.data.data.StoreCategories.items;
  } else {
    storeCategories.value = res.data.data.StoreCategories.items.filter(
      (category) => {
        return category.name !== "Blood Testing";
      }
    );
  }
  finishLoading();
});
</script>
