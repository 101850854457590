<template>
  <div class="rounded bg-white shadow">
    <div class="md:flex max-md:space-y-2 justify-between p-6 border-b">
      <slot name="title" />
    </div>
    <div class="p-6">
      <slot name="body" />
    </div>
  </div>
</template>
