export function getColorMatch(resultValue: number) {
  if (resultValue >= 0 && resultValue < 4) return "tangerine";
  if (resultValue >= 4 && resultValue < 6) return "pacific";
  return "emerald";
}

export function getStageMatch(resultValue: number) {
  if (resultValue >= 0 && resultValue < 4) return 0;
  if (resultValue >= 4 && resultValue < 6) return 1;
  return 2;
}

export const resultColorMap = {
  Good: {
    main: "bg-emerald-500",
    border: "border-emerald-500",
    text: "text-emerald-500",
  },
  Typical: {
    main: "bg-pacific-500",
    border: "border-pacific-500",
    text: "text-pacific-500",
  },
  Poor: {
    main: "bg-tangerine-500",
    border: "border-tangerine-500",
    text: "text-tangerine-500",
  },
};
