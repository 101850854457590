<template>
  <div>
    <h2 class="flex items-center text-lg">
      <AppIcon icon="user" class="mr-2" />

      Personal Details
    </h2>

    <div
      class="w-full mt-6 shadow-key rounded-lg flex-1"
      style="border-spacing: 0"
    >
      <DetailsTr title="First name" link="edit/first-name">
        {{ patient.firstName }}
      </DetailsTr>

      <DetailsTr title="Last name" link="edit/last-name">
        {{ patient.lastName }}
      </DetailsTr>

      <DetailsTr title="Biological sex" link="edit/biological-sex">
        {{ patient.bioSex }}
      </DetailsTr>

      <DetailsTr title="Date of birth" link="edit/lifetime">
        {{ profilesDateFormat(patient.dateOfBirth) }}
      </DetailsTr>
    </div>
  </div>
</template>

<script>
import countries from "@/stores/countries";
import DetailsTr from "./DetailsTr.vue";

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  methods: {
    profilesDateFormat(date) {
      return new Date(date).toLocaleDateString("en-GB");
    },
  },
  components: { DetailsTr },
};
</script>
