const { VUE_APP_AUTH_PROVIDER } = process.env;
import axios from "axios";
import endpoints from "@/endpoints";
import { useClerk } from "./clerk";

const { VUE_APP_API } = process.env;

interface HealthRequestOptions {
  path: string;
  method: string;
  body?: object;
}

export async function makeHealthRequest(options: HealthRequestOptions) {
  if (VUE_APP_AUTH_PROVIDER === "clerk") {
    const clerk = useClerk();
    const token = await clerk.value.session.getToken();
    return await axios.request({
      url: `${VUE_APP_API}${options.path}`,
      method: options.method,
      data: options.body,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }
  return await axios.post(endpoints.healthBackend, options);
}
