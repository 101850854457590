import { defineStore } from "pinia";
import { makeHealthRequest } from "@/services/healthBackend";

interface TesterDetails {
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  bioSex: string;
  dateOfBirth: string;
}

interface ActivationState {
  kit: {
    barcode: string;
    sku: string;
    activated?: boolean;
  };
  test: {
    answers: string;
  };
}

export const useActivationStore = defineStore("activation", {
  state: (): ActivationState => ({
    kit: {
      barcode: "",
      sku: "",
    },
    test: {
      answers: "",
    },
  }),
  actions: {
    setKit(kit: { barcode: string; sku: string }) {
      this.kit = kit;
    },
    setTest(test: { answers: string }) {
      this.test = test;
    },
    async checkActivationCode(code: string) {
      const kit = await makeHealthRequest({
        path: `/kits/${code}`,
        method: "GET",
      });
      this.setKit(kit.data as { barcode: string; sku: string });
    },
    async activateKit(testerDetails: TesterDetails) {
      console.log("activating kit for ", testerDetails);

      const res = await makeHealthRequest({
        path: `/patients/${testerDetails.userUuid}/activation`,
        method: "PUT",
        body: { ...testerDetails, barcode: this.kit.barcode },
      });
      this.kit.activated = true;
    },
  },
});
