<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="none"
    stroke-width="1"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <defs>
      <path
        d="M15.4677455,14.6627699 C15.4677455,17.628867 13.0544261,20.0421864 10.088329,20.0421864 C7.12223199,20.0421864 4.70891258,17.628867 4.70891258,14.6627699 C4.70891258,11.6966729 7.12223199,9.28335348 10.088329,9.28335348 C13.0544261,9.28335348 15.4677455,11.6966729 15.4677455,14.6627699 M22.4967031,3.29181548 L22.386807,3.29401341 L22.4956042,3.28082587 C22.4945052,3.26214352 22.4934062,3.2456591 22.4879114,3.20499753 C22.4769218,3.12367438 22.4604374,3.05334084 22.4384582,2.98740315 L22.4384582,2.91706962 L22.3912028,2.86871531 C22.358234,2.80277763 22.3164734,2.7379389 22.2670202,2.67419913 C22.2527337,2.65661575 22.2373482,2.63903236 22.2230617,2.6236469 C22.1560251,2.54781856 22.0878894,2.48627672 21.9977746,2.4269328 C21.92854,2.38077642 21.8505138,2.34451069 21.7625968,2.31483873 C21.7384197,2.30604704 21.7153415,2.29835431 21.6922633,2.29176054 C21.585664,2.26318754 21.4944502,2.25 21.4043354,2.25 L15.73809,2.25 C15.1336612,2.25 14.6424254,2.74233474 14.6424254,3.34676356 C14.6424254,3.95119237 15.1336612,4.44352712 15.73809,4.44352712 L18.7569372,4.44352712 L14.6182483,8.58331502 C13.2939997,7.59534865 11.7312764,7.0744409 10.088329,7.0744409 C5.90458267,7.0744409 2.5,10.4779246 2.5,14.6627699 C2.5,18.8476153 5.90458267,22.251099 10.088329,22.251099 C14.2731744,22.251099 17.6766581,18.8476153 17.6766581,14.6627699 C17.6766581,13.0198225 17.1557503,11.4560003 16.1677839,10.1339497 L20.3075718,5.99306281 L20.3075718,9.01300896 C20.3075718,9.61743777 20.7999066,10.1097725 21.4043354,10.1097725 C22.0087642,10.1097725 22.5,9.61743777 22.5,9.01300896 L22.5,3.34676356 C22.5,3.32588329 22.4978021,3.30720095 22.4967031,3.29181548"
        id="path-1"
      ></path>
    </defs>
    <g fill-rule="evenodd">
      <mask id="mask-2">
        <use xlink:href="#path-1"></use>
      </mask>
      <use id="Path" xlink:href="#path-1"></use>
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-male",
};
</script>
