const { VUE_APP_AUTH_PROVIDER } = process.env;

import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/endpoints";

interface ProfileState {
  profiles: Array<MergedProfile>;
}

interface Test {
  barcode: string;
}

interface ProfileResponse {
  profile: {
    barcode: string;
  };
  permission: object;
}

interface MergedProfile {
  barcode: string;
  permission: object;
}

async function fetchTests(): Promise<Array<Test>> {
  const res = await axios.get(endpoints.tests);
  return res.data.caseRecords.ancestry;
}

async function fetchProfiles(): Promise<Array<ProfileResponse>> {
  const res = await axios.get(endpoints.profiles);
  return Object.values(res.data);
}

export const useProfileStore = defineStore("profile", {
  state: (): ProfileState => ({
    profiles: [],
  }),
  actions: {
    async fetchProfiles() {
      if (VUE_APP_AUTH_PROVIDER === "clerk") {
        return Promise.resolve();
      }
      const [tests, profiles] = await Promise.all([
        fetchTests(),
        fetchProfiles(),
      ]);

      this.profiles = tests.map((test) => {
        const correspondingProfile = profiles.find(
          (profile) => profile.profile.barcode === test.barcode
        );
        if (!correspondingProfile) {
          throw Error("Corresponding profile could not be found");
        }
        return {
          ...correspondingProfile.profile,
          ...test,
          permission: correspondingProfile.permission,
        };
      });
    },

    async getFullProfile(barcode: string) {
      const { data } = await axios.get(endpoints.getProfile(barcode));
      return data;
    },

    async setDefaultProfile(permissionId: string) {
      await axios.put(`${endpoints.profile}/default/${permissionId}`);
    },
  },
});

export async function checkPassword(username: string, password: string) {
  const response = await fetch(endpoints.checkPassword, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ _username: username, _password: password }),
  });
  if (!response.ok) {
    throw new Error();
  }
}
