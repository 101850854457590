<template>
  <transition name="slide-left" mode="out-in">
    <div>
      <router-view :patient="patient" :first-name="firstName" />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    patient: {
      required: true,
      type: Object,
    },
  },
  computed: {
    firstName() {
      return this.patient.firstName;
    },
  },
};
</script>
