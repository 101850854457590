<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="none"
    stroke-width="1"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <g fill-rule="evenodd">
      <path
        d="M20.6615222,9.96543396 L17.902933,9.03196835 C17.7432504,8.97859973 17.5673127,9.00382853 17.4296222,9.10474373 C17.292888,9.20565893 17.2116124,9.36673512 17.2116124,9.53848503 L17.2116124,20.5392123 L11.9994567,20.5392123 L6.78921327,20.5392123 L6.78921327,9.53848503 C6.78921327,9.36673512 6.70793768,9.20565893 6.57120345,9.10474373 C6.43255686,9.00382853 6.2575753,8.97859973 6.09693648,9.03196835 L3.33834735,9.96543396 L2.34582897,7.19511762 L8.26747282,3.75332706 L8.42237453,4.14049211 C9.01520825,5.58726677 10.4332282,6.56633829 11.9994567,6.56633829 C13.5666413,6.56633829 14.9846613,5.58726677 15.5784512,4.14049211 L15.7333529,3.75332706 L21.6540405,7.19511762 L20.6615222,9.96543396 Z M23.7346956,6.05496991 L17.0414118,2.17361603 C16.3118438,1.8223535 15.6587706,1.49049774 14.8402776,1.59723497 C13.8745323,1.72337897 13.8640143,2.63646748 13.8640143,2.63646748 C13.8114242,3.77273383 13.02831,4.5286275 11.9994567,4.5286275 C10.9725157,4.5286275 10.1884453,3.77273383 10.1358552,2.63646748 C10.1358552,2.63646748 10.1253372,1.72337897 9.16054812,1.59723497 C8.34205513,1.49049774 7.68802567,1.8223535 6.95845773,2.17361603 L0.265173873,6.05496991 C0.0433393226,6.18402493 -0.0561037519,6.45669004 0.0318651217,6.700245 L1.94423194,12.0371066 C2.04176265,12.3088014 2.33531095,12.4524115 2.60495467,12.3611996 L4.7821843,11.6247127 L4.7821843,22.0432369 C4.7821843,22.3372495 5.01740541,22.5769231 5.30712899,22.5769231 L11.9994567,22.5769231 L18.6927405,22.5769231 C18.9834203,22.5769231 19.2186414,22.3372495 19.2186414,22.0432369 L19.2186414,11.6247127 L21.3949148,12.3611996 C21.6645586,12.4524115 21.9581069,12.3088014 22.0556376,12.0371066 L23.9680044,6.700245 C24.0559733,6.45669004 23.9574864,6.18402493 23.7346956,6.05496991 L23.7346956,6.05496991 Z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-tshirt",
};
</script>
