import { Cloudinary } from "@cloudinary/url-gen";
import { scale } from "@cloudinary/url-gen/actions/resize";

export function pngUrlToJpg(url: string) {
  if (!url) {
    console.log("Not a string, or empty, ignoring");
    return url;
  }
  const indexOfFileType = url.indexOf(".png");
  if (indexOfFileType === -1) {
    console.log("Url is not .png, ignoring");
    return url;
  }
  return url.replace(".png", ".jpg");
}

export function useImage(publicId: string, width: number | null = null) {
  const cld = new Cloudinary({
    cloud: {
      cloudName: "living-dna",
    },
  });

  const myImg = cld.image(publicId);

  if (width) {
    myImg.resize(scale().width(width));
  }

  return myImg;
}
