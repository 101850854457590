<template>
  <div class="relative">
    <button
      v-if="centerIndexOfCarousel > 2"
      @click="previous()"
      class="absolute p-3 rounded-full bg-white shadow text-cobalt-500 z-50 left-0"
      style="top: 50%"
    >
      <AppIcon icon="chevron-left" />
    </button>
    <button
      v-if="centerIndexOfCarousel <= categories.length - 3"
      @click="next()"
      class="absolute p-3 rounded-full bg-white shadow text-cobalt-500 z-50 right-0"
      style="top: 50%"
    >
      <AppIcon icon="chevron-right" />
    </button>
    <div
      class="lg:hidden"
      :class="slidesToDisplay(3).length >= 3 ? 'fade-right-sm' : 0"
    >
      <div
        class="grid grid-cols-3 gap-4 overflow-hidden"
        :class="{ 'fade-left-sm': centerIndexOfCarousel > 2 }"
      >
        <CategoryCarouselItem
          v-for="category in slidesToDisplay(3)"
          :key="category._id"
          :category="category"
        />
      </div>
    </div>
    <div
      class="hidden lg:block"
      :class="slidesToDisplay(5).length >= 5 ? 'fade-right-sm' : 0"
    >
      <div
        class="grid grid-cols-5 gap-4 overflow-hidden"
        :class="{ 'fade-left-sm': centerIndexOfCarousel > 2 }"
      >
        <CategoryCarouselItem
          v-for="category in slidesToDisplay(5)"
          :key="category._id"
          :category="category"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import CategoryCarouselItem from "./CategoryCarouselItem.vue";

const route = useRoute();

const props = defineProps({
  categories: Array,
});

watch(
  () => route.params,
  () => {
    console.log("triggered");
    centerIndexOfCarousel.value = props.categories
      .map((c) => c._slug)
      .indexOf(route.params.category);
  }
);

const centerIndexOfCarousel = ref(
  props.categories.map((c) => c._slug).indexOf(route.params.category)
);

console.log(centerIndexOfCarousel.value);

function next() {
  centerIndexOfCarousel.value = centerIndexOfCarousel.value + 1;
}

function previous() {
  centerIndexOfCarousel.value = centerIndexOfCarousel.value - 1;
}

const slidesToDisplay = computed(() => (perPage) => {
  if (centerIndexOfCarousel.value <= 1) {
    return props.categories.slice(0, perPage);
  }

  if (perPage === 3) {
    return props.categories.slice(
      centerIndexOfCarousel.value - perPage - 2,
      centerIndexOfCarousel.value + 4
    );
  }

  if (perPage === 5) {
    return props.categories.slice(
      centerIndexOfCarousel.value - 2,
      centerIndexOfCarousel.value + 3
    );
  }
});
</script>
