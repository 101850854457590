<template>
  <div>
    <div class="block font-semibold">
      <slot name="label" />
    </div>
    <div>
      <slot name="fields" :model="model" />
      <div class="text-sm text-rose-500 mt-1">{{ errorMessage }}</div>
      <button
        type="button"
        @click.prevent="addItem"
        class="ml-auto inline-flex items-center rounded-md border space-x-2 border-gray-300 bg-white px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cobalt-500 focus:ring-offset-2 h-full mt-4"
        style="height: 42px"
      >
        <AppIcon icon="plus" />
        <span>Add</span>
      </button>
    </div>
    <div v-if="false">
      To be added:
      <br />
      {{ model }}
    </div>
    <div class="mt-8">
      <div class="font-semibold">{{ addedTitle }}</div>
      <div v-if="items.length" class="border-b border-gray-200">
        <ul role="list" class="divide-y divide-gray-200">
          <li
            v-for="item in items"
            :key="item"
            class="flex justify-between py-4"
          >
            <span class="font-medium text-gray-900">
              <div v-for="(value, key) in item" :key="key">
                <span class="capitalize">{{ key }}</span>
                :
                <span v-if="typeof value === 'object'">
                  <span
                    v-for="(value2, key2) in value"
                    :key="key2"
                    class="capitalize inline-block mr-2"
                  >
                    {{ key2 }}:
                    {{
                      key2 === "month"
                        ? findMonth(value2)?.title || "Unknown"
                        : value2
                    }}
                  </span>
                </span>
                <span v-else>{{ value }}</span>
              </div>
            </span>
            <button
              @click.prevent="removeItem(item)"
              class="text-sm text-grey-700 font-semibold text-right"
            >
              Remove
            </button>
          </li>
        </ul>
      </div>
      <div class="mt-2" v-else>No items added yet</div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { months } from "@/services/static";

const props = defineProps({
  addedTitle: String,
  requiredFields: Array,
  modelValue: Array,
});

const emit = defineEmits(["update:modelValue"]);

const model = ref({});
const items = ref(props.modelValue);
const errorMessage = ref("");

function checker(items) {
  if (!props.requiredFields?.length) {
    return true;
  }
  if (!Object.keys(items).length) {
    return false;
  }
  return props.requiredFields.every((key) => Object.keys(items).includes(key));
}

function getError(fields) {
  return `You need to provide the following fields: ${fields.join(", ")}`;
}

function addItem() {
  errorMessage.value = "";
  if (checker(model.value)) {
    items.value.push({ ...model.value });
    emit("update:modelValue", items.value);
    model.value = {};
  } else {
    errorMessage.value = getError(props.requiredFields);
  }
}

function removeItem(itemForRemoval) {
  items.value = items.value.filter((item) => item !== itemForRemoval);
}

function findMonth(value) {
  return months.find((month) => month.value === value);
}

watch(
  items,
  () => {
    emit("update:modelValue", items.value);
  },
  {
    deep: true,
  }
);
</script>
