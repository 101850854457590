<template>
  <div>
    <div class="sm:flex sm:space-x-4 items-end block font-semibold text-sm">
      <label :for="id" class="">
        <span>{{ label }}</span>
      </label>
      <button
        @click.prevent="toggleUnit"
        class="text-sm font-semibold text-cobalt-500 text-left"
      >
        Change to {{ currentUnit === "kg" ? "lbs" : "kg" }}
      </button>
    </div>

    <div class="mt-2">
      <div>
        <div
          v-if="currentUnit === 'kg'"
          class="inline-flex rounded-md shadow-sm"
        >
          <input
            v-model="kg"
            type="number"
            name="weight-kg"
            id="weight-kg"
            class="block w-24 min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
            min="0"
          />
          <label
            for="weight-kg"
            class="inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm w-12"
          >
            kg
          </label>
        </div>
        <div
          v-if="currentUnit === 'lbs'"
          class="inline-flex flex-col sm:flex-row sm:space-x-4"
        >
          <div class="inline-flex rounded-md shadow-sm mb-2 sm:mb-0">
            <input
              v-model="st"
              pattern="[0-9]*"
              type="number"
              name="weight-st"
              id="weight-st"
              class="block w-24 min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
              min="0"
            />
            <label
              for="weight-st"
              class="inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm w-12"
            >
              st
            </label>
          </div>
          <div class="inline-flex rounded-md shadow-sm">
            <input
              v-model="lbs"
              pattern="[0-9]*"
              type="number"
              name="weight-lbs"
              id="weight-lbs"
              class="block w-24 min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
              min="0"
            />
            <label
              for="weight-lbs"
              class="inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm w-12"
            >
              lbs
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, toRef } from "vue";

const props = defineProps({
  label: String,
  id: String,
  name: String,
  inputWidth: String,
  modelValue: undefined,
});

const emit = defineEmits(["update:modelValue"]);

const currentUnit = ref(props.modelValue.unit);
const kg = ref(0);
const lbs = ref(0);
const st = ref(0);

if (props.modelValue.unit === "kg") {
  kg.value = toRef(props, "modelValue").value.value;
} else {
  lbs.value = toRef(props, "modelValue").value.value % 14;
  st.value = Math.floor(toRef(props, "modelValue").value.value / 14);
}

function toggleUnit() {
  if (currentUnit.value === "kg") {
    return (currentUnit.value = "lbs");
  }
  return (currentUnit.value = "kg");
}

watch([kg, lbs, st, currentUnit], () => {
  if (currentUnit.value === "kg") {
    emit("update:modelValue", { value: kg.value, unit: "kg" });
  } else if (currentUnit.value === "lbs") {
    emit("update:modelValue", {
      value: Number(st.value) * 14 + Number(lbs.value),
      unit: "lbs",
    });
  }
});
</script>
