<template>
  <div v-if="loadingStatus === 'loading'">
    <PageLoader>Loading</PageLoader>
  </div>
  <div v-else-if="loadingStatus === 'error'">
    <div class="max-w-xl m-auto bg-rose-50 text-rose-500 my-12 p-6 rounded">
      Something went wrong loading the page
    </div>
  </div>
  <div v-else>
    <AppCmsHeaderImage :src="traitData.header_image[0].url" />
    <AppPage stack>
      <AppBreadCrumbs
        class="mt-8"
        :items="[
          {
            label: traitData.trait_subcategory[0].category[0].category_name,
            to: {
              name: 'genetics-category',
              params: {
                category: traitData.trait_subcategory[0].category[0]._slug,
              },
            },
          },
          {
            label: traitData.trait_subcategory[0].name,
            to: {
              name: 'genetics-category',
              params: {
                category: traitData.trait_subcategory[0].category[0]._slug,
              },
              query: {
                subcategory: traitData.trait_subcategory[0]._slug,
              },
            },
          },
          {
            label: traitData.trait_name,
          },
        ]"
      />
      <div class="md:flex w-full md:space-x-6 max-md:space-y-6">
        <div class="rounded bg-white p-6 space-y-6 flex-1">
          <div>Hi, {{ patientStore.patient.firstName }}</div>
          <div>{{ result.interpretation_text }}</div>
          <DoctorDetails />
        </div>
        <div class="md:w-80 flex flex-col">
          <div
            class="rounded bg-white p-6 space-y-4 border-t-4"
            :class="resultColor.border"
          >
            <div class="flex items-center gap-4">
              <img class="h-10" :src="traitData.trait_icon[0].url" alt="" />
              <h3 class="font-semibold">{{ traitData.trait_name }}</h3>
            </div>
            <p>{{ result.short_text }}</p>
            <div class="flex space-x-4">
              <div
                v-for="(i, index) in 3"
                :key="i"
                class="h-8 w-8 border-2"
                :class="resultStage == index ? resultColor.main : ''"
              />
            </div>

            <div class="font-semibold" :class="resultColor.text">
              {{ result.interpretation }}
            </div>

            <div
              v-if="traitData.trait_subcategory[0].category[0].numeric_results"
            >
              Risk score:
              <span class="inline-flex items-center">
                <strong :class="resultColor.text">
                  {{ result.result_value }}
                </strong>
                <span>/10</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="traitData.what_is_body">
        <h1 class="text-xl font-semibold">{{ traitData.what_is_header }}</h1>
        <div v-html="traitData.what_is_body" class="mt-6" />
      </div>
      <div v-if="traitData.symptoms_body">
        <h1 class="text-xl font-semibold">{{ traitData.symptoms_header }}</h1>
        <div v-html="traitData.symptoms_body" class="mt-6" />
      </div>
      <div v-if="traitData.tips_tricks">
        <h1 class="text-xl font-semibold">Tips and tricks</h1>
        <div class="mt-6">
          <div
            class="border-2 border-emerald-500 p-6 rounded bg-emerald-50 text-emerald-700"
          >
            <div
              v-html="traitData.tips_tricks"
              class="list-decimal-child pl-4"
            />
          </div>
        </div>
      </div>
      <div>
        <h1 class="text-xl font-semibold">
          {{ traitData.the_science_header }}
        </h1>
        <div class="mt-6">
          <div
            class="rounded bg-white p-6 flex max-lg:flex-col items-start lg:items-center gap-6"
          >
            <div class="space-y-6">
              <div
                class="flex max-lg:flex-col gap-4 items-start lg:items-center"
              >
                <div class="w-48 border-2 rounded border-grey-200 px-4 py-2">
                  Genes {{ result.genomes.split(",").length }}
                </div>
                <div class="flex-1">
                  Your genetic report to determine your risk for phobic
                  disorders is based on
                  {{ result.genomes.split(",").length }} genetic
                  {{ pluralize("marker", result.genomes.split(",").length) }}
                </div>
              </div>
              <div
                class="flex max-lg:flex-col gap-4 items-start lg:items-center"
              >
                <div class="w-48 border-2 rounded border-grey-200 px-4 py-2">
                  References {{ traitData.references.length }}
                </div>
                <div class="flex-1">
                  {{ traitData.references.length }} 4 and 5 star reference
                  {{ pluralize("paper", traitData.references.length) }}
                  {{ pluralize("have", traitData.references.length) }}
                  been established
                </div>
              </div>
            </div>
            <router-link
              :to="{
                name: 'genetics-science',
                params: { trait: traitData._slug },
              }"
              class="px-4 py-2 bg-cobalt-500 rounded hover:bg-cobalt-700 text-white"
            >
              View
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <h1 class="text-xl font-semibold">
          {{ traitData.limitations_header }}
        </h1>
        <div v-html="traitData.limitations_body" class="mt-6" />
      </div>
    </AppPage>

    <!-- <div class="max-w-5xl mx-auto mt-24 relative z-40 px-4 space-y-16">
      <div class="rounded bg-white p-6">{{ traitData }}</div>
    </div> -->
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import pluralize from "pluralize";
import { getGeneticTrait } from "@/services/traits.ts";
import { usePatientStore } from "@/stores/patient";
import { getStageMatch, resultColorMap } from "@/services/geneticColorMaps";
import DoctorDetails from "@/components/DoctorDetails.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppCmsHeaderImage from "@/components/AppCmsHeaderImage.vue";

const loadingStatus = ref("loading");
const traitData = ref({});
const patientStore = usePatientStore();
const route = useRoute();

const result = patientStore.patient.geneticTest.result.results.find(
  (result) => result.trait_id === route.params.trait
);

const resultColor = resultColorMap[result.interpretation];

const resultStage = getStageMatch(result.result_value);

getGeneticTrait(route.params.trait)
  .then((res) => {
    console.log({ res });
    const trait = res?.data?.data?.GeneticTrait;
    if (!trait) {
      throw new Error("Query failed");
    }
    traitData.value = trait;
    loadingStatus.value = "loaded";
  })
  .catch((err) => {
    console.error(err);
    loadingStatus.value = "error";
  });
</script>
