<template>
  <div>
    <h2 class="text-lg mb-2">More about us</h2>
    <div class="grid md:grid-cols-2 gap-4">
      <DashboardDashlet
        v-for="(value, key) in dashboardInfo"
        :key="key"
        :name="key"
        :icon="{ color: value.color }"
        @selected="selectModal($event)"
      >
        <template #title>{{ value.title }}</template>
        <template #description>
          {{ value.description }}
        </template>
        <template #icon>
          <div v-html="value.icon" />
        </template>
      </DashboardDashlet>
    </div>
    <StepModal
      v-if="modalContent.steps"
      :open="showModal"
      @closed="showModal = false"
      :steps="modalContent.steps"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { dashboardInfo } from "@/services/static";
import DashboardDashlet from "@/components/Dashboard/DashboardDashlet.vue";
import StepModal from "@/components/StepModal.vue";

const showModal = ref(false);
const modalContent = ref({});

function selectModal(target) {
  console.log("called");
  modalContent.value = dashboardInfo[target];
  showModal.value = true;
}
</script>
