<template>
  <DashboardHeader />
  <PostActivationDashboard />
  <!-- <PostActivationDashboard v-if="patientStore.patient.tests.length" />
  <PreActivationDashboard v-else /> -->
</template>

<script setup>
import { usePatientStore } from "@/stores/patient";
import PostActivationDashboard from "@/components/PostActivationDashboard.vue";
import PreActivationDashboard from "@/components/PreActivationDashboard.vue";
import DashboardHeader from "@/components/Dashboard/DashboardHeader.vue";

const patientStore = usePatientStore();
</script>
