const { VUE_APP_CLERK_PUBLISHABLE_KEY } = process.env;
import Clerk from "@clerk/clerk-js";
import { ref, computed } from "vue";
console.log(process.env);
const publishableKey = VUE_APP_CLERK_PUBLISHABLE_KEY;

console.log(Clerk);

const ClerkInitialisation = new Clerk(publishableKey);

const clerk: any = ref({ isLoaded: false });

let promiseResolve: any, promiseReject: any;

export const clerkLoaded = new Promise(function (resolve, reject) {
  promiseResolve = resolve;
  promiseReject = reject;
});

ClerkInitialisation.load({}).then((data) => {
  clerk.value = {
    isLoaded: true,
    signIn: ClerkInitialisation.client?.signIn,
    signUp: ClerkInitialisation.client?.signUp,
    setActive: ClerkInitialisation.setActive,
    signOut: ClerkInitialisation.signOut,
    user: ClerkInitialisation.user,
    session: ClerkInitialisation.session,
  };
  // @ts-ignore
  promiseResolve();
});

ClerkInitialisation.addListener((state) => {
  console.log("clerk state change", state);
  clerk.value = {
    isLoaded: true,
    signIn: state.client?.signIn,
    signUp: state.client?.signUp,
    setActive: ClerkInitialisation.setActive,
    signOut: ClerkInitialisation.signOut,
    user: state.user,
    session: state.session,
  };
});

export function useClerk() {
  return computed(() => {
    return {
      isLoaded: clerk.value.isLoaded,
      signIn: clerk.value.signIn,
      signUp: clerk.value.signUp,
      signOut: clerk.value.signOut,
      setActive: clerk.value.setActive,
      user: clerk.value.user,
      session: clerk.value.session,
    };
  });
}
