<template>
  <AppPage>
    <div v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-else>
      <div class="max-w-5xl m-auto pb-12 px-4">
        <div>
          <div class="flex items-center text-denim-700 //mt-16 mb-4">
            <MarkerIcon :marker="{ code: route.params.marker }" />
            <h1 class="ml-4 text-2xl font-normal">
              {{ markerDetails.Biomarker }}
            </h1>
          </div>
          <div v-html="markerDetails.all_about_body"></div>
          <div class="mt-8">
            <h2 class="text-lg font-semibold mb-4">
              {{ markerDetails.potential_symptoms_title }}
            </h2>
            <AppImage
              :public-id="markerDetails.potential_symptoms_body_image"
            />
            <div
              class="mt-4"
              v-html="markerDetails.potential_symptoms_body"
            ></div>
          </div>
          <div class="mt-8">
            <div>
              <h2 class="text-lg font-semibold">
                {{ markerDetails.action_header }}
              </h2>
              <div class="mt-4">
                <div
                  class="grid gap-4"
                  :class="
                    markerDetails.advice_title_3
                      ? 'md:grid-cols-3'
                      : 'md:grid-cols-2'
                  "
                >
                  <div class="block rounded bg-white shadow overflow-hidden">
                    <div class="fade-bottom h-48 overflow-hidden">
                      <AppImage :public-id="markerDetails.advice_image_1" />
                    </div>

                    <div class="p-6">
                      <div class="flex items-center space-x-4">
                        <div class="text-lg font-semibold">
                          {{ markerDetails.advice_title_1 }}
                        </div>
                      </div>

                      <p class="mt-4" v-html="markerDetails.advice_body_1"></p>
                    </div>
                  </div>

                  <div class="block rounded bg-white shadow overflow-hidden">
                    <div class="fade-bottom h-48 overflow-hidden">
                      <AppImage :public-id="markerDetails.advice_image_2" />
                    </div>

                    <div class="p-6">
                      <div class="flex items-center space-x-4">
                        <div class="text-lg font-semibold">
                          {{ markerDetails.advice_title_2 }}
                        </div>
                      </div>

                      <p class="mt-4" v-html="markerDetails.advice_body_2"></p>
                    </div>
                  </div>

                  <div
                    class="block rounded bg-white shadow overflow-hidden"
                    v-if="markerDetails.advice_title_3"
                  >
                    <div class="fade-bottom h-48 overflow-hidden">
                      <AppImage :public-id="markerDetails.advice_image_3" />
                    </div>

                    <div class="p-6">
                      <div class="flex items-center space-x-4">
                        <div class="text-lg font-semibold">
                          {{ markerDetails.advice_title_3 }}
                        </div>
                      </div>

                      <p class="mt-4" v-html="markerDetails.advice_body_3"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8">
            <h2 class="text-lg font-semibold">
              {{ markerDetails.factors_affecting_title }}
            </h2>
            <div
              class="mt-4"
              v-html="markerDetails.factors_affecting_body"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else class="space-y-8">
      <div v-for="(name, detail) in markerDetails" :key="detail.Biomarker">
        {{ detail }} -
        <div v-html="name"></div>
      </div>
    </div> -->
  </AppPage>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref } from "vue";
import { getMarkerDetails } from "@/stores/marker";
import MarkerIcon from "./MarkerIcon.vue";

const route = useRoute();

const markerDetails = getMarkerDetails(route.params.marker);
const errorMessage = ref("");

if (!markerDetails) {
  errorMessage.value = "No marker found";
}
</script>
