import testDetails from "@/static/testDetails.json";
const { VUE_APP_AUTH_PROVIDER } = process.env;

interface TestDetails {
  Image: string;
  "Craft SKU": string;
  SKU: string;
  "WP SKU": string;
  Name: string;
}

interface TermsLinks {
  terms: string;
  privacy: string;
}

let termsLinks: TermsLinks;

if (VUE_APP_AUTH_PROVIDER === "clerk") {
  termsLinks = {
    terms:
      "https://www.dnaworkplace.com/_files/ugd/4410ce_0ff4aa12bf684c6fb0457d692548cd66.pdf",
    privacy:
      "https://www.dnaworkplace.com/_files/ugd/4410ce_0ff4aa12bf684c6fb0457d692548cd66.pdf",
  };
} else {
  termsLinks = {
    terms: "https://livingdna.com/uk/legal/PM-Terms-Of-Service",
    privacy: "https://livingdna.com/uk/privacy-centre",
  };
}

export { termsLinks };

export const months = [
  {
    value: "01",
    title: "January",
    type: "longPlus",
  },
  {
    value: "02",
    title: "February",
    type: "regular",
  },
  {
    value: "03",
    title: "March",
    type: "longPlus",
  },
  {
    value: "04",
    title: "April",
    type: "long",
  },
  {
    value: "05",
    title: "May",
    type: "longPlus",
  },
  {
    value: "06",
    title: "June",
    type: "long",
  },
  {
    value: "07",
    title: "July",
    type: "longPlus",
  },
  {
    value: "08",
    title: "August",
    type: "longPlus",
  },
  {
    value: "09",
    title: "September",
    type: "long",
  },
  {
    value: "10",
    title: "October",
    type: "longPlus",
  },
  {
    value: "11",
    title: "November",
    type: "long",
  },
  {
    value: "12",
    title: "December",
    type: "longPlus",
  },
];

export const dayList = [
  {
    value: "01",
    title: "1st",
    type: "regular",
  },
  {
    value: "02",
    title: "2nd",
    type: "regular",
  },

  {
    value: "03",
    title: "3rd",
    type: "regular",
  },

  {
    value: "04",
    title: "4th",
    type: "regular",
  },
  {
    value: "05",
    title: "5th",
    type: "regular",
  },
  {
    value: "06",
    title: "6th",
    type: "regular",
  },
  {
    value: "07",
    title: "7th",
    type: "regular",
  },
  {
    value: "08",
    title: "8th",
    type: "regular",
  },
  {
    value: "09",
    title: "9th",
    type: "regular",
  },
  {
    value: "010",
    title: "10th",
    type: "regular",
  },
  {
    value: "11",
    title: "11th",
    type: "regular",
  },
  {
    value: "12",
    title: "12th",
    type: "regular",
  },
  {
    value: "13",
    title: "13th",
    type: "regular",
  },
  {
    value: "14",
    title: "14th",
    type: "regular",
  },
  {
    value: "15",
    title: "15th",
    type: "regular",
  },
  {
    value: "16",
    title: "16th",
    type: "regular",
  },
  {
    value: "17",
    title: "17th",
    type: "regular",
  },
  {
    value: "18",
    title: "18th",
    type: "regular",
  },
  {
    value: "19",
    title: "19th",
    type: "regular",
  },
  {
    value: "20",
    title: "20th",
    type: "regular",
  },
  {
    value: "21",
    title: "21st",
    type: "regular",
  },
  {
    value: "22",
    title: "22nd",
    type: "regular",
  },
  {
    value: "23",
    title: "23rd",
    type: "regular",
  },
  {
    value: "24",
    title: "24th",
    type: "regular",
  },
  {
    value: "25",
    title: "25th",
    type: "regular",
  },
  {
    value: "26",
    title: "26th",
    type: "regular",
  },
  {
    value: "27",
    title: "27th",
    type: "regular",
  },
  {
    value: "28",
    title: "28th",
    type: "regular",
  },
  {
    value: "29",
    title: "29th",
    type: "regular",
  },
  {
    value: "30",
    title: "30th",
    type: "long",
  },
  {
    value: "31",
    title: "31st",
    type: "longPlus",
  },
];

export const kitNames = {
  "LDNA-000-AT": "Ancestry kit",
  "LDNA-000-MT": "Ancestry kit",
  "LDNA-001-AU": "Ancestry kit",
  "LDNA-001-CA": "Ancestry kit",
  "LDNA-001-CGL": "Ancestry kit",
  "LDNA-001-DCD": "Ancestry kit",
  "LDNA-001-EU": "Ancestry kit",
  "LDNA-001-NZ": "Ancestry kit",
  "LDNA-001-ROW": "Ancestry kit",
  "LDNA-001-UK": "Ancestry kit",
  "LDNA-001-US": "Ancestry kit",
  "LDNA-001-USA": "Ancestry kit",
  "LDNA-002-AU": "Ancestry kit",
  "LDNA-002-CA": "Ancestry kit",
  "LDNA-002-EU": "Ancestry kit",
  "LDNA-002-NZ": "Ancestry kit",
  "LDNA-002-ROW": "Ancestry kit",
  "LDNA-002-UK": "Ancestry kit",
  "LDNA-002-US": "Ancestry kit",
  "LDNA-002-USA": "Ancestry kit",
  "LDNA-FAM-001": "Ancestry kit",
  "LDNA-FMP-FULLANCESTRY-AU": "FMP ancestry kit",
  "LDNA-FMP-FULLANCESTRY-CA": "FMP ancestry kit",
  "LDNA-FMP-FULLANCESTRY-EU": "FMP ancestry kit",
  "LDNA-FMP-FULLANCESTRY-NZ": "FMP ancestry kit",
  "LDNA-FMP-FULLANCESTRY-ROW": "FMP ancestry kit",
  "LDNA-FMP-FULLANCESTRY-UK": "FMP ancestry kit",
  "LDNA-FMP-FULLANCESTRY-US": "FMP ancestry kit",
  "LDNA-GOUS-UK": "Gousto ancestry kit",
  "LDNA-SEQ-AU": "Sequencing ancestry kit",
  "LDNA-SEQ-CA": "Sequencing ancestry kit",
  "LDNA-SEQ-EU": "Sequencing ancestry kit",
  "LDNA-SEQ-NZ": "Sequencing ancestry kit",
  "LDNA-SEQ-ROW": "Sequencing ancestry kit",
  "LDNA-SEQ-UK": "Sequencing ancestry kit",
  "LDNA-SEQ-USA": "Sequencing ancestry kit",
  "LDNA-STACK-US": "Ancestry kit",
  "LDNA-STACK-WELLBEING-ANCESTRY-US": "Wellbeing & ancestry kit",
  "LDNA-STARTER-AU": "Starter kit",
  "LDNA-STARTER-CA": "Starter kit",
  "LDNA-STARTER-DIGITAL-AU": "Digital upload",
  "LDNA-STARTER-DIGITAL-CA": "Digital upload",
  "LDNA-STARTER-DIGITAL-EU": "Digital upload",
  "LDNA-STARTER-DIGITAL-NZ": "Digital upload",
  "LDNA-STARTER-DIGITAL-ROW": "Digital upload",
  "LDNA-STARTER-DIGITAL-UK": "Digital upload",
  "LDNA-STARTER-DIGITAL-US": "Digital upload",
  "LDNA-STARTER-EU": "Starter kit",
  "LDNA-STARTER-NZ": "Starter kit",
  "LDNA-STARTER-ROW": "Starter kit",
  "LDNA-STARTER-UK": "Starter kit",
  "LDNA-STARTER-USA": "Starter kit",
  "LDNA-WELLBEING-ANCESTRY-AU": "Wellbeing & ancestry kit",
  "LDNA-WELLBEING-ANCESTRY-CA": "Wellbeing & ancestry kit",
  "LDNA-WELLBEING-ANCESTRY-EU": "Wellbeing & ancestry kit",
  "LDNA-WELLBEING-ANCESTRY-NZ": "Wellbeing & ancestry kit",
  "LDNA-WELLBEING-ANCESTRY-ROW": "Wellbeing & ancestry kit",
  "LDNA-WELLBEING-ANCESTRY-UK": "Wellbeing & ancestry kit",
  "LDNA-WELLBEING-ANCESTRY-USA": "Wellbeing & ancestry kit",
  "LDNA-WELLBEING-AU": "Wellbeing kit",
  "LDNA-WELLBEING-CA": "Wellbeing kit",
  "LDNA-WELLBEING-EU": "Wellbeing kit",
  "LDNA-WELLBEING-NZ": "Wellbeing kit",
  "LDNA-WELLBEING-ROW": "Wellbeing kit",
  "LDNA-WELLBEING-UK": "Wellbeing kit",
  "LDNA-WELLBEING-USA": "Wellbeing kit",
};

export const dashboardInfo = {
  lab: {
    title: "Our labs",
    description:
      "All tests are performed in state-of-the-art, fully accredited, precision health testing laboratories. Find out more…",
    color: "rose",
    icon: `<svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="h-12 w-12 fill-current text-rose-500"
    >
      <path
        d="M20.3,21.75H3.91c-.17,0-.3-.14-.3-.3V11.16c0-.17,.14-.3,.3-.3H20.3c.17,0,.3,.14,.3,.3v10.29c0,.17-.14,.3-.3,.3Zm-16.08-.61h15.78V11.47H4.22v9.68Z"
      />
      <path
        d="M8,15.31h-2.3c-.17,0-.3-.14-.3-.3s.14-.3,.3-.3h2.3c.17,0,.3,.14,.3,.3s-.14,.3-.3,.3Z"
      />
      <path
        d="M18.13,14.33h-2.1c-.17,0-.3-.14-.3-.3s.14-.3,.3-.3h2.1c.17,0,.3,.14,.3,.3s-.14,.3-.3,.3Z"
      />
      <path
        d="M19.16,12.87h-2.05c-.17,0-.3-.14-.3-.3s.14-.3,.3-.3h2.05c.17,0,.3,.14,.3,.3s-.14,.3-.3,.3Z"
      />
      <path
        d="M14.61,21.77h-4.99c-.17,0-.3-.14-.3-.3V12.61c0-.17,.14-.3,.3-.3h4.99c.17,0,.3,.14,.3,.3v8.85c0,.17-.14,.3-.3,.3Zm-4.68-.61h4.38V12.92h-4.38v8.24Z"
      />
      <path
        d="M20.48,7.34H3.8c-.17,0-.3-.14-.3-.3v-2.25c0-.17,.14-.3,.3-.3H20.48c.17,0,.3,.14,.3,.3v2.25c0,.17-.14,.3-.3,.3Zm-16.37-.61H20.17v-1.64H4.1v1.64Z"
      />
      <path
        d="M21.06,5.09H3.16c-.17,0-.3-.14-.3-.3v-1.32c0-.17,.14-.3,.3-.3H21.06c.17,0,.3,.14,.3,.3v1.32c0,.17-.14,.3-.3,.3ZM3.47,4.48H20.76v-.71H3.47v.71Z"
      />
      <path
        d="M23.66,11.45H.47c-.17,0-.3-.14-.3-.3v-1.32c0-.17,.14-.3,.3-.3H23.66c.17,0,.3,.14,.3,.3v1.32c0,.17-.14,.3-.3,.3ZM.78,10.84H23.35v-.71H.78v.71Z"
      />
      <path
        d="M1.4,10.08c-.13,0-.24-.08-.29-.2-.06-.16,.03-.33,.19-.39,.25-.09,.95-.4,1.22-.77,.37-.51,.99-1.8,1-1.81,.07-.15,.26-.21,.41-.14,.15,.07,.22,.26,.14,.41-.03,.06-.65,1.35-1.06,1.91-.43,.6-1.41,.95-1.52,.99-.03,.01-.07,.02-.1,.02Z"
      />
      <path
        d="M5.07,10.08c-.07,0-.13-.02-.19-.06-.13-.1-.16-.29-.05-.43l1.86-2.4c.1-.13,.29-.16,.43-.05,.13,.1,.16,.29,.05,.43l-1.86,2.4c-.06,.08-.15,.12-.24,.12Z"
      />
      <path
        d="M14.9,10.23c-.1,0-.21-.05-.26-.15l-1.52-2.59c-.08-.15-.04-.33,.11-.42s.33-.04,.42,.11l1.52,2.59c.08,.15,.04,.33-.11,.42-.05,.03-.1,.04-.15,.04Z"
      />
      <path
        d="M9.57,10.08s-.1-.01-.15-.04c-.15-.08-.2-.27-.12-.41l1.37-2.49c.08-.15,.27-.2,.41-.12,.15,.08,.2,.27,.12,.41l-1.37,2.49c-.06,.1-.16,.16-.27,.16Z"
      />
      <path
        d="M18.67,10.03c-.1,0-.19-.05-.25-.13l-1.57-2.3c-.09-.14-.06-.33,.08-.42,.14-.1,.33-.06,.42,.08l1.57,2.3c.09,.14,.06,.33-.08,.42-.05,.04-.11,.05-.17,.05Z"
      />
      <path
        d="M23.17,10.08s-.07,0-.11-.02c-.1-.04-.98-.36-1.34-.63-.37-.26-1.25-1.79-1.51-2.25-.08-.15-.03-.33,.12-.42,.15-.08,.33-.03,.42,.12,.5,.89,1.15,1.92,1.33,2.05,.25,.18,.95,.46,1.2,.55,.16,.06,.24,.23,.18,.39-.05,.12-.16,.2-.29,.2Z"
      />
      <path
        d="M12.21,21.48c-.17,0-.3-.14-.3-.3V12.71c0-.17,.14-.3,.3-.3s.3,.14,.3,.3v8.46c0,.17-.14,.3-.3,.3Z"
      />
      <path
        d="M14.46,15.12h-4.79c-.17,0-.3-.14-.3-.3s.14-.3,.3-.3h4.79c.17,0,.3,.14,.3,.3s-.14,.3-.3,.3Z"
      />
      <path
        d="M14.56,17.32h-4.94c-.17,0-.3-.14-.3-.3s.14-.3,.3-.3h4.94c.17,0,.3,.14,.3,.3s-.14,.3-.3,.3Z"
      />
      <path
        d="M14.31,19.57h-4.45c-.17,0-.3-.14-.3-.3s.14-.3,.3-.3h4.45c.17,0,.3,.14,.3,.3s-.14,.3-.3,.3Z"
      />
    </svg>`,
    steps: [
      {
        image: "pm-dashboard/AD1I5182",
        text: `
        <p>As world leaders in diagnostic testing, you have access to a full suite of health testing services through fully accredited testing laboratories, ISO:15189 for medical analysis or ISO:17025 for forensic reporting. </p>
        <p>From the highest precision blood testing packages to advanced genetic and epigenetic testing reports, you have access to the widest ranges of testing available today.</p>
        `,
      },
      {
        image: "pm-dashboard/iStock-1256323051",
        text: `
          <p>The blood testing laboratory has developed the widest UKAS schedule of tests available from just a finger-prick sample of blood, allowing you to take your sample in the comfort and convenience of your own home. Thanks to their extensive research and development, tests performed on finger-prick samples will have the same detailed reports as those from blood drawn by a phlebotomist by venipuncture.</p>
        `,
      },
    ],
  },
  process: {
    title: "Our process",
    description:
      "We have a range of options for collecting your blood sample, from self administered through to a home-visit from a phlebotomist.",
    color: "cobalt",
    image: "pm-biomarker-images/blood-sugar2",
    icon: `
    <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-12 w-12 fill-current text-cobalt-500"
          >
            <path
              d="M7.8,17.99c-2.17,0-3.93-1.76-3.93-3.93s1.76-3.93,3.93-3.93,3.93,1.76,3.93,3.93-1.76,3.93-3.93,3.93Zm0-7.29c-1.85,0-3.36,1.51-3.36,3.36s1.51,3.36,3.36,3.36,3.36-1.51,3.36-3.36-1.51-3.36-3.36-3.36Z"
            />
            <path
              d="M3.32,10.35c-.08,0-.15-.03-.21-.09-.1-.12-.1-.3,.02-.4,.33-.3,.41-.36,.46-.4,.05-.04,.06-.05,.4-.37,.11-.11,.29-.1,.4,.01,.11,.11,.1,.29-.01,.4-.36,.34-.37,.35-.43,.4-.05,.04-.12,.1-.44,.39-.05,.05-.12,.07-.19,.07Z"
            />
            <path
              d="M1.83,13.52c-.07,0-.15-.03-.2-.09l-1.16-1.22c-.08-.08-.1-.21-.05-.31l.97-2.15c.05-.11,.17-.18,.3-.16l1.44,.2c.15,.02,.26,.16,.24,.32-.02,.16-.17,.26-.32,.24l-1.23-.17-.8,1.78,1.03,1.08c.11,.11,.1,.29,0,.4-.05,.05-.13,.08-.2,.08Z"
            />
            <path
              d="M3.02,18.1h0l-1.68-.04c-.12,0-.22-.07-.26-.18L.23,15.68c-.04-.12,0-.25,.09-.33l1.02-.78c.02-.19,.08-.5,.2-1,.04-.15,.19-.24,.34-.21,.15,.04,.24,.19,.21,.34-.08,.32-.16,.72-.19,.89,.04,.1,.02,.22-.06,.31,0,0-.02,.02-.03,.03l-.99,.75,.69,1.83,1.49,.03c.16,0,.28,.13,.28,.29,0,.15-.13,.28-.28,.28Z"
            />
            <path
              d="M5.81,21.57s-.08,0-.12-.03l-2.13-1c-.11-.05-.18-.18-.16-.3l.2-1.32-.72-.93c-.1-.12-.07-.3,.05-.4,.12-.1,.3-.07,.4,.05l.8,1.03c.05,.06,.07,.14,.06,.22l-.19,1.23,1.77,.83,1.1-1.01c.12-.11,.29-.1,.4,.02,.11,.12,.1,.29-.02,.4l-1.24,1.14c-.05,.05-.12,.08-.19,.08Z"
            />
            <path
              d="M9.58,21.51c-.08,0-.17-.04-.22-.11l-.81-1.03h-1.37c-.13,.01-.28-.11-.29-.27s.12-.28,.28-.29h1.51c.08-.02,.17,.02,.23,.09l.77,.98,1.81-.73v-1.49c0-.16,.13-.28,.29-.28,.14,0,.28,.13,.28,.28v1.69c0,.12-.08,.22-.18,.26l-2.19,.88s-.07,.02-.11,.02Z"
            />
            <path
              d="M11.89,18.91c-.08,0-.17-.04-.22-.11-.1-.12-.07-.3,.05-.4,1.05-.82,1.19-.92,1.33-.79l1.11,.15,.8-1.78-1.03-1.08c-.11-.11-.1-.29,0-.4,.11-.11,.29-.1,.4,0l1.16,1.22c.08,.08,.1,.21,.05,.31l-.97,2.15c-.05,.11-.17,.18-.3,.16l-1.34-.18c-.17,.12-.55,.41-.89,.68-.05,.04-.11,.06-.17,.06Z"
            />
            <path
              d="M14.13,14.94s-.02,0-.04,0c-.16-.02-.27-.16-.25-.32l.18-1.4c0-.08,.05-.14,.11-.19l.99-.75-.69-1.83-1.49-.03c-.16,0-.28-.13-.28-.29,0-.15,.13-.28,.28-.28h0l1.68,.04c.12,0,.22,.07,.26,.18l.84,2.2c.04,.12,0,.25-.09,.33l-1.06,.81-.16,1.29c-.02,.14-.14,.25-.28,.25Z"
            />
            <path
              d="M12.89,10.37c-.08,0-.16-.03-.22-.1l-.82-.98c-.05-.06-.08-.14-.06-.23l.19-1.23-1.77-.83-1.1,1.01c-.11,.11-.29,.1-.4-.02-.11-.12-.1-.29,.02-.4l1.24-1.14c.08-.08,.21-.1,.31-.05l2.13,1c.11,.05,.18,.18,.16,.3l-.2,1.31,.74,.88c.1,.12,.09,.3-.03,.4-.05,.04-.12,.07-.18,.07Z"
            />
            <path
              d="M4.18,9.57h0c-.16,0-.28-.13-.28-.28v-1.69c0-.12,.08-.22,.18-.26l2.19-.88c.12-.05,.25-.01,.33,.09l.81,1.03h1.44c.14-.03,.28,.12,.28,.27,0,.16-.13,.28-.28,.28h-1.58s0,0,0,0c-.09,0-.17-.04-.22-.11l-.77-.98-1.81,.73v1.49c0,.16-.13,.28-.29,.28Z"
            />
            <path
              d="M17.6,20.47c-.21,0-.43-.03-.64-.1-1.09-.35-1.7-1.53-1.35-2.62,.17-.53,.54-.96,1.03-1.22,.49-.25,1.06-.3,1.59-.13,1.09,.35,1.7,1.53,1.35,2.62-.17,.53-.54,.96-1.03,1.22-.3,.15-.62,.23-.95,.23Zm0-3.6c-.24,0-.47,.06-.69,.17-.36,.19-.63,.5-.75,.89-.26,.8,.18,1.65,.98,1.91,.39,.12,.8,.09,1.16-.1,.36-.19,.63-.5,.75-.89h0c.26-.8-.18-1.65-.98-1.91-.15-.05-.31-.07-.47-.07Z"
            />
            <path
              d="M15.57,21.77c-.07,0-.14-.03-.19-.08l-.85-.79c-.09-.09-.12-.22-.06-.33l.27-.53-.18-.49c-.05-.15,.02-.31,.17-.36,.15-.05,.31,.02,.36,.17l.22,.6c.03,.07,.02,.15-.01,.22l-.23,.45,.56,.52,.58-.27c.14-.07,.31,0,.38,.14,.07,.14,0,.31-.14,.38l-.75,.35s-.08,.03-.12,.03Z"
            />
            <path
              d="M17.36,22.31c-.12,0-.22-.07-.27-.18l-.2-.54-.58-.18c-.15-.05-.23-.21-.19-.35,.05-.15,.2-.23,.35-.19l.71,.22c.08,.03,.15,.09,.18,.17l.18,.47,.76-.05,.2-.61c.05-.15,.21-.23,.36-.18,.15,.05,.23,.21,.18,.36l-.26,.79c-.04,.11-.13,.19-.25,.19l-1.16,.08s-.01,0-.02,0Z"
            />
            <path
              d="M20.06,21.54s-.08,0-.12-.03l-.55-.26c-.16,.06-.46,.17-.46,.17l-.19-.53c.64-.23,.8-.29,.9-.15l.36,.18,.51-.57-.28-.58c-.07-.14,0-.31,.13-.38,.14-.07,.31-.01,.38,.13l.36,.75c.05,.1,.03,.23-.04,.31l-.78,.86c-.05,.06-.13,.09-.21,.09Z"
            />
            <path
              d="M20.49,19.9s-.08,0-.12-.03c-.14-.07-.2-.23-.14-.38l.3-.63c.03-.07,.09-.12,.16-.15l.48-.17-.04-.76-.61-.21c-.15-.05-.23-.21-.18-.36,.05-.15,.21-.23,.36-.18l.79,.27c.11,.04,.18,.14,.19,.25l.06,1.16c0,.13-.07,.24-.19,.28l-.57,.2-.24,.53c-.05,.1-.15,.16-.26,.16Z"
            />
            <path
              d="M20.59,17.56c-.11,0-.22-.07-.26-.18l-.24-.59c-.03-.08-.03-.16,0-.23l.23-.45-.56-.52-.58,.27c-.14,.07-.31,0-.38-.14-.07-.14,0-.31,.14-.38l.75-.35c.11-.05,.23-.03,.31,.05l.85,.79c.09,.09,.12,.22,.06,.33l-.26,.53,.19,.47c.06,.14-.01,.31-.15,.37-.04,.01-.07,.02-.11,.02Z"
            />
            <path
              d="M19.03,15.91s-.06,0-.09-.01l-.74-.23c-.08-.03-.15-.09-.18-.17l-.18-.47-.76,.05-.2,.61c-.05,.15-.21,.23-.36,.18-.15-.05-.23-.21-.18-.36l.26-.79c.04-.11,.13-.19,.25-.19l1.16-.08c.12-.01,.24,.07,.29,.18l.2,.54,.61,.19c.15,.05,.23,.21,.18,.36-.04,.12-.15,.2-.27,.2Z"
            />
            <path
              d="M17.63,11.31c-.65,0-1.28-.2-1.82-.59-.68-.49-1.13-1.21-1.26-2.03-.28-1.7,.88-3.31,2.58-3.59,1.7-.28,3.31,.88,3.59,2.58,.28,1.7-.88,3.31-2.58,3.59-.17,.03-.34,.04-.51,.04Zm.01-5.68c-.14,0-.28,.01-.42,.03-.67,.11-1.27,.48-1.66,1.03-.4,.55-.56,1.23-.45,1.91,.11,.67,.48,1.27,1.03,1.66s1.23,.56,1.91,.45c.67-.11,1.27-.48,1.66-1.03s.56-1.23,.45-1.91c-.11-.67-.48-1.27-1.03-1.66-.44-.32-.96-.48-1.49-.48Z"
            />
            <path
              d="M13.69,5.95c-.06,0-.12-.02-.18-.06-.12-.1-.14-.28-.04-.4,.22-.27,.27-.33,.3-.37,.04-.04,.04-.05,.26-.33,.1-.12,.27-.15,.4-.05,.12,.1,.15,.27,.05,.4-.23,.31-.24,.32-.28,.36-.03,.03-.08,.09-.29,.35-.06,.07-.14,.11-.22,.11Z"
            />
            <path
              d="M19.91,13.75c-.07,0-.14-.03-.19-.07l-.73-.67-1.02,.18c-.16,.03-.3-.08-.33-.23-.03-.15,.08-.3,.23-.33l1.16-.2c.09-.02,.17,.01,.24,.07l.68,.62,1.23-.74-.18-1.11c-.02-.15,.08-.3,.23-.32,.16-.02,.3,.08,.32,.23l.21,1.3c.02,.11-.03,.23-.13,.29l-1.57,.95s-.1,.04-.15,.04Z"
            />
            <path
              d="M21.35,11.46c-.07,0-.14-.02-.19-.08-.12-.11-.12-.29-.01-.4,.74-.8,.84-.9,.99-.8l.81-.02,.37-1.38-.9-.68c-.12-.09-.15-.27-.06-.4,.09-.12,.27-.15,.4-.06l1.05,.79c.09,.07,.13,.19,.1,.3l-.47,1.77c-.03,.12-.14,.21-.27,.21l-1.03,.03c-.12,.12-.36,.38-.58,.62-.06,.06-.13,.09-.21,.09Z"
            />
            <path
              d="M22.58,8.12c-.15,0-.28-.12-.28-.27l-.04-1.1c0-.08,.03-.15,.08-.2l.63-.67-.72-1.24-1.11,.16c-.15,.02-.3-.09-.32-.24-.02-.15,.09-.3,.24-.32l1.3-.18c.11-.02,.23,.04,.28,.14l.92,1.59c.06,.11,.05,.25-.04,.34l-.7,.74,.04,.98c0,.16-.12,.29-.27,.29,0,0,0,0-.01,0Z"
            />
            <path
              d="M21.05,4.76c-.07,0-.13-.02-.18-.07l-.76-.65c-.06-.05-.1-.13-.1-.21v-.92s-1.39-.39-1.39-.39l-.69,.88c-.1,.12-.28,.14-.4,.05-.12-.1-.15-.27-.05-.4l.81-1.04c.07-.09,.19-.13,.3-.1l1.76,.5c.12,.03,.2,.14,.21,.27v1s.67,.57,.67,.57c.12,.1,.13,.28,.03,.4-.06,.06-.13,.1-.21,.1Z"
            />
            <path
              d="M14.25,5.24c-.14,0-.26-.1-.28-.24l-.21-1.3c-.02-.11,.03-.23,.13-.29l1.57-.95c.11-.06,.25-.05,.34,.03l.73,.67,1.08-.18c.15-.02,.3,.08,.33,.23,.03,.15-.08,.3-.23,.33l-1.22,.21c-.09,.01-.17-.01-.24-.07l-.68-.62-1.23,.74,.18,1.11c.02,.15-.08,.3-.23,.32-.02,0-.03,0-.05,0Z"
            />
          </svg>
          `,
    steps: [
      {
        image: "pm-dashboard/AD1I7045",
        text: `
        <p>The choice is yours to select your preferred option for taking your blood test sample.</p>
        <p>For most of our health test packages, there is the option of taking the blood sample yourself via a finger prick, and we can provide all the instructions and support you need to do this safely and effectively. Finger prick collection is easy to do at home, and you will simply need to prick the tip of your finger with a small needle and squeeze the blood into a vial that we provide you.</p>        
        <p>Alternatively, for all of our blood tests, we can arrange for a qualified nurse or phlebotomist to come to your home or work and take your sample via venipuncture. They will use a needle to take blood from a vein, normally in your arm. If you choose this option, once you have ordered your test a phlebotomist or nurse will be in touch with you directly to arrange an appointment to suit you in your preferred location.</p>        
        <p>The process is simple and easy-to-follow, where you can receive your kit at home, follow our step-by-step instructions at your convenience and complete your blood sample, and send  the collected sample back in the post for our lab to process. Our helpful customer services  team are available to answer any questions you may have about the process.</p> 
        `,
      },
      // {
      //   image: "pm-dashboard/AD1I7843",
      //   text: `
      //   <p>Venipuncture is suitable for anyone who prefers that someone else takes their sample for them. This is closer to how you would have your blood sample collected at the doctor's office or hospital. A trained phlebotomist or nurse will use a needle to collect your blood sample from a suitable vein, usually in your arm.</p>
      //   <p>If you’re not sure about taking your own sample, we offer two options for a fully trained phlebotomist to collect your sample for a small additional charge.</p>
      //   <p>We work with a number of collection sites, including select Superdrug stores across, the UK which you can visit to have your sample collected. Each collection centre has its own appointment booking system which we will give you access to, so you can choose the best day and time for you. With this option, your blood will be collected by venipuncture rather than a finger prick.</p>
      //   <p>For our most bespoke service, if you have a busy schedule or would prefer to have your sample collected in the comfort of your own home, we can arrange for a nurse to visit you at a location of your choice - home or work - to draw your blood via venipuncture.</p>
      //   <p>Once you have received and activated your kit, a nurse will be in touch with you directly to arrange an appointment to suit you in any location you feel comfortable.</p>
      //   `,
      // },
      {
        image: "pm-dashboard/iStock-182894643",
        text: `
          <p>Once your blood sample has been collected, it will need to reach our laboratory as quickly as possible to maintain the quality of the sample. It’s important that you send the sample back to us in the packaging provided in order to keep it safe during transport. Please read the directions carefully and ensure you follow each step, as either the postal service or our laboratory may not accept the package if it is incorrectly packed, sealed, or labelled.</p>
          <p>Blood samples should be taken on a Monday-Thursday, and posted back to the laboratory on the same day before the last ‘post’ collection to ensure that there are no delays in the sample reaching our laboratory. </p>
          <p>The turnaround time for your results will depend on what package you have ordered. We’ll let you know as soon as your results are available in your secure online portal. From there, you can review the report, and will have the opportunity to book a private consultation with one of our expert doctors to discuss your results in greater depth.</p>
        `,
      },
    ],
  },
  doctors: {
    title: "The Medical Team",
    description:
      "One of the team of doctors headed up by Dr Ali Salamat will review your results, providing an insightful and actionable report. Explore more about the clinical team here...",
    color: "tangerine",
    image: "pm-biomarker-images/blood-sugar2",
    icon: `
    <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-12 w-12 fill-current text-tangerine-500"
          >
            <path
              d="M21.69,23.72c-.17,0-.31-.13-.31-.3-.09-2.65-.42-5.71-.8-6.03-.51-.38-3.94-1.69-6.12-2.47-.16-.06-.25-.24-.19-.4,.06-.16,.24-.25,.4-.19,.56,.2,5.53,2,6.29,2.56,.8,.6,1,5.13,1.05,6.51,0,.17-.13,.32-.3,.32h-.01Z"
            />
            <path
              d="M16.66,23.27c-.24,0-.43-.16-.52-.31-.03-.04-.04-.09-.05-.14l-.05-.54c-.02-.17,.11-.32,.28-.34,.17-.01,.32,.11,.34,.28l.03,.35s.06-.09,.1-.17c.37-.73,.81-2.92,.59-3.78-.18-.71-.63-.81-1.26-.75-.51,.05-1.04,.66-1.4,1.64-.11,.31-.24,.61-.36,.89-.19,.45-.48,1.14-.4,1.29l.31,.26,.08-.24c.06-.16,.24-.25,.4-.19,.16,.06,.25,.24,.19,.4l-.3,.85c-.04,.12-.15,.2-.27,.21-.12,.01-.24-.05-.3-.16l-.2-.38-.32-.27c-.45-.38-.15-1.1,.24-2.01,.11-.27,.24-.56,.35-.86,.46-1.24,1.14-1.97,1.92-2.05,.39-.04,1.59-.16,1.93,1.22,.28,1.11-.26,3.46-.64,4.21-.19,.37-.41,.57-.67,.58,0,0-.02,0-.03,0Z"
            />
            <path
              d="M16.63,17.62c-.17,0-.31-.14-.31-.31v-1.72c0-.17,.14-.31,.31-.31s.31,.14,.31,.31v1.72c0,.17-.14,.31-.31,.31Z"
            />
            <path
              d="M16.32,15.31c-.05,0-.11-.01-.16-.04-.15-.09-.2-.28-.11-.43l.88-1.5c-.05-.3-.14-1.08,.09-2.01,.27-1.06,.5-3.27,.2-4.85-.17-.86-.84-3.75-2.55-4.66-1.67-.89-2.7-1.1-3.94-.83-1.29,.28-3.16,1.89-3.79,3.74-.15,.45-.29,.81-.41,1.1-.37,.95-.51,1.31-.33,2.81,.13,1.07,.33,1.93,.5,2.62,.16,.66,.26,1.09,.22,1.4-.06,.49,.32,1.8,.72,2.02,.15,.08,.2,.27,.12,.43-.08,.15-.28,.2-.43,.12-.7-.39-1.11-1.99-1.03-2.64,.03-.2-.08-.65-.21-1.18-.16-.67-.38-1.58-.51-2.7-.19-1.66-.02-2.11,.37-3.11,.11-.29,.25-.64,.4-1.08C7.06,2.45,9.12,.7,10.6,.38c1.59-.35,2.85,.08,4.37,.89,1.91,1.01,2.65,3.9,2.87,5.1,.32,1.67,.07,4-.21,5.11-.25,1.01-.07,1.84-.07,1.85,.02,.08,0,.16-.03,.23l-.94,1.61c-.06,.1-.16,.15-.27,.15Z"
            />
            <path
              d="M11.77,14.17c-.72,0-1.43-.27-2.65-1.74-1.38-1.67-1.8-5.53-1.82-5.7-.02-.16,.1-.31,.26-.34,.02,0,2.4-.41,3.7-.85,1.23-.41,2.66-2.21,2.67-2.23,.07-.09,.19-.13,.3-.12,.11,.02,.21,.1,.24,.21,0,.01,.44,1.36,.81,2.09,.34,.68,.92,1.08,.93,1.08,.08,.06,.14,.15,.14,.26,0,.04,0,1.09-.56,3.34-.57,2.32-2.68,3.9-3.64,3.98-.12,.01-.24,.02-.37,.02Zm-3.81-7.22c.12,.92,.59,3.8,1.65,5.07,1.33,1.6,1.86,1.55,2.48,1.5,.58-.05,2.56-1.34,3.09-3.51,.42-1.69,.51-2.68,.53-3.04-.23-.19-.69-.61-.99-1.21-.24-.47-.5-1.17-.66-1.65-.54,.61-1.59,1.67-2.6,2.01-1.08,.36-2.81,.69-3.5,.82Z"
            />
            <path
              d="M11.87,18.79c-.57,0-.95-.44-1.51-1.15-.36-.45-1.2-1.61-1.2-2.6,0-.82,.46-1.81,.48-1.85,.07-.16,.26-.23,.42-.15,.16,.07,.22,.26,.15,.41,0,0-.42,.91-.42,1.59,0,.55,.4,1.38,1.07,2.21,.5,.62,.76,.91,1.03,.91,.24-.02,1.14-.84,1.62-1.64,.46-.76,.63-2.08,.41-2.47-.27-.49-.43-.65-.43-.66-.12-.12-.12-.32,0-.44,.12-.12,.32-.12,.44,0,.02,.02,.21,.22,.53,.79,.39,.69,.09,2.26-.42,3.1-.41,.68-1.51,1.94-2.16,1.94Z"
            />
            <path
              d="M7.4,19.37c-.11,0-.22-.06-.28-.17-.01-.03-.33-.66-.51-1.48-.18-.85,.09-2.08,.1-2.13,.04-.17,.2-.27,.37-.24,.17,.04,.27,.21,.24,.37,0,.01-.26,1.15-.1,1.86,.16,.74,.45,1.33,.46,1.34,.08,.15,.01,.34-.14,.42-.04,.02-.09,.03-.14,.03Z"
            />
            <path
              d="M8.11,21.57c-.81,0-1.46-.66-1.46-1.46s.66-1.46,1.46-1.46,1.46,.66,1.46,1.46-.66,1.46-1.46,1.46Zm0-2.3c-.46,0-.84,.38-.84,.84s.38,.84,.84,.84,.84-.38,.84-.84-.38-.84-.84-.84Z"
            />
            <path
              d="M2.26,23.67h-.01c-.17,0-.31-.15-.3-.32,.04-1.38,.25-5.91,1.05-6.5,.75-.56,5.72-2.36,6.29-2.56,.16-.06,.34,.03,.4,.19,.06,.16-.03,.34-.19,.4-2.18,.78-5.61,2.09-6.12,2.47-.38,.31-.71,3.37-.8,6.03,0,.17-.14,.3-.31,.3Z"
            />
          </svg>
    `,
    steps: [
      {
        image: "pm-dashboard/iStock-654492200",
        text: `
        <p>Meet Dr Ali Salamat, the medical director at DNA Workplace. He works as part of a wider team, with innovation and excellence being the key principles of what we do and continually aspire to achieve.</p>
        <p>Outside of DNA Workplace, he is an award-winning clinical surgeon. Ali has been nationally appointed as Associate Surgical Speciality Lead at the Royal College of Surgeons’, working to develop research engagement.</p>
        <p>Outside of work, he is kept active and busy by his two young children and hobby of cycling by the beaches and valleys of South Wales.</p>
        <strong>Qualifications</strong>
        <ul>
          <li>Doctorate of Medicine (DM)</li>
          <li>Member of the Royal College of Surgeon’s Edinburgh (MRCSEd ENT)</li>
          <li>Bachelor of Medicine, Bachelor of Surgery (MBChB)</li>
        </ul>
        `,
      },
    ],
  },
  results: {
    title: "Your results",
    description:
      "Your personalised results report is written by one of our expert medical team, and provides a breakdown of what each test marker means and whether your result was below, within or above the normal range.",
    color: "emerald",
    image: "pm-biomarker-images/blood-sugar2",
    icon: `
      <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-12 w-12 fill-current text-emerald-500"
          >
            <path
              d="M19.92,13.9h-7.46c-.16,0-.28-.13-.28-.28v-4.21c0-.16,.13-.28,.28-.28s.28,.13,.28,.28v3.93h7.18c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M13.11,12.47c-.06,0-.12-.02-.17-.06-.12-.09-.15-.27-.06-.4l1.01-1.35c.09-.12,.26-.15,.39-.06l.96,.68,.51-.5c.09-.09,.22-.11,.33-.05l1.41,.7,.49-.79c.08-.12,.23-.17,.36-.11l1.68,.82c.14,.07,.2,.24,.13,.38-.07,.14-.24,.2-.38,.13l-1.45-.71-.49,.79c-.08,.12-.24,.17-.37,.1l-1.45-.73-.53,.53c-.1,.1-.25,.11-.36,.03l-.93-.66-.85,1.13c-.06,.07-.14,.11-.23,.11Z"
            />
            <path
              d="M8.93,14.5c-1.39,0-2.53-1.13-2.53-2.53s1.14-2.53,2.53-2.53,2.53,1.13,2.53,2.53-1.14,2.53-2.53,2.53Zm0-4.49c-1.08,0-1.96,.88-1.96,1.96s.88,1.96,1.96,1.96,1.96-.88,1.96-1.96-.88-1.96-1.96-1.96Z"
            />
            <path
              d="M10.81,12.41h-1.8c-.16,0-.28-.13-.28-.28v-2.09c0-.16,.13-.28,.28-.28s.28,.13,.28,.28v1.81h1.52c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M18.93,7.64H5.54c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h13.39c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M18.97,8.6H5.58c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h13.39c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M5.93,10.34H3.75c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h2.18c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M5.91,11.21H3.73c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h2.18c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M5.95,12.15H3.77c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h2.18c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M5.93,13.02H3.74c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h2.18c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M5.93,13.84H3.74c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h2.18c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M5.9,14.72H3.72c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h2.18c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M23.46,18.3H.51c-.11,0-.21-.07-.26-.17-.05-.1-.03-.22,.05-.3l2.08-2.36c.05-.06,.13-.1,.21-.1H21.31c.08,0,.16,.03,.21,.09l2.15,2.36c.08,.08,.09,.2,.05,.3-.05,.1-.15,.17-.26,.17Zm-22.33-.57H22.82l-1.63-1.79H2.72l-1.58,1.79Z"
            />
            <path
              d="M21.31,15.94h0c-.16,0-.28-.13-.28-.28l.07-9.29s0-.04,0-.07c0,0,.23-.95-.33-1.45-.6-.54-1.26-.55-1.27-.55H4.01c-.11,.03-.61,.17-.81,.65-.26,.64-.33,1.5-.33,1.51V15.66c0,.16-.13,.28-.28,.28s-.28-.13-.28-.28V6.43c0-.06,.08-.97,.37-1.7,.33-.83,1.21-1,1.25-1.01,.02,0,.03,0,.05,0h15.54s.88,0,1.65,.7c.74,.67,.56,1.76,.51,1.98l-.07,9.26c0,.16-.13,.28-.28,.28Z"
            />
            <path
              d="M21.57,6.51H2.63c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28H21.57c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M22.7,19.92c-.1,0-.19,0-.27-.02H1.57s-.52,0-.96-.15c-.46-.16-.53-.58-.46-.86v-1.07c0-.16,.13-.28,.28-.28s.28,.13,.28,.28v1.11s0,.06-.01,.09c-.04,.15,.05,.19,.09,.2,.35,.12,.77,.12,.78,.12H22.46s.04,0,.06,0c.09,.02,.47,.05,.56-.12l.17-.36v-.84c0-.16,.13-.28,.28-.28s.28,.13,.28,.28v.9s0,.08-.03,.12l-.2,.42c-.17,.36-.56,.46-.88,.46Z"
            />
            <path
              d="M12.72,5.52h-1.24c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.24c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M4.15,17.15h-1.09c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.09c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M5.93,17.15h-1.09c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.09c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M7.71,17.17h-1.09c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.09c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M9.49,17.17h-1.09c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.09c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M11.29,17.19h-1.09c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.09c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M13.07,17.18h-1.09c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.09c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M14.85,17.21h-1.09c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.09c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M16.63,17.21h-1.09c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h1.09c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
            <path
              d="M21.16,17.17h-3.73c-.16,0-.28-.13-.28-.28s.13-.28,.28-.28h3.73c.16,0,.28,.13,.28,.28s-.13,.28-.28,.28Z"
            />
          </svg>
      `,
    steps: [
      {
        image: "pm-dashboard/AD1I8502",
        text: `
          <p>Once your health test has been processed by our laboratory, you will receive a report with a doctor’s letter commenting on the test outcome. In addition, the report includes a comprehensive breakdown of every marker that has been tested for and an explanation of whether each biomarker was ‘within range’, ‘below range’, or ‘above range. These categories represent:</p>
          <p><strong>Within range:</strong> this means that your results were within the normal reference ranges, according to your age and gender. </p>
          <p><strong>Below range:</strong> this means that your result was lower than the normal reference range.</p>
          <p><strong>Above range:</strong> this means that your result was higher than the normal reference range.</p>
        `,
      },
      {
        image: "pm-dashboard/results",
        text: `
          <p>Each set of results provides a snapshot of your health on the day your blood was taken. Should any of the results come back outside of normal parameters, we recommend that you consult with a doctor who can further advise on why your result could be out of range, and recommend next steps.</p>
          <p><em>If you are currently experiencing any adverse health symptoms that are causing you concern we recommend that you consult with a doctor, regardless of your test results. A diagnosis cannot be made from one test result alone, and it can be helpful to track changes in levels over time, however your test results and report is not a substitute for medical advice</em></p>
        `,
      },
    ],
  },
};

export { testDetails, TestDetails };
