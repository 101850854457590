<template>
  <form @submit.prevent="updateEmail" class="space-y-4">
    <h1 class="text-denim-700 font-normal text-2xl">Password</h1>
    <p>Set the password associated with your account.</p>
    <BaseTextInput
      type="password"
      id="email"
      name="email"
      v-model="currentPassword"
      label="Current Password"
    />
    <BaseTextInput
      type="password"
      id="email"
      name="email"
      v-model="newPassword"
      label="New Password"
    />
    <div class="mt-2 text-rose-500 text-sm">{{ error }}</div>
    <button
      class="block bg-cobalt-500 py-2 px-4 rounded text-white"
      :class="{ loading: saveStatus === 'saving' }"
      type="submit"
      @click="submit"
    >
      Submit
    </button>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useAuth, useAccountStore } from "@/stores/account";
import { useRouter } from "vue-router";

const props = defineProps({
  account: Object,
});

const store = useAccountStore();
const auth = useAuth();
const router = useRouter();
const saveStatus = ref("idle");
const error = ref("");

const currentPassword = ref("");
const newPassword = ref("");

async function updateEmail() {
  error.value = "";
  saveStatus.value = "saving";
  try {
    await store.updatePassword({
      account: props.account,
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
    });
    await auth.fetch();
    router.push({ name: "account-view" });
  } catch (e) {
    if (e instanceof Error) error.value = e.message;
  } finally {
    saveStatus.value = "idle";
  }
}
</script>
