<template>
  <button
    @click="$emit('onSubmit')"
    type="submit"
    class="py-2 px-4 rounded flex items-center justify-center bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
    :class="{
      'bg-grey-500 hover:bg-grey-500 cursor-default': disabled,
      loading,
    }"
    :disabled="disabled"
  >
    <AppIcon v-if="!hideIcon" :icon="icon" class="mr-2" />
    <slot />
  </button>
</template>

<script>
export default {
  name: "SubmitButton",

  props: {
    icon: String,
    hideIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>
