<template>
  <div class="block rounded bg-white shadow overflow-hidden h-full">
    <div class="fade-bottom h-48 overflow-hidden">
      <AppImage
        class="object-cover object-center h-48 w-full"
        :public-id="test.image"
      />
    </div>

    <div class="p-6">
      <div class="flex items-center space-x-4">
        <div class="text-lg">{{ test.name }}</div>
      </div>
      <div class="mb-4">
        <div class="flex items-center space-x-1 text-sm text-grey-700">
          <AppIcon
            icon="calendar"
            :classes="['h-4 w-4']"
            :containerClasses="['inline w-4 h-4']"
          />
          <span>
            Completed
            {{ new Date(test.result.date).toLocaleDateString("en-GB") }}
          </span>
        </div>
      </div>
      <div class="mb-4" v-html="truncatedSummary()" />
      <HealthTrafficLights
        v-if="!test.result.testFailed"
        :bloodTest="test.result"
      />
      <div class="flex items-center text-cobalt-500 font-semibold mt-4">
        <span>View test</span>
        <AppIcon icon="chevron-right" />
      </div>
    </div>
  </div>
</template>

<script setup>
import HealthTrafficLights from "@/components/HealthTrafficLights.vue";

const props = defineProps({
  test: Object,
});

function truncatedSummary() {
  if (props.test.result.medicalReportSummary?.length <= 280) {
    return props.test.result.medicalReportSummary;
  }
  return props.test.result.medicalReportSummary?.slice(0, 280) + "...";
}
</script>
