<template>
  <div class="rounded bg-white shadow p-6 space-y-4">
    <!-- 
    Original logic
    <div
      v-if="!test?.questionnaireAnswers && !test?.result"
      class="flex flex-col justify-between h-full"
    > -->
    <div
      v-if="!patientStore.patient.questionnaireAnswers"
      class="flex flex-col justify-between h-full"
    >
      <div class="pb-4">
        To get the most from your results we advise that you complete your
        personal questionnaire. This should only take a few minutes to complete
        and will be considered by the Dr when writing your report. Once your
        results are released we are not able to consider your questionnaire
        entries.
        <!-- To get the most from your next set of results we advise that you
        check in and update your personal questionnaire. This should only take a
        few minutes to complete as we have stored your previous answers and will
        be considered by the Dr when writing your report. If you do not update
        your entries we will use the previous ones and are not able to consider
        updates once the results are released. -->
      </div>
      <router-link
        class="text-cobalt-500 font-semibold"
        :to="{ name: 'questionnaire' }"
      >
        Click here to complete
      </router-link>
    </div>
    <!-- <div
      v-else-if="!test?.questionnaireAnswers && test?.result"
      class="content"
    >
      <p>
        You did not provide us with your health questionnaire for this test.
        Unfortunately this means we are not able to consider any current or
        historically health related conditions or your current wellbeing and
        activity.
      </p>

      <p>
        We will be able to provide you a more holistic results report if you
        fill this in for your next test.
      </p>

      <p>
        Sorry - we cannot retrospectively provide results so you cannot fill the
        questionnaire out for this test.
      </p>
    </div> -->
    <div v-else-if="selectedGoals && selectedGoals.length === 0">
      <p class="mb-4">
        You haven't yet selected any wellbeing goals. By adding these our doctor
        can consider them when writing his report.
      </p>
      <router-link
        :to="{ name: 'questionnaire' }"
        class="text-cobalt-500 font-semibold"
      >
        Edit your health questionnaire
      </router-link>
    </div>
    <div v-else class="//flex items-center">
      <h2 class="mr-4 mb-4">Your health goals</h2>
      <!-- <button
        @click="showModal = true"
        class="text-cobalt-500 rounded font-semibold flex items-center text-sm"
      >
        Update
        <AppIcon icon="arrow-right" class="ml-2" />
      </button> -->
      <div class="flex flex-wrap">
        <div
          v-for="goal in selectedGoals"
          :key="goal"
          class="bg-grey-100 text-grey-700 py-2 px-4 //text-white rounded flex items-center space-x-2 mr-2 mb-2"
        >
          <!-- <AppIcon :icon="getGoal(goal).icon" /> -->
          <YourHealthGoalsIcon :health-goal="getGoal(goal)" />
          <span>{{ getGoal(goal).name }}</span>
        </div>
      </div>
    </div>

    <AppModal :show="showModal" @close="showModal = false">
      <PortalHealthGoalsSelector
        :options="wellbeingGoals"
        v-model="selectedGoals"
        @modal-closed="showModal = false"
      />
    </AppModal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { usePatientStore } from "@/stores/patient";
import { wellbeingGoals } from "@/stores/dropdowns";
import AppModal from "@/components/AppModal.vue";
import PortalHealthGoalsSelector from "@/components/PortalHealthGoalsSelector.vue";
import YourHealthGoalsIcon from "@/components/YourHealthGoalsIcon.vue";

const props = defineProps({
  test: Object,
});

console.log({ test: props.test });

const patientStore = usePatientStore();

const showModal = ref(false);

const selectedGoals = ref(
  patientStore.patient.questionnaireAnswers?.wellbeing_goals ?? []
);

function getGoal(goal) {
  return wellbeingGoals.find((g) => g.id === goal);
}
</script>
