<template>
  <AppPage>
    <div>
      <div class="flex items-center container mx-auto text-denim-700 //mt-16">
        <AppIcon icon="heart" :classes="['h-6', 'w-6']" />
        <h1 class="ml-4 text-2xl font-normal">
          {{ patientStore.patient.firstName }}, your blood test result
        </h1>
      </div>
    </div>
    <div class="mt-16">
      <div class="md:flex md:space-x-8 max-md:space-y-4">
        <YourHealthGoals :test="test" class="flex-1" />
        <NextBloodTest v-if="patientStore.mostRecentTest.result" />
      </div>
    </div>

    <div class="mt-16 space-y-4">
      <h3
        class="text-xl font-normal md:flex max-md:space-y-2 items-center justify-between"
      >
        <div class="flex items-center space-x-2">
          <span>{{ test.name }}</span>
          <span
            class="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800"
          >
            {{ instance }} test
          </span>
        </div>
        <div class="flex items-center space-x-2 text-base text-grey-700">
          <AppIcon icon="calendar" />
          <span>
            Completed
            {{ new Date(test.result.date).toLocaleDateString("en-GB") }}
          </span>
        </div>
      </h3>
      <div class="md:flex items-start md:space-x-6 max-md:space-y-4">
        <div class="bg-white rounded shadow p-6 flex-1 flex flex-col content">
          <div class="content" v-html="test.result.medicalReport" />
          <DoctorDetails class="mt-4" />
        </div>
        <BookConsultation v-if="!test.result.testFailed">
          <div class="mb-4">
            <div class="mb-1">To attend to</div>
            <HealthTrafficLights :bloodTest="test.result" />
          </div>
        </BookConsultation>
      </div>
    </div>

    <div v-if="!test.result.testFailed" class="mt-12">
      <div class="flex">
        <div class="ml-auto inline-flex items-center space-x-4">
          <label for="sort-by" class="block text-sm font-medium text-gray-700">
            Sort by
          </label>
          <select
            id="sort-by"
            name="sort-by"
            class="mt-1 w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cobalt-500 focus:outline-none focus:ring-cobalt-500 sm:text-sm flex-1"
            @change="setSortOption"
          >
            <option value="alphabetical">Alphabetical</option>
            <option value="out-of-range">Out of range</option>
          </select>
        </div>
      </div>

      <div v-for="item in panels" :key="item.name">
        <AppDisclosure
          @toggle-panel="toggleListItems(item.name)"
          as="div"
          :default-open="
            disclosureState.openDisclosures.value.includes(item.name)
          "
        >
          <template v-slot:title="{ open }">
            <div
              class="bg-grey-100 p-6 w-full text-left rounded flex items-center justify-between mt-4"
            >
              <h1 class="flex items-center">
                <div
                  :style="`background-color: ${
                    colorMap[getLowestRangeForPanel(item)]
                  }`"
                  class="h-4 w-4 rounded-full mr-4"
                />
                {{ item.name }}
              </h1>
              <AppIcon
                :icon="`chevron-${open ? 'up' : 'down'}`"
                class="h-6 w-6"
              />
            </div>
          </template>
          <template #body>
            <div class="pb-24">
              <div
                v-for="(marker, index) in item.markers"
                :key="marker.name"
                :class="`mt-${index === 0 ? '8' : '16'}`"
              >
                <MarkerResult :marker="marker" :selected-blood-test="test" />
              </div>
            </div>
          </template>
        </AppDisclosure>
      </div>
    </div>
  </AppPage>
</template>

<script>
import { ref, computed } from "vue";
import { getLowestRangeForPanel } from "@/stores/results";
import { usePatientStore } from "@/stores/patient";
import { useRoute } from "vue-router";
import { useDisclosureState } from "@/composables/useDisclosureState";
import AppDisclosure from "@/components/Disclosure/AppDisclosure.vue";
import MarkerResult from "./MarkerResult.vue";
import DoctorDetails from "@/components/DoctorDetails.vue";
import NextBloodTest from "@/components/NextBloodTest.vue";
import YourHealthGoals from "@/components/YourHealthGoals.vue";
import BookConsultation from "@/components/BookConsultation.vue";
import HealthTrafficLights from "@/components/HealthTrafficLights.vue";

const colorMap = {
  rangeLow: "#ef4e4e",
  rangeLowNormal: "#ff9466",
  rangeNormal: "#3ebd93",
  rangeHighNormal: "#ff9466",
  rangeHigh: "#ef4e4e",
};

export default {
  setup() {
    const sortOption = ref("alphabetical");
    const patientStore = usePatientStore();
    const route = useRoute();
    const disclosureState = useDisclosureState();

    const selectedBloodTest = patientStore.getTestByBarcode(
      route.params.barcode
    );

    function setSortOption(event) {
      sortOption.value = event.target.value;
    }

    const panels = computed(() => {
      if (sortOption.value === "alphabetical") {
        return [...selectedBloodTest.result.panels].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
      return [...selectedBloodTest.result.panels].sort((a) =>
        getLowestRangeForPanel(a) === "rangeLow" ? -1 : 1
      );
    });

    const instance = selectedBloodTest.instance;

    function toggleListItems(item) {
      const list = disclosureState.openDisclosures.value;
      if (list.includes(item)) {
        return (disclosureState.openDisclosures.value = list.filter(
          (listItem) => listItem !== item
        ));
      }
      disclosureState.openDisclosures.value.push(item);
    }

    return {
      test: selectedBloodTest,
      panels,
      colorMap,
      getLowestRangeForPanel,
      setSortOption,
      patientStore,
      instance,
      disclosureState,
      toggleListItems,
    };
  },
  components: {
    AppDisclosure,
    MarkerResult,
    DoctorDetails,
    NextBloodTest,
    YourHealthGoals,
    HealthTrafficLights,
    BookConsultation,
  },
};
</script>
