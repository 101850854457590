<template>
  <div class="flex flex-col">
    <div class="bg-green-100 rounded-full p-2 inline-flex mx-auto">
      <AppIcon
        icon="check"
        class="fill-current text-green-600 m-auto h-8 w-8"
        :containerClasses="['flex']"
        :classes="['m-auto']"
      />
    </div>
    <h1 class="text-denim-700 text-2xl font-normal text-center mt-4">
      Your kit has been activated successfully
    </h1>
    <p class="mt-4 mb-0 text-grey-700">
      Your health questionnaire provides us with a detailed view of the whole of
      you. By providing us with as much detail as possible our Dr can provide a
      holistic report based on your blood test results and your lifestyle and
      medical history.
    </p>
    <KitProgress
      :kit="activation.kit"
      :test="activation.test"
      :collection-method="collectionMethod"
      class="my-8"
    />
    <router-link
      :to="{
        name: 'questionnaire-for-test',
        params: { barcode: activation.kit.barcode || 'test' },
      }"
      class="bg-cobalt-500 text-white rounded py-2 text-center"
    >
      <span class="text-white">Complete your health profile</span>
    </router-link>
  </div>
</template>

<script setup>
import KitProgress from "@/components/KitProgress.vue";
import { useActivationStore } from "@/stores/activation";
import { parseSku } from "@/services/kit";

const activation = useActivationStore();

console.log(activation.kit, activation.test);
const { collectionMethod } = parseSku(activation.kit.sku);
</script>
