<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      stroke-width="3"
      fill="currentColor"
    >
      <path
        stroke="currentColor"
        fill="transparent"
        stroke-width="500"
        d="M4520 5113 c-544 -88 -863 -170 -1265 -328 -352 -137 -826 -386 -995
-522 -105 -84 -193 -211 -234 -339 -9 -28 -49 -232 -90 -455 -57 -314 -71
-410 -63 -432 5 -17 32 -43 66 -66 31 -22 145 -128 253 -238 109 -109 320
-319 469 -468 149 -148 288 -296 310 -327 39 -57 69 -75 114 -69 109 14 805
146 849 161 128 44 256 134 334 235 136 175 365 613 507 965 164 408 319 1016
342 1345 22 329 -268 591 -597 538z"
      />

      <path
        stroke-width="3"
        d="M1501 2884 c-30 -8 -76 -26 -103 -41 -101 -55 -202 -198 -202 -286
-1 -36 18 -56 662 -699 646 -647 662 -663 700 -663 59 0 162 56 220 120 124
135 153 305 82 475 -20 48 -81 113 -508 542 -323 324 -501 495 -532 512 -98
53 -215 68 -319 40z"
      />
      <path
        stroke-width="3"
        d="M933 2365 c-164 -82 -261 -268 -232 -448 22 -134 10 -120 543 -655
424 -425 499 -497 551 -522 210 -102 466 -16 570 193 65 129 127 50 -628 804
-754 755 -675 693 -804 628z"
      />
      <path
        stroke-width="3"
        d="M725 1431 c-55 -35 -713 -704 -720 -733 -4 -15 -4 -35 -1 -45 3 -10
149 -161 324 -336 230 -229 324 -317 340 -317 13 0 35 6 50 14 46 25 720 708
727 738 4 15 4 35 1 45 -3 10 -149 161 -324 336 -340 339 -336 336 -397 298z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-spear",
};
</script>
