<template>
  <div
    class="mt-auto ml-auto mb-8 mr-8 z-10 text-right"
    :class="{ 'text-white': white }"
  >
    <div class="text-sm">
      <a
        v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'"
        href="https://support.livingdna.com/hc/en-us"
        target="_blank"
        rel="noopener"
        class="mr-5"
        :class="{ 'text-white': white }"
      >
        <span :class="{ 'text-white': white }">Help</span>
      </a>

      <a
        :href="
          VUE_APP_AUTH_PROVIDER === 'clerk'
            ? 'https://www.dnaworkplace.com/_files/ugd/4436fc_7a018adc05f642a28835165428569ee8.pdf'
            : 'https://livingdna.com/uk/legal/Terms-Of-Use'
        "
        target="_blank"
        rel="noopener"
        class="mr-5"
        :class="{ 'text-white': white }"
      >
        <span :class="{ 'text-white': white }">Terms</span>
      </a>

      <a
        :href="
          VUE_APP_AUTH_PROVIDER === 'clerk'
            ? 'https://www.dnaworkplace.com/_files/ugd/4410ce_0ff4aa12bf684c6fb0457d692548cd66.pdf'
            : 'https://livingdna.com/uk/privacy-centre'
        "
        target="_blank"
        rel="noopener"
        :class="{
          'text-white': white,
          'mr-5': isLoggedIn,
        }"
      >
        <span
          :class="{
            'text-white': white,
            'mr-5': isLoggedIn,
          }"
        >
          Privacy
        </span>
      </a>

      <button
        v-if="isLoggedIn"
        :class="white ? 'text-white' : 'text-cobalt-500'"
        @click.prevent="onLogout"
      >
        Sign out
      </button>
    </div>

    <div class="text-xs mt-4 md:text-right pb-4 md:pb-0">
      <span v-if="VUE_APP_AUTH_PROVIDER === 'clerk'">
        &copy; 2020-{{ new Date().getFullYear() }} DNA Workplace Ltd
      </span>
      <span v-else>&copy; 2019-{{ new Date().getFullYear() }} Living DNA</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useAccountStore, useAuth } from "@/stores/account";
const { VUE_APP_AUTH_PROVIDER } = process.env;

defineProps({
  white: {
    type: Boolean,
    default: false,
  },
});
const auth = useAuth();
const accountStore = useAccountStore();

function onLogout() {
  accountStore.logout();
  auth.logout({ redirect: { name: "login" } });
}

const isLoggedIn = computed(() => {
  return auth.check();
});
</script>
