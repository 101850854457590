<template>
  <div class="space-y-4">
    <template v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'">
      <h1 class="font-normal text-2xl">Confirm your password</h1>
      <p>Reenter your password to confirm deletion.</p>
      <PasswordInput v-model="password" new-password :show-label="false" />
    </template>

    <template v-else>
      <h1 class="font-normal text-2xl">Confirm your deletion</h1>
      <p>Click the button below to confirm deletion.</p>
    </template>

    <p v-if="errorMessage" class="text-sm text-rose-500 mb-0 mt-4">
      {{ errorMessage }}
    </p>

    <SubmitButton
      @click="attemptDeleteProfile"
      class="bg-rose-500 hover:bg-rose-500 w-full"
      icon="trash-2"
      :loading="saveStatus === 'saving'"
    >
      Confirm deletion
    </SubmitButton>
    <router-link
      :to="{ name: 'health-profile' }"
      class="text-center text-grey-500 font-semibold mt-4 block"
    >
      Cancel
    </router-link>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { checkPassword } from "@/stores/profile";
import PasswordInput from "@/components/PasswordInput.vue";
import SubmitButton from "@/components/SubmitButton.vue";
import { useAuth } from "@/stores/account";
import { makeHealthRequest } from "@/services/healthBackend";
import { useSaving } from "@/stores/loading";
import { useRouter } from "vue-router";

const { VUE_APP_AUTH_PROVIDER } = process.env;

const router = useRouter();

const auth = useAuth();
const password = ref("");
const errorMessage = ref("");
const { saveStatus, startSaving, finishSaving } = useSaving();

async function attemptDeleteProfile() {
  startSaving();
  errorMessage.value = "";
  try {
    if (VUE_APP_AUTH_PROVIDER !== "clerk") {
      await checkPassword(auth.user().email, password.value);
    }

    try {
      await makeHealthRequest({
        path: `/patients/${auth.user().uuid}`,
        method: "DELETE",
      });
      auth.logout();
    } catch (e) {
      console.error(e);
      errorMessage.value =
        "You entered the correct password but your account failed to delete. Please contact support for assistance";
    }
  } catch (e) {
    console.error(e);
    errorMessage.value = "The password you entered was incorrect";
  }
  finishSaving();
}
</script>
