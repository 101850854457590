/* eslint-disable */
import findOutMore from "@/static/additionalInfo.json";

export function getMarkerDetails(markerCode: string) {
  return findOutMore.find(
    (f: { Code: string }) => {
      if (f.Code) {
        /** The lab refers to the markers by multiple codes. To handle this we
         * actually store the marker codes on our side as an array, unless
         * there is only one, in which case it is a string. To compare we
         * normalise to an array of codes and then do the check */
        const normalisedCode = f.Code.includes('[') ? JSON.parse(f.Code) : [f.Code]

        return normalisedCode.map((c : string) => c.toLowerCase()).includes(markerCode.toLowerCase());
      }
      return false
    }
  );
}
