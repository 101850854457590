<template>
  <div :class="containerClasses">
    <component v-if="isCustom" :is="icon" :class="classes" />

    <VueFeather v-else :type="icon" :class="classes" />
  </div>
</template>

<script>
import VueFeather from "vue-feather";
import FacebookOfficial from "./svg/facebook-official.vue";
import Female from "./svg/female.vue";
import GoogleOfficial from "./svg/google-official.vue";
import Helix from "./svg/helix.vue";
import Male from "./svg/male.vue";
import TShirt from "./svg/t-shirt.vue";
import VikingShield from "./svg/viking-shield.vue";
import Spear from "./svg/spear.vue";

export default {
  name: "AppIcon",

  components: {
    FacebookOfficial,
    Female,
    GoogleOfficial,
    Helix,
    Male,
    TShirt,
    VikingShield,
    Spear,
    VueFeather,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    classes: {
      type: Array,
      default: () => ["w-5 h-5 fill-current"],
    },
    containerClasses: {
      type: Array,
      default: () => ["w-5 h-5"],
    },
  },

  data: () => ({
    customIcons: [
      "facebook-official",
      "female",
      "google-official",
      "helix",
      "male",
      "t-shirt",
      "viking-shield",
      "spear",
    ],
  }),

  computed: {
    isCustom() {
      return this.customIcons.some((icon) => icon === this.icon);
    },
  },
};
</script>
