const _ = require("lodash");

function createStagesForMarker(result, resultValue) {
  const stages = [];
  if (result.l_bound !== 0) {
    stages.push({
      name: "rangeLow",
      lowest:
        result.l_bound * 0.7 > resultValue ? resultValue : result.l_bound * 0.7,
      highest: result.l_bound,
    });
  }

  if (result.reduced_bound) {
    stages.push({
      name: "rangeLowNormal",
      lowest: result.l_bound,
      highest: result.reduced_bound,
    });
  }

  stages.push({
    name: "rangeNormal",
    lowest: result.reduced_bound || result.l_bound,
    // if there is no upper bound (i.e. range is > 90) then we just want the result to sit in the middle of the green section
    highest: result.elevated_bound
      ? result.elevated_bound
      : result.u_bound
      ? result.u_bound
      : resultValue * 2 - result.l_bound,
  });

  if (result.elevated_bound) {
    stages.push({
      name: "rangeHighNormal",
      lowest: result.elevated_bound,
      // if there is no upper bound (i.e. range is > 90) then we just want the result to sit in the middle of the green section
      highest: result.u_bound
        ? result.u_bound
        : resultValue * 2 - result.l_bound,
    });
  }

  if (result.u_bound) {
    stages.push({
      name: "rangeHigh",
      lowest: result.u_bound,
      highest:
        resultValue < result.u_bound ? result.u_bound * 1.3 : resultValue * 1.3,
    });
  }

  return stages;
}

const interpretationDict = {
  within: "rangeNormal",
  elevated: "rangeHighNormal",
  reduced: "rangeLowNormal",
  above: "rangeHigh",
  below: "rangeLow",
};

function getResultStage(stages, result) {
  const stageName = interpretationDict[result.interpretation];
  return stages.find((stage) => stage.name === stageName);
}

function transformMarker(result) {
  const resultValue = Number(result.result);
  // if (result.marker === "Folate (serum)") {
  //   console.log("======THIS ONE HERE========");
  // }
  // console.log({ result });

  const data = {
    code: result.code,
    panel: result.panel,
    name: result.marker,
    resultUnit: result.units,
    resultDescription: result.interpretation_text,
    result: resultValue,
    resultRange: result.result_range,
    range: {
      lowestPossible:
        result.l_bound * 0.7 > resultValue ? resultValue : result.l_bound * 0.7,
      highestPossible:
        result.u_bound === null
          ? resultValue * 2 - result.l_bound
          : resultValue < result.u_bound
          ? result.u_bound * 1.3
          : resultValue * 1.3,
      stages: createStagesForMarker(result, resultValue),
    },
    resultRangeStage: getResultStage(createStagesForMarker(result), result),
  };
  return data;
}

export function mapResult(data) {
  if (!data) {
    return false;
  }
  const [meta, markers] = data;

  const transformedData = markers.map(transformMarker);
  // .filter((result) => !Number.isNaN(result.result));
  // .filter((result) => result.resultRangeStage);
  // .filter((result) => result.name === "Folate (serum)");

  const groupedByMarker = _.groupBy(transformedData, "panel");

  const decoratedGroups = [];

  for (let panel in groupedByMarker) {
    decoratedGroups.push({
      name: panel,
      markers: groupedByMarker[panel],
    });
  }

  return {
    id: meta.labtest_id,
    date: meta.issue_time,
    nextTestDate: meta.next_test_date,
    doctor: {
      name: meta.doctor,
      photo:
        "https://t4.ftcdn.net/jpg/02/60/04/09/360_F_260040900_oO6YW1sHTnKxby4GcjCvtypUCWjnQRg5.jpg",
    },
    medicalReport: meta.doctor_letter,
    medicalReportSummary: meta.doctor_summary,
    panels: decoratedGroups,
    testFailed: !!meta.test_failed,
  };
}

export function getResult(results) {
  return results.map(mapResult);
}
