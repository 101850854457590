<template>
  <div>
    <h3 class="text-denim-700 text-2xl font-normal text-center">Sign up</h3>
    <form @submit.prevent="attemptRegister(account)" class="mt-8 space-y-4">
      <div class="space-y-1">
        <BaseTextInput
          type="text"
          id="first-name"
          name="first-name"
          v-model="account.firstName"
          label="First Name"
        />
        <BaseFieldErrorMessage>
          {{ filterErrors(requestErrors, "firstName", "detail") }}
        </BaseFieldErrorMessage>
      </div>

      <div class="space-y-1">
        <BaseTextInput
          type="text"
          id="last-name"
          name="last-name"
          v-model="account.lastName"
          label="Last Name"
        />
        <BaseFieldErrorMessage>
          {{ filterErrors(requestErrors, "lastName", "detail") }}
        </BaseFieldErrorMessage>
      </div>

      <div class="space-y-1">
        <BaseTextInput
          type="email"
          id="email"
          name="email"
          v-model="account.emailAddress"
          label="Email"
        />
        <BaseFieldErrorMessage>
          {{ filterErrors(requestErrors, "email", "detail") }}
        </BaseFieldErrorMessage>
      </div>

      <div class="space-y-1">
        <label class="font-semibold block w-full text-sm //mb-1">
          Country of residence
        </label>
        <v-select v-model="account.country" label="name" :options="countries" />
        <BaseFieldErrorMessage>
          {{ filterErrors(requestErrors, "country", "detail") }}
        </BaseFieldErrorMessage>
      </div>

      <div class="space-y-1">
        <PasswordInput
          class="mt-2"
          v-model="account.password"
          :new-password="true"
          :error-text="passwordError ? passwordError.detail : ''"
          @blur="validatePassword"
          @input="updatePasswordError"
        />
        <!-- Don't need an error component here because the input handles its own errors -->
      </div>

      <div class="mt-4">
        <div class="flex items-center">
          <input
            type="checkbox"
            id="accept-terms"
            v-model="account.acceptTerms"
            class="form-checkbox rounded w-6 h-6 text-cobalt-500 mr-3 border-grey-500"
          />
          <label for="accept-terms" class="cursor-pointer text-xs">
            I agree to the
            <a
              :href="termsLinks.terms"
              target="_blank"
              rel="noopener"
              class="font-semibold text-cobalt-500"
            >
              Terms of Service
            </a>
            and
            <a
              :href="termsLinks.privacy"
              target="_blank"
              rel="noopener"
              class="font-semibold text-cobalt-500"
            >
              Privacy Policy
            </a>
          </label>
        </div>
        <BaseFieldErrorMessage class="mt-1">
          {{ filterErrors(requestErrors, "Create account", "detail") }}
        </BaseFieldErrorMessage>
        <div
          v-if="VUE_APP_AUTH_PROVIDER !== 'clerk'"
          class="flex items-center mt-2"
        >
          <input
            type="checkbox"
            id="accept-marketing"
            v-model="account.acceptMarketing"
            class="form-checkbox rounded w-6 h-6 text-cobalt-500 mr-3 border-grey-500"
          />
          <label for="accept-marketing" class="cursor-pointer text-xs">
            Email me with Living DNA news and promotions
          </label>
        </div>
      </div>
      <div
        v-if="errorText"
        class="bg-rose-50 text-rose-500 text-sm rounded p-4 mt-4"
      >
        {{ errorText }}
      </div>
      <div class="mt-4">
        <button
          :class="{
            loading: loading,
            'bg-grey-200 text-grey-500': !readyToSubmit,
            'bg-cobalt-500 text-white': readyToSubmit,
          }"
          :disabled="!readyToSubmit"
          class="rounded w-full py-2 px-4 font-semibold"
        >
          Create account
        </button>
      </div>
      <div class="mt-6 text-center">
        Already have an account?
        <router-link class="text-cobalt-500 font-semibold" to="/signin">
          Log in
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import PasswordInput from "@/components/PasswordInput.vue";
import VSelect from "vue-select";
import countries from "@/stores/countries";
import { useAccountStore } from "@/stores/account";
import { termsLinks } from "@/services/static";
import { useAuth } from "@/stores/account";

import BaseFieldErrorMessage from "@/components/BaseFieldErrorMessage.vue";

const { VUE_APP_AUTH_PROVIDER } = process.env;

export default {
  components: {
    PasswordInput,
    VSelect,
    BaseFieldErrorMessage,
  },
  data() {
    return {
      account: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        country: {},
        password: "",
        acceptTerms: false,
        acceptMarketing: false,
      },
      // account: {
      //   firstName: "George",
      //   lastName: "Hunter",
      //   emailAddress: "georgeh@livingdna.com",
      //   country: {},
      //   password: "32497349832749832748932dafgauyifgy&^&*",
      //   acceptTerms: false,
      //   acceptMarketing: false,
      // },

      passwordError: { detail: "" },
      countries,
      verificationCode: "",
      termsLinks,
    };
  },
  setup() {
    const auth = useAuth();
    const loading = ref(false);
    const errorText = ref("");
    const account = useAccountStore();
    const router = useRouter();
    const requestErrors = ref([]);

    async function attemptRegister(accountData) {
      loading.value = true;
      errorText.value = "";
      try {
        await account.registerAccount(accountData, auth);
        router.push({
          name:
            VUE_APP_AUTH_PROVIDER === "clerk"
              ? "signup-success-clerk"
              : "signup-success",
        });
      } catch (error) {
        if (error.errors) {
          errorText.value = error.errors[0].message;
        }
        console.error(error);
        console.error(error?.response?.data?.errors);
        requestErrors.value = error?.response?.data?.errors;
      } finally {
        loading.value = false;
      }
    }

    return {
      attemptRegister,
      loading,
      errorText,
      VUE_APP_AUTH_PROVIDER,
      requestErrors,
    };
  },
  methods: {
    filterErrors(errors, field, attribute) {
      const error = errors.find((error) => error.source === field);
      if (error && attribute) {
        return error[attribute];
      }
      return error;
    },

    validatePassword() {
      if (this.passwordError && this.account.password.length < 8) {
        this.passwordError.detail =
          "This password is too short. It must contain at least 8 characters.";
      }
    },
    updatePasswordError() {
      if (
        this.passwordError &&
        this.passwordError.detail &&
        this.account.password.length >= 8
      ) {
        this.passwordError.detail = "";
      }
    },
  },
  watch: {
    requestErrors() {
      this.passwordError = this.requestErrors.find(
        (err) => err.source === "first"
      );
    },
  },
  computed: {
    readyToSubmit() {
      return (
        true ||
        (this.account.lastName &&
          this.account.emailAddress &&
          this.account.country &&
          this.account.acceptTerms &&
          this.account.password.length >= 8)
      );
    },
  },
};
</script>
