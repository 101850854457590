<template>
  <div class="flex flex-col">
    <div class="bg-green-100 rounded-full p-2 inline-flex mx-auto">
      <AppIcon
        icon="check"
        class="fill-current text-green-600 m-auto h-8 w-8"
        :containerClasses="['flex']"
        :classes="['m-auto']"
      />
    </div>
    <h1 class="text-denim-700 text-2xl font-normal text-center mt-4">
      Your appointment details are confirmed
    </h1>
    <p class="mt-4 mb-4 text-grey-700">
      Thanks for confirming your details, a health professional with be in touch
      soon to arrange your appointment
    </p>
    <router-link
      :to="{ name: 'dashboard' }"
      class="bg-cobalt-500 text-white rounded py-2 text-center"
    >
      <span class="text-white">Visit your dashboard</span>
    </router-link>
  </div>
</template>

<script setup></script>
