<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    stroke="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <defs>
      <path
        d="M20.4651163,10.3636364 L12.2015504,10.3636364 L12.2015504,13.8409091 L16.9581395,13.8409091 C16.5147287,16.05 14.6604651,17.3181818 12.2015504,17.3181818 C9.29922481,17.3181818 6.96124031,14.9454545 6.96124031,12 C6.96124031,9.05454545 9.29922481,6.68181818 12.2015504,6.68181818 C13.4511628,6.68181818 14.579845,7.13181818 15.4666667,7.86818182 L18.0465116,5.25 C16.4744186,3.85909091 14.4589147,3 12.2015504,3 C7.28372093,3 3.33333333,7.00909091 3.33333333,12 C3.33333333,16.9909091 7.28372093,21 12.2015504,21 C16.6356589,21 20.6666667,17.7272727 20.6666667,12 C20.6666667,11.4681818 20.5860465,10.8954545 20.4651163,10.3636364 Z"
        id="path-google-1"
      ></path>
      <path
        d="M20.4651163,10.3636364 L12.2015504,10.3636364 L12.2015504,13.8409091 L16.9581395,13.8409091 C16.5147287,16.05 14.6604651,17.3181818 12.2015504,17.3181818 C9.29922481,17.3181818 6.96124031,14.9454545 6.96124031,12 C6.96124031,9.05454545 9.29922481,6.68181818 12.2015504,6.68181818 C13.4511628,6.68181818 14.579845,7.13181818 15.4666667,7.86818182 L18.0465116,5.25 C16.4744186,3.85909091 14.4589147,3 12.2015504,3 C7.28372093,3 3.33333333,7.00909091 3.33333333,12 C3.33333333,16.9909091 7.28372093,21 12.2015504,21 C16.6356589,21 20.6666667,17.7272727 20.6666667,12 C20.6666667,11.4681818 20.5860465,10.8954545 20.4651163,10.3636364 Z"
        id="path-google-3"
      ></path>
      <path
        d="M20.4651163,10.3636364 L12.2015504,10.3636364 L12.2015504,13.8409091 L16.9581395,13.8409091 C16.5147287,16.05 14.6604651,17.3181818 12.2015504,17.3181818 C9.29922481,17.3181818 6.96124031,14.9454545 6.96124031,12 C6.96124031,9.05454545 9.29922481,6.68181818 12.2015504,6.68181818 C13.4511628,6.68181818 14.579845,7.13181818 15.4666667,7.86818182 L18.0465116,5.25 C16.4744186,3.85909091 14.4589147,3 12.2015504,3 C7.28372093,3 3.33333333,7.00909091 3.33333333,12 C3.33333333,16.9909091 7.28372093,21 12.2015504,21 C16.6356589,21 20.6666667,17.7272727 20.6666667,12 C20.6666667,11.4681818 20.5860465,10.8954545 20.4651163,10.3636364 Z"
        id="path-google-5"
      ></path>
      <path
        d="M20.4651163,10.3636364 L12.2015504,10.3636364 L12.2015504,13.8409091 L16.9581395,13.8409091 C16.5147287,16.05 14.6604651,17.3181818 12.2015504,17.3181818 C9.29922481,17.3181818 6.96124031,14.9454545 6.96124031,12 C6.96124031,9.05454545 9.29922481,6.68181818 12.2015504,6.68181818 C13.4511628,6.68181818 14.579845,7.13181818 15.4666667,7.86818182 L18.0465116,5.25 C16.4744186,3.85909091 14.4589147,3 12.2015504,3 C7.28372093,3 3.33333333,7.00909091 3.33333333,12 C3.33333333,16.9909091 7.28372093,21 12.2015504,21 C16.6356589,21 20.6666667,17.7272727 20.6666667,12 C20.6666667,11.4681818 20.5860465,10.8954545 20.4651163,10.3636364 Z"
        id="path-google-7"
      ></path>
    </defs>
    <g fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-google-1"></use>
      </mask>
      <g id="a"></g>
      <polygon
        id="Path"
        fill="#FBBC05"
        fill-rule="nonzero"
        mask="url(#mask-2)"
        points="2.05710472 18.1486174 2 6 10.004253 11.3837467"
      ></polygon>
      <mask id="mask-4" fill="white">
        <use xlink:href="#path-google-3"></use>
      </mask>
      <g id="a"></g>
      <polygon
        id="Path"
        fill="#EA4335"
        fill-rule="nonzero"
        mask="url(#mask-4)"
        points="2 6.125 9.08333333 11 12 8.7125 22 7.25 22 2 2 2"
      ></polygon>
      <mask id="mask-6" fill="white">
        <use xlink:href="#path-google-5"></use>
      </mask>
      <g id="a"></g>
      <polygon
        id="Path"
        fill="#34A853"
        fill-rule="nonzero"
        mask="url(#mask-6)"
        points="2 17.4166667 14.5 7.83333333 17.7916667 8.25 22 2 22 22 2 22"
      ></polygon>
      <mask id="mask-8" fill="white">
        <use xlink:href="#path-google-7"></use>
      </mask>
      <g id="a"></g>
      <polygon
        id="Path"
        fill="#4285F4"
        fill-rule="nonzero"
        mask="url(#mask-8)"
        points="22 22 9.00952381 11.6216216 7.33333333 10.3243243 22 6"
      ></polygon>
    </g>
  </svg>
</template>

<script>
export default {
  name: "GoogleOfficial",
};
</script>
