<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="$emit('modal-closed')"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          >
            <div class="bg-white px-4 py-5 sm:px-6">
              <div
                class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap mb-4"
              >
                <div class="ml-4 mt-4">
                  <h3 class="text-lg leading-6 font-medium text-grey-700">
                    Change Profile
                  </h3>
                  <p
                    v-if="profileStore.profiles.length > 1"
                    class="mt-1 text-sm text-grey-600"
                  >
                    Select a profile below to switch
                  </p>
                </div>
                <div v-if="false" class="ml-4 mt-4 flex-shrink-0">
                  <button
                    @click="goToProfiles"
                    type="button"
                    class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cobalt-500 hover:bg-cobalt-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cobalt-500"
                  >
                    <span class="text-white">Manage profiles</span>
                  </button>
                </div>
              </div>
              <ProfileSwitcherModalList
                @profile-changed="$emit('modal-closed')"
              />
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { useRouter } from "vue-router";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import ProfileSwitcherModalList from "./ProfileSwitcherModalList.vue";
import { useProfileStore } from "@/stores/profile";

export default {
  props: {
    open: Boolean,
  },
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ProfileSwitcherModalList,
  },
  setup(_, { emit }) {
    const router = useRouter();
    const profileStore = useProfileStore();
    function goToProfiles() {
      emit("modal-closed");
      router.push({ name: "profiles" });
    }
    return { goToProfiles, profileStore };
  },
};
</script>
