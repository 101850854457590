<template>
  <div>
    <h2 class="flex items-center text-lg">
      <AppIcon icon="heart" class="mr-2" />

      Health Details
    </h2>

    <div
      class="w-full mt-6 shadow-key rounded-lg flex-1 p-6 flex"
      style="border-spacing: 0"
    >
      <router-link
        :to="{ name: 'questionnaire' }"
        class="font-semibold text-cobalt-500 w-full flex justify-between items-center"
      >
        <span>Click here to update your health details</span>
        <AppIcon icon="chevron-right" />
      </router-link>
    </div>
  </div>
</template>
