<template>
  <div v-if="Number.isNaN(marker.result) || !marker.resultRangeStage">
    <div class="mb-6">
      <h2 class="font-semibold text-lg flex items-center mb-2">
        <div class="mr-2">
          <MarkerIcon :marker="marker" />
        </div>
        {{ marker.name }}
      </h2>
      <p>
        {{ markerDetails.all_about_body }}
      </p>
    </div>

    <div class="bg-white rounded-lg shadow p-6">
      <div v-if="marker.resultDescription" v-html="marker.resultDescription" />
      <div v-else>Unfortunately testing of this biomarker failed</div>
    </div>
  </div>
  <TabGroup v-else as="div">
    <div class="mb-6">
      <h2 class="font-semibold text-lg flex items-center mb-2 space-x-2">
        <div>
          <MarkerIcon :marker="marker" />
        </div>
        <span>
          {{ marker.name }}
        </span>
      </h2>
      <div v-html="markerDetails.all_about_body" />
      <router-link
        :to="{
          name: 'marker-find-out-more',
          params: { marker: marker.code.toLowerCase() },
        }"
        class="text-cobalt-500 rounded mt-2 font-semibold flex items-center"
      >
        Find out more
        <AppIcon icon="arrow-right" class="ml-2" />
      </router-link>
    </div>
    <div class="flex mb-2">
      <nav class="inline-flex ml-auto space-x-4" aria-label="Tabs">
        <TabList>
          <Tab v-slot="{ selected }" v-for="tab in tabs" :key="tab.name">
            <button
              :class="[
                selected
                  ? 'bg-cobalt-50 text-cobalt-500'
                  : 'text-gray-500 hover:text-gray-700',
                'px-3 py-2 font-semibold text-sm rounded-md',
              ]"
              :aria-current="tab.current ? 'page' : undefined"
            >
              {{ tab.name }}
            </button>
          </Tab>
        </TabList>
      </nav>
    </div>

    <div class="bg-white rounded-lg shadow p-6">
      <TabPanels>
        <div class="flex mt-6 space-x-8">
          <div class="w-full">
            <TabPanel>
              <div class="md:flex md:space-x-6">
                <div class="md:w-1/2">
                  <div class="pt-6">
                    <MarkerResultPill
                      :result-percentage="resultAsPecentage()"
                      :color-map="colorMap"
                      :stage-width="stageWidth"
                      :marker="marker"
                    />

                    <MarkerResultRange
                      class="mt-8"
                      :background-color="colorMap[marker.resultRangeStage.name]"
                      :marker="marker"
                    />
                  </div>
                </div>
                <div class="md:w-1/2">
                  <div class="content" v-html="marker.resultDescription" />
                  <!-- <router-link
                    :to="{
                      name: 'marker-find-out-more',
                      params: { marker: marker.code.toLowerCase() },
                    }"
                    class="text-cobalt-500 rounded mt-4 font-semibold flex items-center"
                  >
                    Find out more
                    <AppIcon icon="arrow-right" class="ml-2" />
                  </router-link> -->
                </div>
              </div>
            </TabPanel>
            <TabPanel class="h-full">
              <div class="w-full" v-if="trend.length > 1">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 100 33"
                  preserveAspectRatio="none"
                >
                  <g
                    v-for="(stage, index) in stagesForVerticalDisplay()"
                    :key="stage.name"
                  >
                    <rect
                      :height="`${stageWidth(stage, marker) * 0.33}`"
                      x="5"
                      :y="stage.startingPlace * 0.33 - 3"
                      width="1"
                      :style="`fill: ${colorMap[stage.name]}`"
                      :class="`${index === 0 ? '//rounded-l-full' : ''} ${
                        index === marker.range.stages.length - 1
                          ? '//rounded-r-full'
                          : ''
                      }`"
                    ></rect>
                    <rect
                      :height="`${stageWidth(stage, marker) * 0.33}`"
                      x="5"
                      :y="stage.startingPlace * 0.33 - 3"
                      width="100"
                      :style="`fill: ${colorMap[stage.name]}`"
                      :class="`${index === 0 ? '//rounded-l-full' : ''} ${
                        index === marker.range.stages.length - 1
                          ? '//rounded-r-full'
                          : ''
                      }`"
                      class="opacity-10"
                    ></rect>
                    <path
                      v-if="index !== stagesForVerticalDisplay().length - 1"
                      :d="`M5 ${stage.startingPlace * 0.33 - 3} L100 ${
                        stage.startingPlace * 0.33 - 3
                      }`"
                      :stroke="colorMap[stagesForVerticalDisplay()[index].name]"
                      stroke-width=".1"
                      class="opacity-50"
                      fill="none"
                    />
                  </g>
                  <text
                    text-anchor="left"
                    v-for="stage in stagesForVerticalDisplay()"
                    :key="stage.name"
                    :x="0"
                    :y="stage.startingPlace * 0.33 - 2.5"
                    class="text-sm"
                    style="font-size: 1.6px"
                  >
                    {{ stage.highest }}
                  </text>
                  <path
                    :d="createPathForResults()"
                    class="stroke-current text-grey-700 opacity-50"
                    stroke-width=".2"
                    fill="none"
                  />
                  <!-- <rect x="40%" y="40%" width="25%" height="25%" /> -->
                  <circle
                    :data-test="value.stageName"
                    :fill="colorMap[value.stageName]"
                    v-for="(value, index) in trend"
                    :key="index"
                    :cx="(index + 1) * 20"
                    :cy="(100 - resultAsPecentage(value.result)) * 0.33 - 3"
                    r="1"
                  />
                  <text
                    text-anchor="middle"
                    v-for="(value, index) in trend"
                    :key="index"
                    :x="(index + 1) * 20"
                    y="33"
                    class="text-sm"
                    style="font-size: 1.4px"
                  >
                    {{
                      new Date(value.date).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })
                    }}
                  </text>
                </svg>
              </div>
              <div v-else>
                <p class="mb-2">
                  Regular blood testing means you can witness the changes to
                  your body that you cannot physically see.
                </p>

                <p class="mb-2">
                  Whilst you may feel lighter, brighter and be sleeping better
                  testing every 3 months will show you the improvements you're
                  making on the inside.
                </p>

                <p class="mb-2">
                  You can also view your trends over time, the more tests you
                  have the better the picture will be of your over all health
                  and improvements.
                </p>
              </div>
            </TabPanel>
          </div>
        </div>
      </TabPanels>
    </div>
  </TabGroup>
</template>

<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { useResultsStore } from "@/stores/results";
import MarkerResultPill from "./MarkerResultPill.vue";
import MarkerResultRange from "./MarkerResultRange.vue";
import MarkerIcon from "./MarkerIcon.vue";
import { getMarkerDetails } from "@/stores/marker";
import { useDisclosureState } from "@/composables/useDisclosureState";

const discloseState = useDisclosureState();

console.log({ discloseState });

const props = defineProps({
  marker: Object,
  selectedBloodTest: Object,
});

const resultsStore = useResultsStore();

const trend = props.marker.resultRangeStage
  ? getMarkerResultsOverTime(props.marker)
  : [];

function stageWidth(stage, marker) {
  return Math.ceil(
    ((stage.highest - stage.lowest) /
      (marker.range.highestPossible - marker.range.lowestPossible)) *
      100
  );
}

function resultAsPecentage(result) {
  const value = result || props.marker.result;
  // (value - lower bound)/(upper bound - lower bound)
  return (
    ((value - props.marker.range.lowestPossible) /
      (props.marker.range.highestPossible -
        props.marker.range.lowestPossible)) *
    100
  );
}

function stagesForVerticalDisplay() {
  return props.marker.range.stages.map((stage) => {
    return {
      ...stage,
      startingPlace: 100 - resultAsPecentage(stage.highest),
    };
  });
}

function createPathForResults() {
  return trend.map((result, index) => {
    return `${index === 0 ? "M" : "L"}${(index + 1) * 20} ${
      (100 - resultAsPecentage(result.result)) * 0.33 - 3
    }`;
  });
}

const tabs = [
  {
    name: "Result",
  },
  {
    name: "Trend",
    current: false,
  },
];

const colorMap = {
  rangeLow: "#ef4e4e",
  rangeLowNormal: "#ff9466",
  rangeNormal: "#3ebd93",
  rangeHighNormal: "#ff9466",
  rangeHigh: "#ef4e4e",
};

function getMarkerResultsOverTime(targetMarker) {
  return [...resultsStore.bloodResults]
    .sort((a, b) => {
      const date1 = Date.parse(a.date);
      const date2 = Date.parse(b.date);
      if (date1 > date2) {
        return 1;
      } else if (date1 < date2) {
        return -1;
      } else {
        return 0;
      }
    })
    .flatMap((result) =>
      props.selectedBloodTest.result.panels.flatMap((panel) =>
        panel.markers
          .filter((marker) => marker.name === targetMarker.name)
          .map((marker) => ({
            date: props.selectedBloodTest.result.date,
            result: marker.result,
            stageName: marker.resultRangeStage.name,
          }))
      )
    );
}

const markerDetails = getMarkerDetails(props.marker.code);
</script>
