import AccountView from "@/views/Account/AccountView.vue";
// import Account from '@/views/account/Account.vue';
import AccountEdit from "@/views/Account/AccountEdit.vue";
import AccountName from "@/views/Account/Fields/AccountName.vue";
import DeleteAccount from "@/views/Account/Delete/DeleteAccount.vue";
import StartDeletion from "@/views/Account/Delete/StartDeletion.vue";
import RequestDeletion from "@/views/Account/Delete/RequestDeletion.vue";
import ConfirmDeletion from "@/views/Account/Delete/ConfirmDeletion.vue";
// import ReceivedDeletion from "@/views/account/Delete/ReceivedDeletion.vue";
import AccountEmail from "@/views/Account/Fields/AccountEmail.vue";
import AccountPassword from "@/views/Account/Fields/AccountPassword.vue";

export default [
  {
    path: "/Account",
    name: "account-view",
    component: AccountView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/account/edit",
    component: AccountEdit,
    children: [
      {
        path: "name",
        name: "account-name",
        component: AccountName,
      },
      {
        path: "/account/email",
        name: "account-email",
        component: AccountEmail,
        meta: {
          auth: true,
        },
      },
      {
        path: "/account/password",
        name: "account-password",
        component: AccountPassword,
        meta: {
          auth: true,
        },
      },
    ],
  },

  {
    path: "delete",
    component: DeleteAccount,
    redirect: "delete/start",
    children: [
      {
        path: "start",
        component: StartDeletion,
        name: "start-account-deletion",
      },
      {
        path: "request",
        component: RequestDeletion,
        name: "request-account-deletion",
      },
      {
        path: "confirm",
        component: ConfirmDeletion,
        name: "confirm-account-deletion",
      },
    ],
  },
];
