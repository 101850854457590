<template>
  <div class="flex items-center">
    <div class="flex-none flex items-center text-sm" style="width: 230px">
      <img class="h-5 w-5 mr-2" :src="result.trait_icon[0].url" alt="" />
      {{ result.trait_name }}
    </div>

    <div class="//w-1/2 text-sm pl-4" v-html="result.short_text" />

    <!-- I removed a  && !allLocked from the v-if below so the lock would always display -->
    <router-link
      v-if="
        result.result_value === 'Locked' &&
        patientStore.patient.geneticTest &&
        patientStore.patient.geneticTest.hasAnyResult
      "
      :to="{
        name: 'store-details',
        params: {
          product: result.trait_subcategory[0].category[0].key_product[0]._id,
        },
        query: {
          return: $route.params.return,
        },
      }"
      class="bg-cobalt-500 text-white hover:bg-cobalt-700 px-3 py-2 rounded font-semibold inline-block shrink-0 ml-auto text-sm"
    >
      <AppIcon icon="unlock" />
    </router-link>
  </div>
</template>

<script setup>
import { usePatientStore } from "@/stores/patient";

const patientStore = usePatientStore();

defineProps({
  result: Object,
  allLocked: Boolean,
});
</script>
