<template>
  <div class="text-center">
    <h3 class="text-denim-700 text-2xl font-normal">Check your inbox</h3>
    <p class="mt-4 leading-normal">
      We've just sent an email to
      <span class="underline">{{ email }}</span>
      .
      <br />
      Follow the link in the email to complete your password reset.
    </p>
    <div v-if="showSuccess" class="bg-emerald-50 py-2 px-4 text-emerald-500">
      Reset email resent
    </div>
    <div v-if="responseErrors" class="bg-rose-50 py-2 px-4 text-rose-500">
      {{ responseErrors }}
    </div>
    <div class="mt-24">
      <p class="mb-2">
        Problems receiving email?
        <button
          @click="resendResetEmail"
          class="text-cobalt-500 font-semibold"
          :class="{ 'text-grey-500': loading }"
          :disabled="loading"
        >
          Resend
        </button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: this.$route.query.email,
      loading: false,
      showSuccess: false,
      responseErrors: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    return to.query.email ? next() : next({ name: "reset-password" });
  },
  methods: {
    resendResetEmail() {
      this.loading = true;
      this.$store
        .dispatch("resetPassword", {
          email: this.email,
        })
        .then(() => {
          this.loading = false;
          this.showSuccess = true;
        })
        .catch((err) => {
          this.loading = false;
          this.responseErrors = JSON.parse(err.bodyText).errors[0].detail;
        });
    },
  },
};
</script>
