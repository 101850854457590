<template>
  <div>
    <div :class="removePadding ? '' : 'rounded bg-white p-6 shadow //grow'">
      <slot />
      <AppButton
        @click="showModal = true"
        class="mt-auto px-4"
        :class="{ 'w-full': fullWidth }"
      >
        Book a consultation
      </AppButton>
    </div>
    <AppModal
      :open="showModal"
      width="2xl"
      @closed="showModal = false"
      remove-padding
    >
      <div class="fade-bottom h-64">
        <AppImage
          public-id="AD1I7063"
          width="600"
          class="h-64 w-full object-cover"
        />
      </div>

      <div class="p-6" v-if="saveStatus !== 'completed'">
        <h4 class="font-semibold //text-lg mb-2">
          Book a private 1-1 consultation
        </h4>
        <p class="mb-4">
          Would you like to discuss your blood test report with one of our
          expert doctors? Book a 20-minute or 30-minute telephone consultation
          so that you can better understand your results and how you can
          optimise your health, at a time that suits your schedule.
        </p>

        <h4 class="font-semibold //text-lg mb-2">What’s included</h4>
        <template v-if="!seeingMore">
          <p class="mb-4">
            A variety of appointment times are available to you, and...
            <button
              @click="seeingMore = true"
              class="font-semibold text-cobalt-500"
            >
              see more
            </button>
          </p>
        </template>

        <template v-else>
          <p class="mb-4">
            A variety of appointment times are available to you, and prior to
            booking you will receive a short survey asking what you would like
            to discuss and basic information surrounding your health status,
            which will be reviewed by the doctor ahead of the appointment
            allowing you to make the most out of your consultation.
          </p>

          <p class="mb-4">
            During the consultation you will be able to ask the doctor any
            questions about your health test results, such as:
          </p>

          <ul class="list-disc pl-6">
            <li>What your results mean</li>
            <li>
              What further tests are recommended, or how frequently you should
              repeat the test
            </li>
            <li>How your symptoms could be related to your results</li>
            <li>How lifestyle changes could improve your health and results</li>
            <li>
              Why there could be an upwards or downwards trend in your results
              over time
            </li>
          </ul>
          <button
            @click="seeingMore = false"
            class="font-semibold text-cobalt-500 block mb-6"
          >
            Hide
          </button>
        </template>
        <form @submit.prevent="requestConsultation" class="space-y-4">
          <BaseTextInput
            type="text"
            label="First name"
            id="firstName"
            name="firstName"
            v-model="appointmentDetails.firstName"
            required
          />
          <BaseTextInput
            type="text"
            label="Last name"
            id="lastName"
            name="lastName"
            v-model="appointmentDetails.lastName"
            required
          />
          <BaseTextInput
            type="tel"
            label="Phone number"
            id="phone"
            name="phone"
            v-model="appointmentDetails.phone"
            required
          />
          <TextareaInput
            name="appointmentInfo"
            id="appointmentInfo"
            v-model="appointmentDetails.appointmentInfo"
            label="Briefly explain your reason for a health consultation"
          />
          <SubmitButton :loading="saveStatus === 'saving'" hide-icon>
            Submit
          </SubmitButton>
        </form>
      </div>
      <div v-else class="p-6">
        Thanks for starting the process of booking a health consultation. A
        member of the team will be in touch shortly.
        <button
          class="bg-cobalt-500 px-4 py-2 text-white rounded hover:bg-cobalt-700 block mt-4 font-semibold w-full"
          @click="(showModal = false), (saveStatus = 'idle')"
        >
          Click here to go back
        </button>
      </div>
    </AppModal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { usePatientStore } from "@/stores/patient";
import AppModal from "./AppModal.vue";
import BaseTextInput from "./BaseTextInput.vue";
import TextareaInput from "./QuestionnaireFields/TextareaInput.vue";
import SubmitButton from "./SubmitButton.vue";

const patientStore = usePatientStore();
const showModal = ref(false);
const seeingMore = ref(false);
const saveStatus = ref("idle");
const errorMessage = ref("");

const appointmentDetails = ref({
  firstName: patientStore.patient.firstName,
  lastName: patientStore.patient.lastName,
  phone: patientStore.patient.phone,
  appointmentInfo: "",
});

defineProps({
  removePadding: Boolean,
  fullWidth: Boolean,
});

async function requestConsultation() {
  saveStatus.value = "saving";
  patientStore
    .editPatient({
      firstName: appointmentDetails.value.firstName,
      lastName: appointmentDetails.value.lastName,
      phone: appointmentDetails.value.phone,
      appointmentInfo: appointmentDetails.value.appointmentInfo,
    })
    .then(() => (saveStatus.value = "completed"))
    .catch(() => (errorMessage.value = "Something went wrong"));
  // .finally(() => ());
}
</script>
