const { VUE_APP_AUTH_PROVIDER } = process.env;

import treeImage from "@/assets/images/signin/iStock-826220836.png";
import peopleImage from "@/assets/images/activation/AD1I8294.jpg";
import activationImage from "@/assets/images/activation/activation-code.jpg";
import ipad from "@/assets/images/upload/ipad.png";
import consultation from "@/assets/images/AD1I7643.jpg";
import workplaceLogoWhite from "@/assets/images/dna-workplace-logo.png";
import workplaceLogoColor from "@/assets/images/dna-workplace-logo-color.png";
import ldnaLogoColor from "@/assets/images/Logo-Color.png";
import ldnaLogo from "@/assets/images/Logo.png";

export const loginImages = {
  treeImage,
  peopleImage,
  activationImage,
  ipad,
  consultation,
};

let logos;

const LDNALogos = {
  whiteLogo: ldnaLogo,
  colorCompactLogo: "",
  colorLogo: ldnaLogoColor,
};

const workplaceLogos = {
  whiteLogo: workplaceLogoWhite,
  colorCompactLogo: "",
  colorLogo: workplaceLogoColor,
};

if (VUE_APP_AUTH_PROVIDER === "clerk") {
  logos = workplaceLogos;
} else {
  logos = LDNALogos;
}

export { logos };
