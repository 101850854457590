<template>
  <div>
    <div v-if="showLabel" class="flex items-center justify-between">
      <label
        :for="id"
        class="text-grey-700 font-semibold"
        style="font-size: 14px"
      >
        Password
      </label>
      <router-link
        :to="{ name: 'reset-password' }"
        v-if="!newPassword"
        class="text-cobalt-700"
        style="font-size: 14px"
      >
        Forgot Password?
      </router-link>
    </div>
    <div class="relative mt-1">
      <input
        @input="$emit('update:modelValue', $event.target.value)"
        :value="modelValue"
        :type="inputType"
        :id="id"
        class="border bg-grey-50 border-grey-500 rounded w-full py-2 px-4 focus:outline-none focus:border-cobalt-500 pr-12"
        @blur="$emit('blur', $event)"
        name="password"
      />
      <span class="absolute pin-r mr-4 my-auto inset-y-0 right-0 flex">
        <vue-feather
          @click="toggleInputType()"
          class="my-auto fill-current text-grey-500 cursor-pointer"
          :type="inputType === 'password' ? 'eye' : 'eye-off'"
        />
      </span>
    </div>
    <div v-if="errorText" class="mt-1 text-rose-500 text-sm">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
import VueFeather from "vue-feather";

export default {
  components: { VueFeather },
  props: {
    modelValue: String,
    newPassword: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    errorText: String,
  },
  data() {
    return {
      inputType: "password",
      id: `password-${Math.floor(Math.random() * 1000)}`,
    };
  },
  methods: {
    toggleInputType() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
  },
};
</script>
