<template>
  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <g>
        <g>
          <path
            d="M256,0C114.618,0,0,114.618,0,256s114.618,256,256,256s256-114.618,256-256S397.382,0,256,0z M468.274,234.667H277.333
				V43.726C378.117,53.734,458.266,133.883,468.274,234.667z M234.667,43.726v190.941H43.726
				C53.734,133.883,133.883,53.734,234.667,43.726z M43.726,277.333h190.941v190.941C133.883,458.266,53.734,378.117,43.726,277.333
				z M277.333,468.274V277.333h190.941C458.266,378.117,378.117,458.266,277.333,468.274z"
          />
          <!-- <path
                        d="M323.661,161.83l-15.085,15.085c-8.331,8.331-8.331,21.839,0,30.17s21.839,8.331,30.17,0L353.83,192l15.076,15.076
				c8.331,8.331,21.839,8.331,30.17,0s8.331-21.839,0-30.17L384,161.83l15.076-15.076c8.331-8.331,8.331-21.839,0-30.17
				s-21.839-8.331-30.17,0l-15.076,15.076l-15.085-15.085c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17L323.661,161.83z
				"
                    /> -->
          <!-- <path
                        d="M192,353.83l15.076-15.076c8.331-8.331,8.331-21.839,0-30.17c-8.331-8.331-21.839-8.331-30.17,0l-15.076,15.076
				l-15.085-15.085c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17l15.085,15.085l-15.085,15.085
				c-8.331,8.331-8.331,21.839,0,30.17s21.839,8.331,30.17,0L161.83,384l15.076,15.076c8.331,8.331,21.839,8.331,30.17,0
				s8.331-21.839,0-30.17L192,353.83z"
                    />
                    <path
                        d="M362.667,341.333c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333H320
				c-11.782,0-21.333,9.551-21.333,21.333s9.551,21.333,21.333,21.333H362.667z"
                    />
                    <path
                        d="M384,362.667h-42.667C329.551,362.667,320,372.218,320,384c0,11.782,9.551,21.333,21.333,21.333H384
				c11.782,0,21.333-9.551,21.333-21.333C405.333,372.218,395.782,362.667,384,362.667z"
                    />
                    <path
                        d="M149.333,128H192c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333h-42.667
				c-11.782,0-21.333,9.551-21.333,21.333C128,118.449,137.551,128,149.333,128z"
                    />
                    <path
                        d="M128,192h42.667c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333H128
				c-11.782,0-21.333,9.551-21.333,21.333C106.667,182.449,116.218,192,128,192z"
                    /> -->
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "viking-shield",
};
</script>
