<template>
  <div class="content">
    <h1 class="font-normal text-2xl my-3 text-denim-700">
      Delete {{ firstName }}'s Health profile
    </h1>
    <p>
      Are you sure you want to permanently delete
      <strong>{{ firstName }}’s</strong>
      Health profile? This decision cannot be reversed.
    </p>
    <p>
      By deleting
      <strong>{{ firstName }}’s</strong>
      Health profile, the following information will be destroyed:
    </p>
    <ul class="list-disc">
      <li class="mt-2">
        All digital results visible to this profile (e.g. ancestry estimations
        and wellbeing traits)
      </li>
      <li class="mt-2">
        Any applicable Family Networks data, including match list and message
        history
      </li>
      <li class="mt-2">Downloadable raw DNA data file</li>
      <li class="mt-2">
        Residual DNA sample stored by Living DNA's laboratory (if applicable)
      </li>
      <li class="mt-2">
        Digital purchases for this profile, such as test upgrades
      </li>
      <li class="mt-2">
        Access rights for other users who can currently view this profile
      </li>
    </ul>
    <p>
      This is not an account-level deletion and will still allow
      <strong>{{ auth.user().email }}</strong>
      to log into LivingDNA.com and view any other profiles owned and managed by
      that email address.
    </p>
    <router-link
      :to="{ name: 'confirm-deletion', params: { barcode: patient.userUuid } }"
      class="w-full py-2 px-4 mt-6 rounded flex items-center justify-center bg-rose-500 hover:bg-rose-700 text-white font-semibold"
    >
      <AppIcon icon="trash-2" class="mr-2 h-5 w-5 text-white" />
      <span class="text-white">Request deletion</span>
    </router-link>
    <router-link
      :to="{ name: 'health-profile' }"
      class="text-center text-grey-500 font-semibold mt-4 block"
    >
      Cancel
    </router-link>
  </div>
</template>

<script setup>
import { useAuth } from "@/stores/account";

defineProps({
  patient: {
    required: true,
    type: Object,
  },
  firstName: {
    required: true,
    type: String,
  },
});

const auth = useAuth();
</script>
