import axios from "axios";

const { VUE_APP_PREPR_URL } = process.env;

const ax = axios.create();

export function getGeneticTrait(geneticTraitSlug: string) {
  return ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        query GeneticTrait($geneticTraitSlug: String) {
          GeneticTrait(slug: $geneticTraitSlug) {
            _slug
            _id
            header_image {
              url
            }
            trait_name
            trait_subcategory {
              _slug
              name
              id
              category {
                category_image {
                  url
                }
                thumbnail_image {
                  url
                }
                _slug
                category_name
                numeric_results
              }
            }
            trait_icon {
              url
            }
            medical_review_icon {
              url
            }
            report_author {
              name
              biography
              photos {
                url
              }
            }
            what_is_header
            what_is_body
            symptoms_header
            symptoms_body
            tips_tricks
            the_science_header
            limitations_header
            limitations_body
            references {
              name
              url
            }
          }
        }
      `,
      variables: {
        geneticTraitSlug: geneticTraitSlug,
      },
    },
  });
}

export function getGeneticTraitsBySubcategory(subcategoryId: string) {
  return ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
      {
        GeneticTraits ( where : { trait_subcategory: { _slug_any : ["${subcategoryId}"] } }, limit: 500 ) {
          items {
            _slug
            header_image {
              url
            }
            trait_name
            trait_subcategory {
              name
              _id
              _slug
              category {
                category_image {
                  url
                }
                thumbnail_image {
                  url
                }
                _slug
                id
                category_name
                key_product {
                  _id
                }
                numeric_results
              }
            }
            trait_icon {
              url
            }
            medical_review_icon {
              url
            }
            report_author {
              name
              biography
              photos {
                url
              }
            }
            what_is_header
            what_is_body
            symptoms_header
            symptoms_body
            tips_tricks
            the_science_header
            limitations_header
            limitations_body
            references {
              name
              url
            }
          }
        }
      }
      `,
    },
  });
}

export async function getGene(geneId: string) {
  const res = await ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        query Genes($where: GeneWhereInput) {
          Genes(where: $where, limit: 500) {
            items {
              id
              description
              name
            }
          }
        }
      `,
      variables: {
        where: {
          name: geneId,
        },
      },
    },
  });
  if (!res.data.data?.Genes) {
    console.log("couln't find the gene");
  } else {
    return res.data.data.Genes.items[0];
  }
}

export async function getGeneticCategories(categories: string[]) {
  const res = await ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        query Items($where: GeneticCategoryWhereInput) {
          GeneticCategories(where: $where, limit: 500) {
            items {
              _slug
              category_name
              description
              id
              category_image {
                url
              }
              thumbnail_image {
                url
              }
              key_product {
                _id
              }
            }
          }
        }
      `,
      variables: {
        where: {
          _slug_any: categories,
        },
      },
    },
  });
  return res.data.data.GeneticCategories.items;
}

export function getGeneticSubcategories(category: string) {
  return ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        {
          GeneticSubcategories ( where : { category: { _slug_any : ["${category}"] } }, limit: 500 ) {
            items {
                _id
                _slug
                name
            }
          }
        }
      `,
    },
  });
}

export function getIntroText(introId: string) {
  return ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        {
          GeneticDashboardIntro (id: "${introId}") { 
            _id
            name
            body
          }
        }
      `,
    },
  });
}

export function combineCmsAndResultData(cmsData: any[], result: any) {
  return cmsData.map((trait) => {
    const foundCmsData = result.find((resultItem: any) => {
      return resultItem.trait_id === trait._slug;
    });

    return {
      ...trait,
      ...foundCmsData,
    };
  });
}
