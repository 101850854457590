import { defineStore } from "pinia";
import { makeHealthRequest } from "@/services/healthBackend";
import { usePatientStore } from "./patient";

export const useOrderStore = defineStore("orders", {
  state: () => ({
    orders: [],
  }),
  actions: {
    async getOrders() {
      const patientStore = usePatientStore();

      try {
        const res = await makeHealthRequest({
          path: `/orders/patient/${patientStore.patient.email}`,
          method: "GET",
        });
        return res.data;
      } catch (e: any) {
        if (e?.response?.status === 404) {
          // no orders, return empty array
          return [];
        } else {
          console.error(e);
        }
      }
    },
  },
});
