<template>
  <BaseRadioInput
    v-model="smokerDetails.status"
    :options="[
      { id: 'no', title: 'Never smoked', value: null },
      { id: 'present', title: 'Current smoker', value: 'present' },
      { id: 'past', title: 'Past smoker who has given up', value: 'past' },
    ]"
    label="Are you a smoker?"
    id="isSmoker"
  />
  <div v-if="smokerDetails.status" class="mt-6">
    <div>
      <label for="years" class="block font-semibold text-sm">
        <span v-if="smokerDetails.status === 'present'">
          How long have you smoked for in total?
        </span>
        <span v-if="smokerDetails.status === 'past'">
          How long did you smoke for in total?
        </span>
      </label>
    </div>
    <div class="mt-2 inline-flex rounded-md shadow-sm space-x-4">
      <div class="inline-flex rounded-md shadow-sm">
        <input
          v-model="cigaretteTime.years"
          pattern="[0-9]*"
          type="number"
          name="years"
          id="years"
          class="block w-24 min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
          min="0"
        />
        <label
          for="years"
          class="inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm w-24"
        >
          Years
        </label>
      </div>
      <div class="inline-flex rounded-md shadow-sm">
        <input
          v-model="cigaretteTime.months"
          pattern="[0-9]*"
          type="number"
          name="months"
          id="months"
          class="block w-24 min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
          min="0"
        />
        <label
          for="months"
          class="inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm w-24"
        >
          Months
        </label>
      </div>
    </div>
    <BaseTextInput
      v-model="smokerDetails.per_day"
      name="cigarettes-per-day"
      type="number"
      id="cigarettes-per-day"
      :label="
        smokerDetails.status === 'present'
          ? 'On average, how many cigarettes do you smoke a day?'
          : 'On average, how many cigarettes did you smoke a day?'
      "
      inputWidth="16"
      min="0"
      class="mt-4"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: Object,
});

const emit = defineEmits(["update:modelValue"]);

const cigaretteTime = ref({
  years: null,
  months: null,
});

cigaretteTime.value = {
  years: Math.floor(props.modelValue.months / 12),
  months: props.modelValue.months % 12,
};

const smokerDetails = ref({
  status: props.modelValue.status,
  months: undefined,
  per_day: props.modelValue.per_day,
});

watch(
  cigaretteTime,
  () => {
    console.log("gets triggered");
    smokerDetails.value.months =
      cigaretteTime.value.years * 12 + cigaretteTime.value.months;
  },
  { deep: true }
);

watch(smokerDetails, (value) => emit("update:modelValue", value), {
  deep: true,
});
</script>
