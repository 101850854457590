<template>
  <PageLoader v-if="loadingStatus === 'loading'">Loading</PageLoader>
  <AppPage v-else stack bleed-top>
    <div>
      <div class="sm:flex items-start sm:space-x-4 max-sm:space-y-4">
        <div class="flex-1">
          <div class="rounded bg-white shadow">
            <div class="md:flex max-md:space-y-2 justify-between p-6 border-b">
              <div class="flex items-center space-x-4">
                <div>{{ headerText.title }}</div>
              </div>
            </div>
            <div class="p-6">
              <div class="space-x-12 flex">
                <DoctorDetails mode="large" />
                <DoctorWelcomeContent>
                  <div v-html="headerText.body" />
                </DoctorWelcomeContent>
              </div>
            </div>
          </div>
        </div>
        <NextBloodTest
          v-if="patientStore.mostRecentTest?.result"
          class="flex-0"
        />
      </div>
    </div>

    <template v-if="enableBlood(patientStore.patient)">
      <div v-if="patientStore.mostRecentTest?.result">
        <h2 class="text-lg mb-2">Your latest blood result</h2>
        <div class="md:flex md:space-x-4 max-md:space-y-4">
          <div class="md:w-3/4">
            <DashboardResultsOverview :test="patientStore.mostRecentTest" />
          </div>
          <div class="md:w-1/4">
            <BookConsultation class="grow" full-width>
              <p class="mb-4">
                You can arrange a consultation with a doctor to discuss your
                results
              </p>
            </BookConsultation>
          </div>
        </div>
      </div>
      <div
        v-else-if="patientStore.mostRecentTest"
        class="rounded bg-white shadow"
      >
        <div class="md:flex max-md:space-y-2 justify-between p-6 border-b">
          <div class="flex items-center space-x-4">
            <div>{{ patientStore.mostRecentTest.name }}</div>
            <span
              class="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800"
            >
              {{ patientStore.mostRecentTest.instance }} test
            </span>
          </div>
          <div>
            <div class="flex items-center space-x-2 text-base text-grey-700">
              <AppIcon icon="calendar" />
              <span>
                Activated
                {{
                  new Date(
                    patientStore.mostRecentTest.createdAt
                  ).toLocaleDateString("en-GB")
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="p-6">
          <div class="space-y-6">
            <div>
              We're currently working to get your results back to you as soon as
              possible. In the meantime, you can find more information on the
              testing processes and our doctors
              <a href="#info-section" class="text-cobalt-500 font-semibold">
                here
              </a>
              all our other testing options
              <router-link :to="{ name: 'store' }">here</router-link>
              .
            </div>

            <DoctorDetails
              :doctor="patientStore.mostRecentTest.result.doctor"
            />
          </div>
        </div>
      </div>

      <div v-else class="rounded bg-white shadow">
        <div
          class="md:flex max-md:space-y-2 justify-between p-6 bg-cobalt-50 text-cobalt-500"
        >
          <div class="flex items-center space-x-4 font-semibold">
            <AppIcon icon="alert-circle" />
            <div>No blood tests activated</div>
          </div>
        </div>
        <div class="p-6">
          You haven't activated any blood tests yet. If you have a test please
          activate it, or check out your options below
          <div class="flex items-center space-x-4 mt-4">
            <router-link
              :to="{ name: 'store-index' }"
              class="font-semibold bg-cobalt-500 text-white rounded hover:bg-cobalt-700 py-2 px-4 block"
            >
              Explore options
            </router-link>
            <router-link
              :to="{ name: 'activate' }"
              class="font-semibold text-cobalt-500"
            >
              Activate a test
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <div v-if="patientStore.patient.geneticTest?.result?.results?.length">
      <h2 class="text-lg mb-2">Your genetic result</h2>
      <div class="md:flex md:space-x-4 max-md:space-y-4">
        <div class="md:w-3/4">
          <DashboardResultGeneticOverview
            :test="patientStore.patient.geneticTest"
          />
        </div>
        <div class="md:w-1/4">
          <div class="bg-white rounded shadow p-6 space-y-4">
            <h3 class="font-semibold">Unlock and Explore more traits</h3>
            <p>
              There {{ pluralize("are", lockedTraitsCount) }}
              {{ lockedTraitsCount }}
              genetic {{ pluralize("trait", lockedTraitsCount) }} still to
              unlock and discover about yourself.
            </p>
            <router-link
              :to="{ name: 'store-index' }"
              class="bg-cobalt-500 hover:bg-cobalt-700 px-4 py-2 rounded text-white block text-center font-semibold"
            >
              Discover
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="patientStore.patient.geneticTest?.kit?.sku.includes('YULIFE')"
    >
      <div class="rounded bg-white shadow">
        <div
          class="md:flex max-md:space-y-2 justify-between p-6 bg-cobalt-50 text-cobalt-500"
        >
          <div class="flex items-center space-x-4 font-semibold">
            <AppIcon icon="alert-circle" />
            <div>Waiting for data</div>
          </div>
        </div>
        <div class="p-6">
          <p>
            Once your data is returned from the lab we will begin processing it
          </p>
        </div>
      </div>
    </div>

    <DashboardGeneticIntro v-else />

    <div>
      <DashboardProductCategoryList />
    </div>

    <div v-if="patientStore.patient.tests.length">
      <h2 class="text-lg mb-2">Your test history</h2>
      <div
        class="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 //max-md:max-w-sm //max-md:mx-auto"
      >
        <!-- Condition checks that there aren't any tests where result it false because that's what I'm using at the moment to determine tests that are activated but no results returned yet -->
        <!-- Would I be better off doing this by getting the most recent test and checking it has results -->
        <TestDueCard v-if="patientStore.mostRecentTest?.result" />
        <template
          v-for="test in patientStore.patient.tests"
          :key="test.barcode"
        >
          <div v-if="test.result">
            <router-link
              :is="'router-link'"
              :to="{
                name: 'blood-test-details',
                params: { barcode: test.barcode },
              }"
              class=""
            >
              <TestOverviewCard :test="test" />
            </router-link>
          </div>
          <div v-else>
            <TestOverviewCardIncomplete :test="test" />
          </div>
        </template>
      </div>
    </div>

    <div v-if="profileStore.length === 0">
      <h2 class="text-lg mb-2">Unlock your DNA</h2>
      <div
        class="rounded bg-white shadow flex flex-col-reverse md:flex-row justify-between overflow-hidden"
      >
        <div class="p-6 flex-1">
          Do you want to explore more about your genetic history or wellbeing.
          <br />
          <br />

          You can uncover your ancestry with advanced reports or unlock
          comprehensive wellbeing reports for nutrition and fitness.
          <br />
          <br />

          Discover our range of Living DNA tests and order today.
          <a
            href="https://livingdna.com/compare"
            class="font-semibold text-cobalt-500 mt-4 flex items-center space-x-3"
          >
            Compare our DNA tests
            <AppIcon icon="chevron-right" />
          </a>
        </div>
        <div
          class="md:w-1/2 overflow-hidden max-md:fade-bottom md:fade-left flex-0"
        >
          <img
            src="https://static.livingdna.com/images/_2400xAUTO_crop_center-center_75_none/wellbeing-and-anestry-kit.jpg"
            alt=""
            class="h-full object-cover"
          />
        </div>
      </div>
    </div>

    <DashboardInformationSection id="info-section" />
  </AppPage>
</template>

<script setup lan>
import { ref, computed } from "vue";
import pluralize from "pluralize";
import { getHeaderText } from "@/services/store";
import { enableBlood } from "@/services/store";

import { useLoading } from "@/stores/loading";
import { usePatientStore } from "@/stores/patient";
import { useProfileStore } from "@/stores/profile";

import NextBloodTest from "@/components/NextBloodTest.vue";
import TestOverviewCard from "@/components/TestOverviewCard.vue";
import TestOverviewCardIncomplete from "@/components/TestOverviewCardIncomplete.vue";
import TestDueCard from "@/components/TestDueCard.vue";
import DashboardResultsOverview from "@/components/Dashboard/DashboardResultsOverview.vue";
import DashboardInformationSection from "@/components/Dashboard/DashboardInformationSection.vue";
import DoctorDetails from "@/components/DoctorDetails.vue";
import BookConsultation from "./BookConsultation.vue";
import DashboardResultGeneticOverview from "./Dashboard/DashboardResultGeneticOverview.vue";
import DashboardProductCategoryList from "./DashboardProductCategoryList.vue";
import DoctorWelcomeContent from "./DoctorWelcomeContent.vue";
import DashboardGeneticIntro from "./DashboardGeneticIntro.vue";

const patientStore = usePatientStore();
const profileStore = useProfileStore();
const { loadingStatus, finishLoading } = useLoading();

const headerText = ref({});

getHeaderText("b5a7e1f3-2b7c-4fe6-8190-c4d764e074a1").then((res) => {
  console.log(res);
  headerText.value = res.data.data.HeaderText;
  finishLoading();
});

const lockedTraitsCount = computed(() => {
  if (patientStore.patient.geneticTest.result) {
    return patientStore.patient.geneticTest.result.results.filter(
      (r) => r.result_value === "Locked"
    ).length;
  }
  return 0;
});
</script>
