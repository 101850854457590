<template>
  <div v-if="loadingStatus === 'loading'">
    <PageLoader>Loading</PageLoader>
  </div>
  <AppPage v-else>
    <router-link
      :to="{
        name: 'store-collection-method',
        params: { product: productData._id },
      }"
      class="flex items-center space-x-2 text-cobalt-500 font-semibold py-12"
    >
      <AppIcon icon="arrow-left" />
      <span>Back to choose collection method</span>
    </router-link>
    <h1 class="text-lg font-semibold">Subscribe and save</h1>

    <div class="mt-8">
      <div
        class="border-2 rounded w-full bg-white p-6 relative"
        :class="mode === 'subscription' ? 'border-cobalt-500' : ''"
      >
        <div
          class="absolute -mt-4 ml-6 top-0 left-0 h-8 bg-cobalt-500 px-4 text-white flex rounded"
        >
          <div class="m-auto text-sm">
            Best value save
            {{
              Math.floor(
                ((productData.price - productData.subscription_price) /
                  productData.price) *
                  100
              )
            }}%
          </div>
        </div>
        <div class="flex space-x-4 pt-4">
          <div class="md:w-1/2">
            <label class="cursor-pointer font-semibold w-full block">
              <input
                v-model="mode"
                value="subscription"
                type="radio"
                class="text-cobalt-500 focus:ring-cobalt-500"
              />
              &nbsp; Subscription
            </label>
            <div class="md:hidden">
              <p class="mb-2 mt-6">By subscribing you can</p>

              <ul class="space-y-2">
                <li class="flex items-center space-x-2">
                  <AppIcon icon="check-circle" class="text-cobalt-500" />
                  <div>Track your results over time</div>
                </li>
                <li class="flex items-center space-x-2">
                  <AppIcon icon="check-circle" class="text-cobalt-500" />
                  <div>Understand how your lifestyle affects your health</div>
                </li>
                <li class="flex items-center space-x-2">
                  <AppIcon icon="check-circle" class="text-cobalt-500" />
                  <div>Monitor Health conditions</div>
                </li>
              </ul>
            </div>
            <div class="mt-6">
              <p>Pause or cancel at any time</p>
              <p class="mt-4">1 test delivered every</p>
            </div>

            <select
              v-model="interval"
              class="mt-4 max-w-sm block w-full rounded-md border-gray-300 shadow-sm focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
            >
              <option
                v-for="option in productData.subscription_options"
                :key="option.body"
                :value="option.body.split(' ')[0]"
              >
                {{ option.body }}
              </option>
            </select>

            <div
              class="md:hidden mt-6 flex flex-col items-start //items-center space-y-2"
            >
              <div
                class="//text-lg font-semibold inline-flex items-center space-x-4 text-cobalt-500"
              >
                <div
                  class="bg-cobalt-500 text-white px-4 py-1 rounded-full text-xs"
                >
                  Subscription price
                </div>

                <span class="line-through text-rose-500">
                  {{ getPrice(productData) }}
                </span>

                <span>
                  {{ getSubscriptionPrice(productData) }}
                </span>
              </div>
              <div v-if="collectionMethod.price !== 0" class="text-sm">
                +
                <strong>{{ getPrice(collectionMethod) }}</strong>
                collection
              </div>
            </div>
          </div>
          <div class="max-md:hidden w-1/2">
            <p class="mb-2">By subscribing you can:</p>

            <ul class="space-y-2">
              <li class="flex items-center space-x-2">
                <AppIcon icon="check-circle" class="text-cobalt-500" />
                <div>Track your results over time</div>
              </li>
              <li class="flex items-center space-x-2">
                <AppIcon icon="check-circle" class="text-cobalt-500" />
                <div>Understand how your lifestyle affects your health</div>
              </li>
              <li class="flex items-center space-x-2">
                <AppIcon icon="check-circle" class="text-cobalt-500" />
                <div>Monitor Health conditions</div>
              </li>
            </ul>
            <div
              class="mt-6 flex flex-col items-start //items-center space-y-2"
            >
              <div
                class="//text-lg font-semibold inline-flex items-center space-x-4 text-cobalt-500"
              >
                <div
                  class="bg-cobalt-500 text-white px-4 py-1 rounded-full text-xs"
                >
                  Subscription price
                </div>

                <span class="line-through text-rose-500">
                  {{ getPrice(productData) }}
                </span>

                <span>
                  {{ getSubscriptionPrice(productData) }}
                </span>
              </div>
              <div v-if="collectionMethod.price !== 0" class="text-sm">
                +
                <strong>{{ getPrice(collectionMethod) }}</strong>
                collection
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <div
          class="border rounded w-full bg-white p-6 flex items-center"
          :class="mode === 'payment' ? 'border-gray-400' : ''"
        >
          <label class="cursor-pointer block w-1/2 font-semibold">
            <input v-model="mode" value="payment" type="radio" />
            &nbsp; One-off purchase
          </label>
          <div class="space-y-2">
            <div
              class="//text-lg font-semibold inline-flex items-center space-x-1 text-grey-700"
            >
              <div
                class="bg-grey-100 text-grey-700 px-4 py-1 rounded-full text-xs"
              >
                <!-- <AppIcon icon="check" /> -->
                One-off price
              </div>

              <span class="px-2">
                {{ getPrice(productData) }}
              </span>
            </div>
            <div v-if="collectionMethod.price !== 0" class="text-sm">
              +
              <strong>{{ getPrice(collectionMethod) }}</strong>
              collection
            </div>
          </div>
        </div>
      </div>
    </div>
    <StoreOrderButton
      :product="productData"
      :mode="mode"
      :collection-method="$route.params.method"
      :interval="interval"
    >
      <SubmitButton class="w-auto mt-8" icon="shopping-cart">
        Checkout
      </SubmitButton>
    </StoreOrderButton>
  </AppPage>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import StoreOrderButton from "@/components/StoreOrderButton.vue";
import SubmitButton from "@/components/SubmitButton.vue";
import { getProductData } from "@/services/store";
import { getPrice, getSubscriptionPrice } from "@/services/store";

const route = useRoute();
const productData = ref({});
const loadingStatus = ref("loading");
const mode = ref("subscription");
const interval = ref("3");
const collectionMethod = ref({});

getProductData(route.params.product)
  .then((res) => {
    productData.value = res;
    collectionMethod.value = productData.value.collection_methods.find(
      (c) => c._id === route.params.method
    );
  })
  .finally(() => (loadingStatus.value = "loaded"));
</script>
