<template>
  <div v-if="isInvalid" class="text-sm text-rose-500 mt-2">
    The value should be a number greater than or equal to zero
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps(["input"]);

const isInvalid = computed(
  () => props.input && typeof props.input === "string" && props.input < 0
);
</script>
