<template>
  <div v-if="loadingStatus === 'loading'">
    <PageLoader>Loading</PageLoader>
  </div>
  <div v-else class="pb-12">
    <div>
      <div class="fade-bottom fade-bottom-grey">
        <div class="h-64 relative fade-bottom fade-bottom-grey">
          <div class="absolute inset-0 bg-black opacity-20 z-10"></div>
          <span class="inset-0 z-20 absolute flex px-4"></span>
          <img
            :src="traitData.header_image[0].url"
            alt=""
            class="h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
    <AppPage>
      <AppBreadCrumbs
        class="mt-8"
        :items="[
          {
            label: traitData.trait_subcategory[0].category[0].category_name,
            to: {
              name: 'genetics-category',
              params: {
                category: traitData.trait_subcategory[0].category[0]._slug,
              },
            },
          },
          {
            label: traitData.trait_subcategory[0].name,
            to: {
              name: 'genetics-category',
              params: {
                category: traitData.trait_subcategory[0].category[0]._slug,
              },
              query: {
                subcategory: traitData.trait_subcategory[0]._slug,
              },
            },
          },
          {
            label: traitData.trait_name,
            to: {
              name: 'genetics-report',
              params: {
                trait: traitData._slug,
              },
            },
          },
          {
            label: 'The Science',
          },
        ]"
      />
      <h1 class="font-semibold text-2xl mt-12">
        {{ traitData.trait_name }}: The Science
      </h1>
      <h2 class="font-semibold text-xl mt-6">Genes and Genetic Variances</h2>
      <div class="space-y-6 mt-4">
        <div
          v-for="genome in genomes"
          :key="genome.gene_id"
          class="rounded bg-white p-6 space-y-6 flex-1"
        >
          <div
            v-if="genome.description"
            class="flex flex-col lg:flex-row gap-6 lg:gap-12"
          >
            <div class="space-y-4 w-48">
              <div class="bg-cobalt-500 rounded text-white px-4 py-2">
                Gene name: {{ genome.name }}
              </div>
              <div class="bg-cobalt-50 rounded text-cobalt-500 px-4 py-2">
                Your genotype: {{ genome.genotype }}
              </div>
            </div>
            <div class="flex-1" v-html="genome.description" />
          </div>
          <div v-else>Could not load data for this genome</div>
        </div>
      </div>
      <h2 class="font-semibold text-xl mt-12">References</h2>
      <div class="rounded bg-white p-6 space-y-6 flex-1 mt-4">
        <div v-for="reference in traitData.references" :key="reference.name">
          {{ reference.name }}
        </div>
      </div>
    </AppPage>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useLoading } from "@/stores/loading";
import { getGene } from "@/services/traits";
import { getGeneticTrait } from "@/services/traits.ts";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import { usePatientStore } from "@/stores/patient";

const { loadingStatus, finishLoading } = useLoading();
const patientStore = usePatientStore();

const genomes = ref([]);
const traitData = ref({});

// const result = {
//   trait: "Snacking Pattern",
//   genomes: [
//     { gene_id: "2102", genotype: "TC" },
//     { gene_id: "2194", genotype: "GG" },
//   ],
//   result_value: 2.4,
//   interpretation: "Good",
//   short_text: "As per your genetics, your Snacking Pattern is good.",
//   interpretation_text:
//     "As per your genotype, your Snacking Pattern is good. People with such a genetic makeup are unlikely to over snack after consuming proper meals.",
// };

async function getCmsDataForGenome(genome) {
  return await getGene(genome.gene_id);
}

const route = useRoute();

const currentResult = patientStore.patient.geneticTest.result.results.find(
  (result) => result.trait_id === route.params.trait
);

const resultGenomes = currentResult.genomes.split(",").map((genome) => ({
  gene_id: genome.slice(genome.indexOf("[") + 1, genome.indexOf("]")),
  genotype: genome.slice(0, genome.indexOf(" ")),
}));

Promise.all([
  Promise.all(
    resultGenomes.map(async (genome) => ({
      ...genome,
      ...(await getGene(genome.gene_id)),
    }))
  ).then((data) => (genomes.value = data)),
  getGeneticTrait(route.params.trait).then((res) => {
    console.log({ res });
    traitData.value = res.data.data.GeneticTrait;
  }),
]).then(finishLoading);
</script>
