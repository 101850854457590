function mapGeneticResult(result) {
  if (!result) {
    return false;
  }
  const [meta, results] = result;
  return {
    ...meta,
    results,
  };
}

export { mapGeneticResult };
