export const defaultSymptoms = [
  "Aneurysm",
  "Abdominal aortic aneurysm",
  "Acne",
  "Acute cholecystitis",
  "Acute lymphoblastic leukaemia",
  "Acute myeloid leukaemia",
  "Acute pancreatitis",
  "Addison's disease",
  "Alcohol-related liver disease",
  "Allergic rhinitis",
  "Allergies",
  "Alzheimer's disease",
  "Anal cancer",
  "Anaphylaxis",
  "Angioedema",
  "Ankylosing spondylitis",
  "Anorexia nervosa",
  "Anxiety",
  "Appendicitis",
  "Arthritis",
  "Asbestosis",
  "Asthma",
  "Atopic eczema",
  "Attention deficit hyperactivity disorder (ADHD)",
  "Autistic spectrum disorder (ASD)",
  "Bacterial vaginosis",
  "Benign prostate enlargement",
  "Bile duct cancer (cholangiocarcinoma)",
  "Binge eating",
  "Bipolar disorder",
  "Bladder cancer",
  "Blindness",
  "Blood poisoning (sepsis)",
  "Bone cancer",
  "Bowel cancer",
  "Bowel incontinence",
  "Bowel polyps",
  "Brain stem death",
  "Brain tumours",
  "Breast cancer",
  "Bronchiectasis",
  "Bronchitis",
  "Bulimia",
  "Bunion",
  "Carcinoid syndrome and carcinoid tumours",
  "Catarrh",
  "Cellulitis",
  "Cervical cancer",
  "Chest infection",
  "Chest pain",
  "Chickenpox",
  "Chilblains",
  "Chlamydia",
  "Chronic fatigue syndrome",
  "Chronic kidney disease",
  "Chronic lymphocytic leukaemia",
  "Chronic myeloid leukaemia",
  "Chronic obstructive pulmonary disease",
  "Chronic pain",
  "Chronic pancreatitis",
  "Cirrhosis",
  "Clostridium difficile",
  "Coeliac disease",
  "Cold sore",
  "Coma",
  "Common cold",
  "Common heart conditions",
  "Congenital heart disease",
  "Conjunctivitis",
  "Constipation",
  "Coronavirus (COVID-19)",
  "Cough",
  "Crohn's disease",
  "Croup",
  "Cystic fibrosis",
  "Cystitis",
  "Deafblindness",
  "Deafness",
  "Deep vein thrombosis (DVT)",
  "Dehydration",
  "Dementia",
  "Dementia with Lewy bodies",
  "Dental abscess",
  "Depression",
  "Dermatitis herpetiformis",
  "Diabetes",
  "Diabetes: Type 1",
  "Diabetes: Type 2",
  "Diarrhoea",
  "Discoid eczema",
  "Diverticular disease and diverticulitis",
  "Dizziness (Lightheadedness)",
  "Down's syndrome",
  "Dry mouth",
  "Dysphagia (swallowing problems)",
  "Dystonia",
  "Earache",
  "Earwax build-up",
  "Ebola virus disease",
  "Ectopic pregnancy",
  "Endometriosis",
  "Epilepsy",
  "Erectile dysfunction (impotence)",
  "Escherichia coli (E. coli) O157",
  "Ewing sarcoma",
  "Eye cancer",
  "Febrile seizures",
  "Fever in adults",
  "Fever in children",
  "Fibroids",
  "Fibromyalgia",
  "Flatulence",
  "Flu",
  "Foetal alcohol syndrome",
  "Food poisoning",
  "Functional neurological disorder (FND)",
  "Fungal nail infection",
  "Gallbladder cancer",
  "Gallstones",
  "Ganglion cyst",
  "Gastro-oesophageal reflux disease (GORD)",
  "Gastroenteritis",
  "Genital herpes",
  "Genital warts",
  "Germ cell tumours",
  "Glandular fever",
  "Gonorrhoea",
  "Gout",
  "Gum disease",
  "Haemorrhoids (piles)",
  "Hand, foot and mouth disease",
  "Hay fever",
  "Head and neck cancer",
  "Head lice and nits",
  "Headaches",
  "Hearing loss",
  "Heart failure",
  "Hepatitis A",
  "Hepatitis B",
  "Hepatitis C",
  "Hiatus hernia",
  "High cholesterol",
  "HIV",
  "Hodgkin lymphoma",
  "Huntington's disease",
  "Hyperglycaemia (high blood sugar)",
  "Hyperhidrosis",
  "Hypoglycaemia (low blood sugar)",
  "Idiopathic pulmonary fibrosis",
  "Impetigo",
  "Indigestion",
  "Ingrown toenail",
  "Inherited heart conditions",
  "Insomnia",
  "Iron deficiency anaemia",
  "Irritable bowel syndrome (IBS)",
  "Irritable hip",
  "Itching",
  "Itchy bottom",
  "Kaposi's sarcoma",
  "Kidney cancer",
  "Kidney infection",
  "Kidney stones",
  "Labyrinthitis",
  "Lactose intolerance",
  "Laryngeal (larynx) cancer",
  "Laryngitis",
  "Leg cramps",
  "Lichen planus",
  "Liver cancer",
  "Liver disease",
  "Liver tumours",
  "Loss of libido",
  "Lung cancer",
  "Lupus",
  "Lyme disease",
  "Lymphoedema",
  "Lymphogranuloma venereum (LGV)",
  "Malaria",
  "Malignant brain tumour (cancerous)",
  "Malnutrition",
  "Measles",
  "Meniere's disease",
  "Meningitis",
  "Menopause",
  "Mesothelioma",
  "Middle ear infection (otitis media)",
  "Migraine",
  "Miscarriage",
  "Motor neurone disease (MND)",
  "Mouth cancer",
  "Mouth ulcer",
  "Multiple myeloma",
  "Multiple sclerosis (MS)",
  "Mumps",
  "Nasal and sinus cancer",
  "Nasopharyngeal cancer",
  "Neuroendocrine tumours",
  "Non-alcoholic fatty liver disease (NAFLD)",
  "Non-Hodgkin lymphoma",
  "Norovirus",
  "Nosebleeds (Regular)",
  "Obesity",
  "Obstructive sleep apnoea",
  "Oesophageal cancer",
  "Oral thrush in adults",
  "Osteoarthritis",
  "Osteoporosis",
  "Osteosarcoma",
  "Otitis externa",
  "Ovarian cancer",
  "Ovarian cyst",
  "Overactive thyroid",
  "Paget's disease of the nipple",
  "Pancreatic cancer",
  "Panic disorder",
  "Parkinson's disease",
  "Pelvic inflammatory disease",
  "Pelvic organ prolapse",
  "Penile cancer",
  "Peripheral neuropathy",
  "Personality disorder",
  "Pleurisy",
  "Pneumonia",
  "Polymyalgia rheumatica",
  "Post-traumatic stress disorder (PTSD)",
  "Postnatal depression",
  "Pregnancy and baby",
  "Pressure ulcers",
  "Prostate cancer",
  "Psoriasis",
  "Psoriatic arthritis",
  "Psychosis",
  "Pubic lice",
  "Rare tumours",
  "Raynaud's phenomenon",
  "Reactive arthritis",
  "Restless legs syndrome",
  "Rhabdomyosarcoma",
  "Rheumatoid arthritis",
  "Ringworm and other fungal infections",
  "Rosacea",
  "Scabies",
  "Scarlet fever",
  "Schizophrenia",
  "Scoliosis",
  "Septic shock",
  "Shingles",
  "Shortness of breath",
  "Sickle cell disease",
  "Sinusitis",
  "Sjogren's syndrome",
  "Skin cancer (melanoma)",
  "Skin cancer (non-melanoma)",
  "Slapped cheek syndrome",
  "Soft tissue sarcomas",
  "Sore throat",
  "Spleen problems and spleen removal",
  "Stillbirth",
  "Stomach ache and abdominal pain",
  "Stomach cancer",
  "Stomach ulcer",
  "Stress, anxiety and low mood",
  "Stroke",
  "Sudden infant death syndrome (SIDS)",
  "Suicidal Behaviour",
  "Sunburn",
  "Swollen glands",
  "Syphilis",
  "Testicular cancer",
  "Testicular lumps and swellings",
  "Thirst",
  "Threadworms",
  "Thrush",
  "Thyroid cancer",
  "Tinnitus",
  "Tonsillitis",
  "Tooth decay",
  "Toothache",
  "Transient ischaemic attack (TIA)",
  "Transverse myelitis",
  "Trichomonas infection",
  "Trigeminal neuralgia",
  "Tuberculosis (TB)",
  "Ulcerative colitis",
  "Underactive thyroid",
  "Urinary incontinence",
  "Urinary tract infection (UTI)",
  "Urticaria (hives)",
  "Vaginal cancer",
  "Varicose eczema",
  "Venous leg ulcer",
  "Vertigo",
  "Vitamin B12 or folate deficiency anaemia",
  "Vomiting in adults",
  "Vulval cancer",
  "Warts and verrucas",
  "Whooping cough",
  "Wilms’ tumour",
  "Womb (uterus) cancer",
  "Yellow fever",
];

export const supplements = [
  "Multivitamin",
  "Calcium",
  "Magnesium",
  "Vitamin D",
  "Vitamin D & K",
  "Vitamin E",
  "Vitamin A",
  "Vitamin B-complex",
  "Cod liver oil",
  "Vitamin C",
  "Zinc",
  "Omega 3",
  "Biotin",
  "Collagen",
  "Testosterone",
  "Pro-biotics",
];

export const symptoms = [
  "Stomach pain",
  "Back pain",
  "Joint pain",
  "Menstrual cramps (women only)",
  "Headaches",
  "Chest pain",
  "Dizziness",
  "Palpitations (feeling your heart pound or race)",
  "Shortness of breath",
  "Fainting spells",
  "Constipation",
  "Nausea, gas or indigestion",
  "Insomnia (inability to sleep)",
  "Vomitting",
  "Diarrhoea",
  "Loss of appetite",
  "Jaundice (yellowing of skin/eyes)",
  "Fatigue",
];

export const surgeries = [
  "Bariatric Surgery",
  "Breast Surgery",
  "Colon & Rectal Surgery",
  "Endocrine Surgery",
  "Caesarian section",
  "Gynecological Surgery",
  "Hand Surgery",
  "Head & Neck Surgery",
  "Hernia Surgery",
  "Joint Replacement Surgery",
  "Neurosurgery",
  "Orthopedic Surgery",
  "Eye Surgery",
  "Hysterectomy",
  "Heart Surgery",
  "Plastic & Reconstructive Surgery",
  "Coronary Artery Bypass",
  "Tonsil Removal",
  "Trauma Surgery",
  "Urologic Surgery",
  "Vascular Surgery",
  "Oopherectomy (Ovary removal)",
  "Cataract Surgery",
  "Gallbladder Removal",
  "Appendix Removal",
  "Melanoma Removal",
];

export const prescriptionDrugs = [
  "Aciclovir (Zovirax)",
  "Acrivastine",
  "Adalimumab",
  "Alendronic acid",
  "Allopurinol",
  "Alogliptin",
  "Amitriptyline",
  "Amlodipine",
  "Amoxicillin",
  "Anastrozole",
  "Apixaban",
  "Aspirin",
  "Atenolol",
  "Atorvastatin",
  "Azathioprine",
  "Azithromycin",
  "Baclofen",
  "Beclometasone ",
  "Bendroflumethiazide",
  "Benzoyl peroxide",
  "Benzydamine",
  "Betahistine",
  "Betamethasone tablets",
  "Bimatoprost",
  "Bisacodyl",
  "Bisoprolol",
  "Brinzolamide",
  "Budesonide inhalers",
  "Budesonide nasal spray",
  "Budesonide rectal foam and enemas",
  "Budesonide tablets, capsules and granules",
  "Bumetanide",
  "Buprenorphine",
  "Buscopan (hyoscine butylbromide)",
  "Calcipotriol",
  "Calpol",
  "Candesartan",
  "Carbamazepine",
  "Carbimazole",
  "Carbocisteine",
  "Carmellose sodium",
  "Carvedilol",
  "Cefalexin",
  "Cetirizine",
  "Champix (varenicline)",
  "Chloramphenicol",
  "Chlorhexidine",
  "Chlorphenamine (Piriton)",
  "Cinnarizine",
  "Ciprofloxacin",
  "Citalopram",
  "Clarithromycin",
  "Clobetasol",
  "Clobetasone",
  "Clonazepam",
  "Clopidogrel",
  "Clotrimazole",
  "Clotrimazole cream, spray and solution",
  "Co-amoxiclav",
  "Co-beneldopa",
  "Co-careldopa",
  "Co-codamol",
  "Co-codaprin (aspirin and codeine)",
  "Co-dydramol",
  "Codeine",
  "Colchicine",
  "Cyanocobalamin",
  "Cyclizine",
  "Dabigatran",
  "Dapagliflozin",
  "Dexamethasone eye drops",
  "Dexamethasone tablets and liquid",
  "Diazepam",
  "Diclofenac",
  "Digoxin",
  "Dihydrocodeine",
  "Diltiazem",
  "Diphenhydramine",
  "Dipyridamole",
  "Docusate",
  "Domperidone",
  "Donepezil",
  "Dosulepin",
  "Doxazosin",
  "Doxycycline",
  "Duloxetine",
  "Edoxaban",
  "Empagliflozin",
  "Enalapril",
  "Eplerenone",
  "Erythromycin",
  "Escitalopram",
  "Esomeprazole",
  "Ezetimibe",
  "Felodipine",
  "Fentanyl",
  "Ferrous fumarate",
  "Ferrous sulfate",
  "Fexofenadine",
  "Finasteride",
  "Flucloxacillin",
  "Fluconazole",
  "Fluoxetine (Prozac)",
  "Fluticasone inhalers",
  "Fluticasone nasal spray and drops",
  "Fluticasone skin creams",
  "Folic acid",
  "Furosemide",
  "Fusidic acid",
  "Fybogel (ispaghula husk)",
  "Gabapentin",
  "Gaviscon (alginic acid)",
  "Gliclazide",
  "Glimepiride",
  "Glyceryl trinitrate (GTN)",
  "Heparinoid",
  "Hydrocortisone",
  "Hydrocortisone buccal tablets",
  "Hydrocortisone for piles and itchy bottom",
  "Hydrocortisone for skin",
  "Hydrocortisone injections",
  "Hydrocortisone rectal foam",
  "Hydrocortisone tablets",
  "Hydroxocobalamin",
  "Hydroxychloroquine",
  "Hyoscine hydrobromide (Kwells and Joy-Rides)",
  "Ibuprofen and codeine",
  "Ibuprofen for adults (Nurofen)",
  "Ibuprofen for children",
  "Indapamide",
  "Irbesartan",
  "Isosorbide mononitrate and isosorbide dinitrate",
  "Isotretinoin capsules (Roaccutane)",
  "Isotretinoin gel (Isotrex)",
  "Ketoconazole",
  "Labetalol",
  "Lactulose",
  "Lamotrigine",
  "Lansoprazole",
  "Latanoprost",
  "Lercanidipine",
  "Letrozole",
  "Levetiracetam",
  "Levothyroxine",
  "Lidocaine",
  "Lidocaine skin cream",
  "Linagliptin",
  "Lisinopril",
  "Lithium",
  "Loperamide",
  "Loratadine (Clarityn)",
  "Lorazepam",
  "Losartan",
  "Low-dose aspirin",
  "Lymecycline",
  "Macrogol",
  "Mebendazole",
  "Mebeverine",
  "Melatonin",
  "Memantine",
  "Mesalazine",
  "Metformin",
  "Methadone",
  "Methotrexate",
  "Methylphenidate ",
  "Metoclopramide",
  "Metoprolol",
  "Metronidazole",
  "Mirabegron",
  "Mirtazapine",
  "Molnupiravir (Lagevrio)",
  "Mometasone for skin",
  "Mometasone inhalers",
  "Mometasone nasal spray",
  "Montelukast",
  "Morphine",
  "Naproxen",
  "Nefopam",
  "Nicorandil",
  "Nifedipine",
  "Nitrofurantoin",
  "Nortriptyline",
  "Nystatin",
  "Olanzapine",
  "Olmesartan",
  "Omeprazole",
  "Oxybutynin",
  "Oxycodone",
  "Pantoprazole",
  "Paracetamol for adults",
  "Paroxetine",
  "Paxlovid",
  "Peppermint oil",
  "Pepto-Bismol (bismuth subsalicylate)",
  "Perindopril",
  "Phenoxymethylpenicillin",
  "Phenytoin",
  "Pioglitazone",
  "Pravastatin",
  "Prednisolone tablets and liquid",
  "Pregabalin",
  "Prochlorperazine",
  "Promethazine (Phenergan)",
  "Propranolol",
  "Pseudoephedrine (Sudafed)",
  "Quetiapine",
  "Rabeprazole",
  "Ramipril",
  "Ranitidine",
  "Remdesivir (Veklury)",
  "Risedronate",
  "Risperidone",
  "Rivaroxaban",
  "Ropinirole",
  "Rosuvastatin",
  "Salbutamol inhaler",
  "Saxagliptin",
  "Senna",
  "Sertraline",
  "Sildenafil (Viagra)",
  "Simeticone",
  "Simvastatin",
  "Sitagliptin",
  "Sodium cromoglicate capsules",
  "Sodium cromoglicate eye drops",
  "Sodium valproate",
  "Solifenacin",
  "Sotalol",
  "Sotrovimab (Xevudy)",
  "Spironolactone",
  "Sulfasalazine",
  "Sumatriptan",
  "Tadalafil",
  "Tamsulosin",
  "Temazepam",
  "Terbinafine",
  "Thiamine (vitamin B1)",
  "Ticagrelor",
  "Timolol eye drops",
  "Timolol tablets",
  "Tolterodine",
  "Topiramate",
  "Tramadol",
  "Tranexamic acid",
  "Trazodone",
  "Trimethoprim",
  "Valproic acid",
  "Valsartan",
  "Varenicline",
  "Venlafaxine",
  "Verapamil",
  "Warfarin",
  "Zolpidem",
  "Zopiclone",
];

export const prescriptionDrugFrequency = [
  "More than three times per day",
  "Twice per day",
  "Once per day",
  "Once per week",
  "Two to four times per week",
  "Five to six times per week",
  "One to three times per month",
  "Less than once per month",
];

export const recreationalDrugs = [
  "Benzodiazepines",
  "Buprenorphine",
  "Cannabis (Marijuana, Pot, Weed)",
  "Cocaine",
  "Ecstasy (MDMA, Molly)",
  "Fentanyl",
  "GHB",
  "Heroin",
  "Hydrocodone",
  "Hydromorphone",
  "Ketamine",
  "Khat",
  "LSD",
  "Magic Mushrooms",
  "Mephedrone",
  "Methadone",
  "Methamphetamine (Crystal meth)",
  "PCP (Angel dust)",
  "Pethidine",
  "Propoxyphene",
  "Steriods (Anabolic)",
  "Synthetic cannabinoids (K2, spice)",
  "Synthetic cathinones (Bath salts)",
  "Tramadol",
];

export const recreationalDrugFrequency = [
  "More than three times per day",
  "Twice per day",
  "Once per day",
  "Once per week",
  "Two to four times per week",
  "Five to six times per week",
  "One to three times per month",
  "Less than once per month",
];

export const relatives = [
  "Father",
  "Mother",
  "Son",
  "Daughter",
  "Uncle",
  "Aunty",
  "Cousin",
  "Grandfather",
  "Grandmother",
];

export const dietTypes = [
  "Paleo",
  "Vegan",
  "Vegetarian",
  "Pescatarian",
  "Gluten free",
  "Dairy free",
  "Ketogenic",
  "Low-carb",
  "Carnivore",
  "Atkins",
  "Plant-based",
  "Low Fodmap",
];

export const wellbeingGoals = [
  {
    id: "general-wellbeing",
    title: "I want to improve my general wellbeing",
    icon: "activity",
    name: "General Wellbeing",
  },
  {
    id: "monitor",
    title: "I want to monitor known health condition(s)",
    icon: "monitor",
    name: "Monitor conditions",
  },
  {
    id: "improve-control",
    title: "I want to improve control of my health condition(s)",
    icon: "sliders",
    name: "Improve Control",
  },
  {
    id: "improve-cardio",
    title:
      "I want to improve my cardiovascular health (this includes lowering cholesterol)",
    icon: "heart",
    name: "Improve Cardio",
  },
  {
    id: "lose-weight",
    title: "I want to lose weight",
    icon: "bar-chart",
    name: "Lose Weight",
  },
  {
    id: "more-active",
    title: "I want to become more active",
    icon: "map",
    name: "Be More Active",
  },
  {
    id: "reduce-illness",
    title: "I want to reduce my risks of developing a medical illness",
    icon: "user-minus",
    name: "Reduce Illness Risk",
  },
  {
    id: "less-tired",
    title: "I want to feel less tired",
    icon: "coffee",
    name: "Be Less Tired",
  },
  {
    id: "less-stressed",
    title: "I want to feel less stressed",
    icon: "user-check",
    name: "Feel Less Stress",
  },
  {
    id: "mange-hormones",
    title: "I want to manage my hormones (including improving fertility)",
    icon: "arrow-right",
    name: "Manage Hormones",
  },
];

export const allergens = [
  "Hayfever",
  "Bee stings",
  "Insect stings",
  "Casein (milk/dairy)",
  "Animal fur",
  "Dust and dust mites",
  "Egg",
  "Fish",
  "Latex",
  "Mould",
  "Nickel",
  "Gold",
  "Chromium",
  "Nut",
  "Peanut",
  "Penicillin",
  "Poison Ivy",
  "Ragweed",
  "Saliculate",
  "Shellfish",
  "Sulfite",
  "Celery",
  "Tomtato",
  "Strawberry",
  "Orange",
  "Wheat (gluten)",
  "Buckwheat",
  "Fruit",
  "Garlic",
  "Oats",
  "Maize",
  "Mustard",
  "Poultry meat",
  "Red meat",
  "Rice",
  "Sesame",
  "Soy / Soybeans",
  "Tartrazine",
  "Tree nut",
  "Perfume",
  "Cosmetics",
  "Pesticide",
  "Fungicide",
  "Formaldehyde",
  "Hair dye",
  "Nail polish",
  "Dimethylaminopropylamine",
  "Sun",
  "Coeliac",
  "Alcohol",
  "Histamine",
  "Yeast",
  "Lupin",
  "Sulphur dioxide",
  "Aciclovir (Zovirax)",
  "Acrivastine",
  "Adalimumab",
  "Alendronic acid",
  "Allopurinol",
  "Alogliptin",
  "Amitriptyline",
  "Amlodipine",
  "Amoxicillin",
  "Anastrozole",
  "Apixaban",
  "Aspirin",
  "Atenolol",
  "Atorvastatin",
  "Azathioprine",
  "Azithromycin",
  "Baclofen",
  "Beclometasone ",
  "Bendroflumethiazide",
  "Benzoyl peroxide",
  "Benzydamine",
  "Betahistine",
  "Betamethasone tablets",
  "Bimatoprost",
  "Bisacodyl",
  "Bisoprolol",
  "Brinzolamide",
  "Budesonide inhalers",
  "Budesonide nasal spray",
  "Budesonide rectal foam and enemas",
  "Budesonide tablets, capsules and granules",
  "Bumetanide",
  "Buprenorphine",
  "Buscopan (hyoscine butylbromide)",
  "Calcipotriol",
  "Calpol",
  "Candesartan",
  "Carbamazepine",
  "Carbimazole",
  "Carbocisteine",
  "Carmellose sodium",
  "Carvedilol",
  "Cefalexin",
  "Cetirizine",
  "Champix (varenicline)",
  "Chloramphenicol",
  "Chlorhexidine",
  "Chlorphenamine (Piriton)",
  "Cinnarizine",
  "Ciprofloxacin",
  "Citalopram",
  "Clarithromycin",
  "Clobetasol",
  "Clobetasone",
  "Clonazepam",
  "Clopidogrel",
  "Clotrimazole",
  "Clotrimazole cream, spray and solution",
  "Co-amoxiclav",
  "Co-beneldopa",
  "Co-careldopa",
  "Co-codamol",
  "Co-codaprin (aspirin and codeine)",
  "Co-dydramol",
  "Codeine",
  "Colchicine",
  "Cyanocobalamin",
  "Cyclizine",
  "Dabigatran",
  "Dapagliflozin",
  "Dexamethasone eye drops",
  "Dexamethasone tablets and liquid",
  "Diazepam",
  "Diclofenac",
  "Digoxin",
  "Dihydrocodeine",
  "Diltiazem",
  "Diphenhydramine",
  "Dipyridamole",
  "Docusate",
  "Domperidone",
  "Donepezil",
  "Dosulepin",
  "Doxazosin",
  "Doxycycline",
  "Duloxetine",
  "Edoxaban",
  "Empagliflozin",
  "Enalapril",
  "Eplerenone",
  "Erythromycin",
  "Escitalopram",
  "Esomeprazole",
  "Ezetimibe",
  "Felodipine",
  "Fentanyl",
  "Ferrous fumarate",
  "Ferrous sulfate",
  "Fexofenadine",
  "Finasteride",
  "Flucloxacillin",
  "Fluconazole",
  "Fluoxetine (Prozac)",
  "Fluticasone inhalers",
  "Fluticasone nasal spray and drops",
  "Fluticasone skin creams",
  "Folic acid",
  "Furosemide",
  "Fusidic acid",
  "Fybogel (ispaghula husk)",
  "Gabapentin",
  "Gaviscon (alginic acid)",
  "Gliclazide",
  "Glimepiride",
  "Glyceryl trinitrate (GTN)",
  "Heparinoid",
  "Hydrocortisone",
  "Hydrocortisone buccal tablets",
  "Hydrocortisone for piles and itchy bottom",
  "Hydrocortisone for skin",
  "Hydrocortisone injections",
  "Hydrocortisone rectal foam",
  "Hydrocortisone tablets",
  "Hydroxocobalamin",
  "Hydroxychloroquine",
  "Hyoscine hydrobromide (Kwells and Joy-Rides)",
  "Ibuprofen and codeine",
  "Ibuprofen for adults (Nurofen)",
  "Ibuprofen for children",
  "Indapamide",
  "Irbesartan",
  "Isosorbide mononitrate and isosorbide dinitrate",
  "Isotretinoin capsules (Roaccutane)",
  "Isotretinoin gel (Isotrex)",
  "Ketoconazole",
  "Labetalol",
  "Lactulose",
  "Lamotrigine",
  "Lansoprazole",
  "Latanoprost",
  "Lercanidipine",
  "Letrozole",
  "Levetiracetam",
  "Levothyroxine",
  "Lidocaine",
  "Lidocaine skin cream",
  "Linagliptin",
  "Lisinopril",
  "Lithium",
  "Loperamide",
  "Loratadine (Clarityn)",
  "Lorazepam",
  "Losartan",
  "Low-dose aspirin",
  "Lymecycline",
  "Macrogol",
  "Mebendazole",
  "Mebeverine",
  "Melatonin",
  "Memantine",
  "Mesalazine",
  "Metformin",
  "Methadone",
  "Methotrexate",
  "Methylphenidate ",
  "Metoclopramide",
  "Metoprolol",
  "Metronidazole",
  "Mirabegron",
  "Mirtazapine",
  "Molnupiravir (Lagevrio)",
  "Mometasone for skin",
  "Mometasone inhalers",
  "Mometasone nasal spray",
  "Montelukast",
  "Morphine",
  "Naproxen",
  "Nefopam",
  "Nicorandil",
  "Nifedipine",
  "Nitrofurantoin",
  "Nortriptyline",
  "Nystatin",
  "Olanzapine",
  "Olmesartan",
  "Omeprazole",
  "Oxybutynin",
  "Oxycodone",
  "Pantoprazole",
  "Paracetamol for adults",
  "Paroxetine",
  "Paxlovid",
  "Peppermint oil",
  "Pepto-Bismol (bismuth subsalicylate)",
  "Perindopril",
  "Phenoxymethylpenicillin",
  "Phenytoin",
  "Pioglitazone",
  "Pravastatin",
  "Prednisolone tablets and liquid",
  "Pregabalin",
  "Prochlorperazine",
  "Promethazine (Phenergan)",
  "Propranolol",
  "Pseudoephedrine (Sudafed)",
  "Quetiapine",
  "Rabeprazole",
  "Ramipril",
  "Ranitidine",
  "Remdesivir (Veklury)",
  "Risedronate",
  "Risperidone",
  "Rivaroxaban",
  "Ropinirole",
  "Rosuvastatin",
  "Salbutamol inhaler",
  "Saxagliptin",
  "Senna",
  "Sertraline",
  "Sildenafil (Viagra)",
  "Simeticone",
  "Simvastatin",
  "Sitagliptin",
  "Sodium cromoglicate capsules",
  "Sodium cromoglicate eye drops",
  "Sodium valproate",
  "Solifenacin",
  "Sotalol",
  "Sotrovimab (Xevudy)",
  "Spironolactone",
  "Sulfasalazine",
  "Sumatriptan",
  "Tadalafil",
  "Tamsulosin",
  "Temazepam",
  "Terbinafine",
  "Thiamine (vitamin B1)",
  "Ticagrelor",
  "Timolol eye drops",
  "Timolol tablets",
  "Tolterodine",
  "Topiramate",
  "Tramadol",
  "Tranexamic acid",
  "Trazodone",
  "Trimethoprim",
  "Valproic acid",
  "Valsartan",
  "Varenicline",
  "Venlafaxine",
  "Verapamil",
  "Warfarin",
  "Zolpidem",
  "Zopiclone",
];
