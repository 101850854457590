<template>
  <div>
    <h1 class="font-normal text-2xl mb-2">Confirm your password</h1>
    <p class="mb-2">Reenter your password to confirm deletion.</p>
    <form
      @submit.prevent="
        confirmPasswordService.send({ type: 'SUBMIT', data: $event })
      "
    >
      <input
        id="password"
        name="password"
        type="password"
        autocomplete="current-password"
        required
        class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cobalt-500 focus:outline-none focus:ring-cobalt-500 sm:text-sm"
      />
      <!-- <PasswordInput new-password :show-label="false" /> -->
      <template v-if="currentConfirmPasswordState.matches('incorrectPassword')">
        <p class="text-sm text-rose-500 mb-0 mt-4">
          Your password was incorrect
        </p>
      </template>
      <template
        v-if="currentConfirmPasswordState.matches('unableToDeleteAccount')"
      >
        <p class="text-sm text-rose-500 mb-0 mt-4">
          You've entered the
          <strong>correct</strong>
          password but we were unable to delete your profile. Please contact
          customer service and we will be able to manually delete your profile
          for you.
        </p>
      </template>
      <SubmitButton
        class="bg-rose-500 hover:bg-rose-500"
        :class="{
          loading: currentConfirmPasswordState.matches('deletingAccount'),
        }"
        icon="trash-2"
      >
        Confirm deletion
      </SubmitButton>
    </form>
    <!-- <SubmitButton
            v-else-if="currentConfirmPasswordState.matches('checkingInput')"
            class="bg-rose-500 hover:bg-rose-500 loading"
            icon="trash-2"
        >
            Confirm deletion
        </SubmitButton>
        <SubmitButton v-else class="bg-grey-500" icon="trash-2" disabled>
            Confirm deletion
        </SubmitButton> -->
    <router-link
      :to="{ name: 'account-view' }"
      class="text-center text-grey-500 font-semibold mt-4 block"
    >
      Cancel
    </router-link>
  </div>
</template>

<script>
import axios from "axios";
import { Machine, interpret, assign } from "xstate";
import { checkPassword } from "@/stores/profile";
// import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";
import SubmitButton from "@/components/SubmitButton.vue";
import endpoints from "@/endpoints";
import { mapStores } from "pinia";
import { useProfileStore } from "@/stores/profile";

export default {
  components: {
    SubmitButton, //PasswordInput
  },
  props: {
    account: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapStores(useProfileStore),
    profileStore() {
      return useProfileStore();
    },
  },
  created() {
    // states: idle, checkingPassword, passwordIncorrect, deletingAccount, unableToDeleteAccount, deletedProfile

    const logout = this.$auth.logout;

    const confirmPasswordMachine = Machine(
      {
        id: "confirm-password",
        initial: "idle",
        context: {
          password: "",
        },
        states: {
          idle: {
            on: {
              SUBMIT: "checkingInput",
            },
          },
          checkingInput: {
            invoke: {
              src: "checkPassword",
              onDone: "deletingAccount",
              onError: "incorrectPassword",
            },
          },
          deletingAccount: {
            invoke: {
              src: "deleteAccount",
              onDone: "deletedProfile",
              onError: "unableToDeleteAccount",
            },
          },
          unableToDeleteAccount: {
            entry: (_, event) => console.error(event),
          },
          deletedProfile: {
            entry: () => logout(),
          },
          incorrectPassword: {
            on: {
              SUBMIT: "checkingInput",
            },
          },
        },
      },
      {
        services: {
          checkPassword: async (context, event) =>
            // eslint-disable-next-line
            new Promise(async (resolve, reject) => {
              const data = new FormData(event.data.target);
              const password = data.get("password");
              try {
                await checkPassword(this.$auth.user().email, password);
                resolve();
              } catch (e) {
                reject();
              }
            }),
          async deleteAccount() {
            await axios.delete(endpoints.account);
          },
        },
        actions: {
          cachePassword: assign({
            password: (context, event) => {
              return event.data.target.value;
            },
          }),
          navigateToSuccessPage: () => {
            this.$router.push({
              name: "received-deletion",
              params: { barcode: this.profile.barcode },
            });
          },
        },
        guards: {
          hasPasswordValue: (context, event) => event.data.length,
        },
      }
    );
    this.confirmPasswordService = interpret(confirmPasswordMachine).start();
    this.currentConfirmPasswordState = confirmPasswordMachine.initialState;
    this.confirmPasswordService.onTransition((state) => {
      this.currentConfirmPasswordState = state;
    });
  },
  data() {
    return {
      confirmPasswordService: undefined,
      currentConfirmPasswordState: undefined,
    };
  },
};
</script>
