<template>
  <div>
    <div class="block font-semibold //text-gray-700 text-sm">
      Day sample taken
    </div>
    <BaseSelect :options="activationDays" v-model="day" />
  </div>

  <div>
    <div class="block font-semibold //text-gray-700 text-sm mb-1">
      Time sample taken
    </div>
    <div class="flex space-x-4">
      <div class="w-1/2">
        <BaseSelect
          label="Hour"
          reduce-label
          :options="activationHours"
          v-model="hour"
        />
      </div>
      <div class="w-1/2">
        <BaseSelect
          label="Minutes"
          reduce-label
          :options="activationMinutes"
          v-model="minutes"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({ modelValue: String });
const emit = defineEmits(["update:modelValue"]);

function getRoundedMinutes(date) {
  const minutes = date.getMinutes();

  if (minutes < 15) {
    return "00";
  }
  if (minutes < 30) {
    return "15";
  }
  if (minutes < 45) {
    return "30";
  }
  return "45";
}

const sampleTakenTime = ref(new Date());
const day = ref(sampleTakenTime.value.toString());
const hour = ref(sampleTakenTime.value.getHours());
const minutes = ref(getRoundedMinutes(sampleTakenTime.value));

watch([day, hour, minutes], () => {
  const d = new Date(day.value);
  d.setHours(hour.value);
  d.setMinutes(minutes.value);
  emit("update:modelValue", d.toISOString().replace("Z", ""));
});

const activationDays = ref(
  [...Array(5).keys()].map((item, idx) => {
    const d = new Date();
    d.setDate(d.getDate() - idx);
    return {
      value: d.toString(),
      label: d.toLocaleDateString("en-GB", {
        day: "numeric",
        weekday: "long",
        month: "long",
      }),
    };
  })
);

const activationHours = [
  { label: "00", value: "00" },
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
];

const activationMinutes = [
  { label: "00", value: "00" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
];
</script>
