<template>
  <div class="block rounded bg-white shadow overflow-hidden h-full">
    <div class="fade-bottom h-48 overflow-hidden">
      <AppImage
        class="object-cover object-center h-48 w-full blur-sm grayscale"
        :public-id="patientStore.nextBloodTest.image"
      />
    </div>

    <div class="p-6">
      <div class="flex items-center space-x-4">
        <div class="text-lg">{{ patientStore.nextBloodTest.name }}</div>
      </div>
      <div class="mb-4">
        <div class="flex items-center space-x-2 text-sm text-grey-600">
          <AppIcon
            icon="calendar"
            :classes="['h-4 w-4']"
            :containerClasses="['inline w-4 h-4']"
          />
          <span>
            Due
            {{ patientStore.nextBloodTest.dueDate.toLocaleDateString("en-GB") }}
          </span>
        </div>
      </div>
      <p>
        Have you made lifestyle and wellbeing changes based on the results from
        your previous test? Discover what these changes have done for the parts
        of your body you cannot see and order your next blood test. For the best
        view of your overall health order a new test every 3 - 4 months.
      </p>
      <div class="flex items-center text-cobalt-500 font-semibold mt-4">
        <span>Click here to order</span>
        <AppIcon icon="chevron-right" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePatientStore } from "@/stores/patient";

const patientStore = usePatientStore();
</script>
