<template>
  <div class="bg-white rounded shadow p-6 flex flex-col">
    <div>
      Test due: {{ patientStore.nextBloodTest.name }}
      <br />
      Due Date:
      {{ patientStore.nextBloodTest.dueDate.toLocaleDateString("en-GB") }}
    </div>
    <router-link
      :to="{ name: 'store-index' }"
      class="text-cobalt-500 rounded font-semibold flex items-center mt-auto pt-2"
    >
      Order next test
      <AppIcon icon="arrow-right" class="ml-2" />
    </router-link>
  </div>
</template>

<script setup>
import { usePatientStore } from "@/stores/patient";

const patientStore = usePatientStore();
</script>
