<template>
  <div
    v-if="mode === 'large'"
    class="flex flex-col items-center //space-x-4 //mt-auto"
  >
    <div class="rounded-full h-36 w-36 overflow-hidden">
      <AppImage
        width="300"
        class="object-cover h-full w-full"
        :public-id="doctor.photo"
      />
    </div>
    <div class="text-center mt-2">
      <img :src="signature" alt="Dr Ali Salamat's Signature" class="h-10" />
      <div class="whitespace-nowrap text-sm">{{ doctor.name }}</div>
      <div class="whitespace-nowrap text-sm">
        {{ doctor.title }}
      </div>
    </div>
  </div>
  <div v-else class="flex items-center space-x-4">
    <div
      class="rounded-full overflow-hidden"
      :class="mode === 'small' ? 'h-12 w-12' : 'h-24 w-24'"
    >
      <!-- <img class="object-cover h-full w-full" :src="doctor.photo" alt="" /> -->
      <AppImage
        width="100"
        class="object-cover h-full w-full"
        :public-id="doctor.photo"
      />
    </div>
    <div>
      <img
        v-if="mode !== 'small'"
        class="h-10"
        :src="signature"
        alt="Dr Ali Salamat's Signature"
      />
      <div class="text-sm">{{ doctor.name }}</div>
      <div class="text-sm">{{ doctor.title }}</div>
    </div>
  </div>
</template>

<script setup>
import signature from "@/assets/images/dr-ali-signature.png";

defineProps({
  mode: String,
});

const doctor = {
  photo: "pm-dashboard/AD1I9170",
  name: "Dr Ali Salamat",
  title: "Medical Director",
};
//eslint-disable-next-line
// defineProps({
//   doctor: Object,
// });
</script>
