<template>
  <div
    class="flex flex-col lg:flex-row items-start lg:items-center max-lg:gap-4 justify-between max-lg:pb-6"
  >
    <div class="flex-none flex items-center text-sm" style="width: 230px">
      <img class="h-5 w-5 mr-2" :src="result.trait_icon[0].url" alt="" />
      {{ result.trait_name }}
    </div>

    <div>
      <div class="flex gap-2 mb-1">
        <div
          v-for="(i, index) in 3"
          :key="i"
          class="h-4 w-4 border-2"
          :class="
            getStageMatch(result.result_value) == index
              ? getResultColor(result.interpretation).main
              : ''
          "
        />
      </div>
      <div
        class="text-center font-semibold"
        :class="getResultColor(result.interpretation).text"
      >
        {{ result.interpretation }}
      </div>
    </div>
    <div
      v-if="result.trait_subcategory[0].category[0].numeric_results"
      class="font-semibold text-center whitespace-nowrap lg:pl-8"
    >
      <span :class="getResultColor(result.interpretation).text">
        {{ result.result_value }}
      </span>
      <span></span>
      / 10
    </div>
    <div class="lg:px-6 text-sm">
      {{ result.short_text }}
    </div>
    <div class="flex-none">
      <router-link
        v-if="result.trait_id"
        class="text-cobalt-500 rounded font-semibold block py-2 px-1 text-center text-sm lg:w-[90px]"
        :to="{
          name: 'genetics-report',
          params: { trait: result.trait_id },
        }"
      >
        Explore
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { getStageMatch, resultColorMap } from "@/services/geneticColorMaps";

defineProps({
  result: Object,
});

function getResultColor(resultInterpretation) {
  return resultColorMap[resultInterpretation];
}
</script>
