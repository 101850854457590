<template>
  <tr
    class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b last:border-b-0 first:rounded-t-lg last:rounded-b-lg"
  >
    <td>
      <a class="pl-6 block pr-6 py-5 text-left" :href="link" target="_blank">
        {{ title }}
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  name: "FAQ",

  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.tr--hover-animate {
  transition: box-shadow ease 0.2s;
}
</style>
