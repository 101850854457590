<template>
  <form @submit.prevent="updateName" class="space-y-4">
    <h1 class="text-denim-700 font-normal text-2xl">Account name</h1>
    <p>Set the name associated with your account.</p>
    <BaseTextInput
      type="text"
      id="firstName"
      name="firstName"
      v-model="firstName"
      label="First name"
    />
    <BaseTextInput
      type="text"
      id="lastName"
      name="lastName"
      v-model="lastName"
      label="Last name"
    />
    <button
      class="block bg-cobalt-500 py-2 px-4 rounded text-white"
      :class="{ loading: saveStatus === 'saving' }"
      type="submit"
      @click="submit"
    >
      Submit
    </button>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useAuth, useAccountStore } from "@/stores/account";
import { useRouter } from "vue-router";

const props = defineProps({
  account: Object,
});

const store = useAccountStore();
const auth = useAuth();
const router = useRouter();
const saveStatus = ref("idle");

const firstName = ref(props.account.firstName);
const lastName = ref(props.account.lastName);

async function updateName() {
  await store.setAccountFields({
    account: props.account,
    fields: {
      firstName: firstName.value,
      lastName: lastName.value,
    },
  });
  await auth.fetch();
  router.push({ name: "account-view" });
}
</script>
