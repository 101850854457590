<template>
  <div v-if="loadingStatus === 'loading'">
    <PageLoader>Loading</PageLoader>
  </div>
  <AppPage v-else>
    <router-link
      :to="{
        name: 'store-details',
        params: { product: productData._id },
      }"
      class="flex items-center space-x-2 text-cobalt-500 font-semibold py-12"
    >
      <AppIcon icon="arrow-left" />
      <span>Back to {{ productData.product_name }}</span>
    </router-link>

    <h1 class="text-lg font-semibold">Choose your collection method</h1>

    <div
      v-if="availableCollectionMethods.length"
      class="md:flex md:space-x-6 max-md:space-y-6 mt-8"
    >
      <div
        v-for="method in availableCollectionMethods"
        :key="method.name"
        class="border shadow rounded bg-white p-6 md:w-1/3 flex flex-col"
      >
        <div class="space-y-6 pb-6">
          <div class="flex items-center justify-between">
            <h2 class="text-lg font-semibold">{{ method.name }}</h2>
            <img :src="method.icon[0].url" alt="" class="h-10 w-10" />
          </div>

          <hr />
          <div v-html="method.description" />
        </div>

        <div class="flex items-center space-x-4 mt-auto">
          <StoreOrderButton
            v-if="method._slug === 'selfcollectionsaliva'"
            :product="productData"
            mode="payment"
            :collection-method="method._id"
            interval="3"
          >
            <SubmitButton class="w-auto" icon="shopping-cart">
              Checkout
            </SubmitButton>
          </StoreOrderButton>
          <router-link
            v-else
            :to="{
              name:
                productData.product_type === 'Genetic Test'
                  ? 'store-genetic'
                  : 'store-cadence',
              params: { product: productData._id, method: method._id },
            }"
            class="py-2 px-6 rounded flex items-center justify-center text-white font-semibold bg-cobalt-500 hover:bg-cobalt-700"
          >
            Select
          </router-link>

          <div class="text-lg font-semibold">{{ getPrice(method) }}</div>
        </div>
      </div>
    </div>
    <div v-else class="mt-8">
      There are no suitable collection methods for this product
    </div>
  </AppPage>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { getProductData } from "@/services/store";
import StoreOrderButton from "@/components/StoreOrderButton.vue";
import SubmitButton from "@/components/SubmitButton.vue";
import { getPrice } from "@/services/store";

const { VUE_APP_AUTH_PROVIDER } = process.env;
const route = useRoute();
const productData = ref({});
const loadingStatus = ref("loading");

getProductData(route.params.product)
  .then((res) => {
    productData.value = res;
  })
  .finally(() => (loadingStatus.value = "loaded"));

const availableCollectionMethods = computed(() => {
  if (loadingStatus.value !== "loaded") {
    return [];
  }
  return productData.value.collection_methods.filter(
    (method) =>
      !(
        VUE_APP_AUTH_PROVIDER === "clerk" &&
        method._slug === "digitalcollection"
      )
  );
});
</script>
