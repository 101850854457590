import axios from "axios";

const { VUE_APP_PREPR_URL } = process.env;

const ax = axios.create();

export function getHeaderImage(productId: string) {
  return ax({
    url: VUE_APP_PREPR_URL,
    method: "post",
    data: {
      query: `
        {
          HeaderImage (id: "ec3f9550-5321-4174-b268-41a749b7830e") { 
            _id
            name
            image { 
              _id
              url
            }
          }
        }
      `,
    },
  });
}
