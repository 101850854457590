<template>
  <div>
    <slot
      :goodResponse="numberOfResultsInRange('Good')"
      :typicalResponse="numberOfResultsInRange('Typical')"
      :poorResponse="numberOfResultsInRange('Poor')"
      :awaitingResult="numberOfResultsInStatus('Awaiting Results')"
      :locked="numberOfResultsInStatus('Locked')"
      :colors="colorMap"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  geneticTest: Object,
});

const colorMap = {
  Poor: "#ef4e4e",
  Typical: "#3ebd93",
  Good: "#ef4e4e",
};

function numberOfResultsInRange(range) {
  if (typeof range === "string") {
    return props.geneticTest.result.results.filter(
      (result) => result.interpretation === range
    ).length;
  }
  if (Array.isArray(range)) {
    return props.geneticTest.result.results.filter((rangeName) =>
      range.includes(rangeName)
    ).length;
  }
}

function numberOfResultsInStatus(status) {
  return props.geneticTest.result.results.filter(
    (r) => r.result_value === status
  ).length;
}
</script>
