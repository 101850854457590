import "vue-router";
const { VUE_APP_AUTH_PROVIDER } = process.env;
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { loginImages } from "@/assets/assetMaps";

import profiles from "./profiles";
import account from "./account";
import ThePortal from "@/views/ThePortal/ThePortal.vue";
import DashboardView from "@/views/DashboardView.vue";
import PassThrough from "@/views/PassThrough.vue";
import StoreIndexView from "@/views/Store/StoreIndexView.vue";
import StoreDetailsView from "@/views/Store/StoreDetailsView.vue";
import StoreSuccessView from "@/views/Store/StoreSuccessView.vue";
import StoreChooseCollectionMethod from "@/views/Store/StoreChooseCollectionMethod.vue";
import StoreChooseCadence from "@/views/Store/StoreChooseCadence.vue";
import StoreChooseGenetic from "@/views/Store/StoreChooseGenetic.vue";
import LoginView from "@/views/Login/LoginView.vue";
import LoginEnterPassword from "@/views/Login/LoginEnterPassword.vue";
import LoginImpersonate from "@/views/Login/LoginImpersonate.vue";
import SendResetEmail from "@/views/Login/reset-password/SendResetEmail.vue";
import SendResetEmailClerk from "@/views/Login/reset-password/SendResetEmailClerk.vue";
import ResetEmailSentSuccessfully from "@/views/Login/reset-password/ResetEmailSentSuccessfully.vue";
import LoginEnterPasswordClerk from "@/views/Login/LoginEnterPasswordClerk.vue";
import RegisterView from "@/views/Register/RegisterView.vue";
import RegisterSuccess from "@/views/Register/RegisterSuccess.vue";
import RegisterSuccessClerk from "@/views/Register/RegisterSuccessClerk.vue";
import ActivationEnterCode from "@/views/Activation/ActivationEnterCode.vue";
import ActivationTodo from "@/views/Activation/ActivationTodo.vue";
import ActivationDetails from "@/views/Activation/ActivationDetails.vue";
import ActivationSuccess from "@/views/Activation/ActivationSuccess.vue";
import BloodTestPassthrough from "@/views/BloodTests/BloodTestPassthrough.vue";
import BloodTestsList from "@/views/BloodTests/BloodTestsList.vue";
import BloodTestDetails from "@/views/BloodTests/BloodTestDetails.vue";
import HealthQuestionnaire from "@/views/HealthQuestionnaire/HealthQuestionnaire.vue";
import HealthQuestionnaireSuccess from "@/views/HealthQuestionnaire/HealthQuestionnaireSuccess.vue";
import FindOutMore from "@/views/BloodTests/FindOutMore.vue";
import ConfirmAppointmentDetails from "@/views/ConfirmAppointmentDetailsView.vue";
import ConfirmAppointmentSuccess from "@/views/ConfirmAppointmentSuccessView.vue";
import CreateHealthProfileDetails from "@/views/CreateHealthProfileDetails.vue";
import CreateHealthProfileLink from "@/views/CreateHealthProfileLink.vue";
import CreateHealthProfileGoals from "@/views/CreateHealthProfileGoals.vue";
import CreateHealthProfileSuccess from "@/views/CreateHealthProfileSuccess.vue";
import VideosView from "@/views/Videos/VideosView.vue";
import TwoVialView from "@/views/Videos/TwoVialView.vue";
import OneVialView from "@/views/Videos/OneVialView.vue";
import GeneticOverviewView from "@/views/Genetics/GeneticOverviewView.vue";
import GeneticReportDetails from "@/views/Genetics/GeneticReportDetails.vue";
import GeneticReportScience from "@/views/Genetics/GeneticReportScience.vue";
import GeneticCategoryView from "@/views/Genetics/GeneticCategoryView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

// declare module "vue-router" {
//   interface RouteMeta {
//     auth: boolean;
//     scrollTopTo?: number;
//     fullWidth?: boolean;
//   }
// }

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: ThePortal,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: {
          auth: true,
        },
      },
      {
        path: "/blood-tests",
        component: BloodTestPassthrough,
        children: [
          {
            path: "",
            name: "blood-tests-list",
            component: BloodTestsList,
            meta: {
              auth: true,
            },
          },
          {
            path: ":barcode",
            name: "blood-test-details",
            component: BloodTestDetails,
            meta: {
              auth: true,
            },
          },
          {
            path: "find-out-more/:marker",
            name: "marker-find-out-more",
            component: FindOutMore,
            meta: {
              auth: true,
            },
          },
        ],
      },

      {
        path: "/store",
        name: "store",
        component: PassThrough,
        meta: {
          auth: true,
        },
        children: [
          {
            path: "",
            name: "store-index",
            component: StoreIndexView,
            meta: {
              auth: true,
            },
          },
          {
            path: "success",
            name: "store-success",
            component: StoreSuccessView,
            meta: {
              auth: true,
            },
          },
          {
            path: ":product",
            name: "store-details",
            component: StoreDetailsView,
            meta: {
              auth: true,
            },
          },
          {
            path: ":product/collection-method",
            name: "store-collection-method",
            component: StoreChooseCollectionMethod,
            meta: {
              auth: true,
            },
          },
          {
            path: ":product/:method/cadence",
            name: "store-cadence",
            component: StoreChooseCadence,
            meta: {
              auth: true,
            },
          },
          {
            path: ":product/:method/genetic",
            name: "store-genetic",
            component: StoreChooseGenetic,
            meta: {
              auth: true,
            },
          },
        ],
      },

      {
        path: "/genetics",
        name: "genetics",
        component: PassThrough,
        meta: {
          auth: true,
        },
        children: [
          {
            path: "",
            name: "genetics-index",
            component: GeneticOverviewView,
            meta: {
              auth: true,
            },
          },
          {
            path: "category/:category",
            name: "genetics-category",
            component: GeneticCategoryView,
            meta: {
              auth: true,
              dontScroll: true,
            },
          },
          {
            path: "report/:trait",
            name: "genetics-report",
            component: GeneticReportDetails,
            meta: {
              auth: true,
            },
          },
          {
            path: ":trait/science",
            name: "genetics-science",
            component: GeneticReportScience,
            meta: {
              auth: true,
            },
          },
        ],
      },

      ...profiles,
      ...account,
    ],
  },
  {
    path: "/",
    // name: "main",
    component: LoginView,
    children: [
      {
        path: "signin",
        name: "login",
        component: LoginEnterPassword,
        meta: {
          auth: false,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "impersonate",
        name: "impersonate",
        component: LoginImpersonate,
        meta: {
          auth: false,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "signin/reset-password",
        name: "reset-password",
        component:
          VUE_APP_AUTH_PROVIDER === "clerk"
            ? SendResetEmailClerk
            : SendResetEmail,
        meta: {
          image: loginImages.peopleImage,
          auth: false,
        },
      },
      {
        path: "signin/reset-email-sent",
        name: "reset-password-email-sent",
        component: ResetEmailSentSuccessfully,
        meta: {
          image: loginImages.peopleImage,
          auth: false,
        },
      },
      {
        path: "signin-clerk",
        name: "login-clerk",
        component: LoginEnterPasswordClerk,
        meta: {
          auth: false,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "signup",
        name: "signup",
        component: RegisterView,
        meta: {
          image: loginImages.peopleImage,
          auth: false,
        },
      },
      {
        path: "signup/success",
        name: "signup-success",
        component: RegisterSuccess,
        meta: {
          image: loginImages.peopleImage,
          auth: false,
        },
      },
      {
        path: "signup/successful",
        name: "signup-success-clerk",
        component: RegisterSuccessClerk,
        meta: {
          image: loginImages.peopleImage,
          auth: false,
        },
      },
      {
        path: "create-profile",
        name: "create-profile",
        component: CreateHealthProfileDetails,
        meta: {
          auth: true,
          image: loginImages.consultation,
        },
      },
      {
        path: "create-profile/link",
        name: "create-profile-link",
        component: CreateHealthProfileLink,
        meta: {
          auth: true,
          image: loginImages.consultation,
        },
      },
      {
        path: "create-profile/goals",
        name: "create-profile-goals",
        component: CreateHealthProfileGoals,
        meta: {
          auth: true,
          image: loginImages.consultation,
        },
      },
      {
        path: "create-profile/success",
        name: "create-profile-success",
        component: CreateHealthProfileSuccess,
        meta: {
          auth: true,
          image: loginImages.consultation,
        },
      },
      {
        path: "/activate",
        name: "activate",
        component: ActivationEnterCode,
        meta: {
          auth: true,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "/activate-todo",
        name: "activate-todo",
        component: ActivationTodo,
        meta: {
          auth: true,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "/activate-details",
        name: "activate-details",
        component: ActivationDetails,
        meta: {
          auth: true,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "/activate-success",
        name: "activate-success",
        component: ActivationSuccess,
        meta: {
          auth: true,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "/questionnaire/success",
        name: "questionnaire-success",
        component: HealthQuestionnaireSuccess,
        meta: {
          auth: true,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "/appointments/confirm",
        name: "appointment-confirm",
        component: ConfirmAppointmentDetails,
        meta: {
          auth: true,
          image: loginImages.peopleImage,
        },
      },
      {
        path: "/appointments/confirm-success",
        name: "appointment-confirm-success",
        component: ConfirmAppointmentSuccess,
        meta: {
          auth: true,
          image: loginImages.peopleImage,
        },
      },
    ],
  },

  {
    path: "/questionnaire",
    name: "questionnaire",
    component: HealthQuestionnaire,
    meta: {
      auth: true,
    },
  },

  {
    path: "/questionnaire/:barcode",
    name: "questionnaire-for-test",
    component: HealthQuestionnaire,
    meta: {
      auth: true,
    },
  },

  {
    path: "/videos",
    name: "videos",
    component: VideosView,
  },
  {
    path: "/videos/fingerprickblood-yellow",
    name: "videos-1-vial",
    component: OneVialView,
  },
  {
    path: "/videos/fingerprickblood-yellow-purple",
    name: "videos-2-vial",
    component: TwoVialView,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFoundView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    const el = document.querySelector("#main");
    if (el && !to.meta.dontScroll) {
      el.scrollTop = 0;
    }
  },
});

export default router;
