<template>
  <div>
    <div class="flex space-x-4 items-end font-semibold text-sm">
      <label :for="id">
        <span>{{ label }}</span>
      </label>
      <button
        @click.prevent="toggleUnit"
        class="text-sm font-semibold text-cobalt-500"
      >
        Change to {{ currentUnit === "cm" ? "ft" : "cm" }}
      </button>
    </div>

    <div class="mt-2">
      <div>
        <div
          v-if="currentUnit === 'cm'"
          class="inline-flex rounded-md shadow-sm"
        >
          <input
            v-model="cm"
            type="number"
            pattern="[0-9]*"
            name="height-cm"
            id="height-cm"
            class="block w-24 min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
            min="0"
          />
          <label
            for="height-cm"
            class="inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm w-12"
          >
            cm
          </label>
        </div>
        <div
          v-if="currentUnit === 'in'"
          class="inline-flex rounded-md shadow-sm space-x-4"
        >
          <div class="inline-flex rounded-md shadow-sm">
            <input
              v-model="feet"
              pattern="[0-9]*"
              type="number"
              name="height-ft"
              id="height-ft"
              class="block w-24 min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
              min="0"
            />
            <label
              for="height-ft"
              class="inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm w-12"
            >
              ft
            </label>
          </div>
          <div class="inline-flex rounded-md shadow-sm">
            <input
              v-model="inches"
              pattern="[0-9]*"
              type="number"
              name="height-in"
              id="height-in"
              class="block w-24 min-w-0 flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm"
              min="0"
            />
            <label
              for="height-in"
              class="inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm w-12"
            >
              in
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, toRef } from "vue";

const props = defineProps({
  label: String,
  id: String,
  name: String,
  inputWidth: String,
  modelValue: undefined,
});

const emit = defineEmits(["update:modelValue"]);

const currentUnit = ref(props.modelValue.unit);
const cm = ref(0);
const feet = ref(0);
const inches = ref(0);

if (props.modelValue.unit === "cm") {
  cm.value = toRef(props, "modelValue").value.value;
} else {
  inches.value = toRef(props, "modelValue").value.value % 12;
  feet.value = Math.floor(toRef(props, "modelValue").value.value / 12);
}

function toggleUnit() {
  if (currentUnit.value === "cm") {
    return (currentUnit.value = "in");
  }
  return (currentUnit.value = "cm");
}

watch([cm, feet, inches], () => {
  if (currentUnit.value === "cm") {
    emit("update:modelValue", { value: cm.value, unit: "cm" });
  } else if (currentUnit.value === "in") {
    emit("update:modelValue", {
      value: Number(feet.value) * 12 + Number(inches.value),
      unit: "in",
    });
  }
});
</script>
