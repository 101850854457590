<template>
  <div class="bg-white min-h-screen p-6 pt-12">
    <div v-if="account.firstName">
      <router-link
        :to="{ name: 'account-view' }"
        class="bg-white font-semibold flex items-center"
      >
        <AppIcon icon="chevron-left" class="h-5 w-5 mr-2" />

        {{ account.firstName }}'s DNA Account
      </router-link>

      <div class="mx-auto pt-16 max-w-xl">
        <router-view v-slot="{ Component }">
          <transition name="slide-left" mode="out-in">
            <component
              :is="Component"
              :account="account"
              :firstName="account.firstName"
            />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuth } from "@/stores/account";
const auth = useAuth();
const account = auth.user();
</script>
