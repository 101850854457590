<template>
  <div v-if="loadingStatus === 'loaded'" v-html="icon" class="marker-icon" />
  <div v-else />
</template>

<script setup>
import { ref } from "vue";
import { useLoading } from "@/stores/loading";

const props = defineProps({
  healthGoal: Object,
});

const { loadingStatus, finishLoading } = useLoading();
const icon = ref("");

import(`@/assets/icons/goals/${props.healthGoal.id}.js`)
  .then((i) => (icon.value = i.default))
  .then(finishLoading);
</script>

<style>
.marker-icon svg {
  @apply h-6 w-6 fill-current;
  stroke-width: 2;
}
</style>
