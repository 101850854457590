<template>
  <div class="text-center">
    <h3 class="text-denim-700 text-2xl font-normal">Check your inbox</h3>
    <p class="mt-4 leading-normal">
      We've just sent an email to
      <span class="underline">{{ accountStore.emailAddress }}</span>
      .
      <br />
      Follow the link in the email to verify
      <br />
      your account registration.
    </p>
    <div
      v-if="status === 'resend-success'"
      class="bg-emerald-50 py-2 px-4 text-emerald-500 mt-2"
    >
      Verification email resent
    </div>
    <div
      v-if="status === 'resend-failure'"
      class="bg-rose-50 py-2 px-4 text-rose-500"
    >
      Something went wrong resending the verification email
    </div>
    <div class="mt-24">
      <p class="mb-2">
        Problems receiving email?
        <button
          @click="resendVerificationEmail"
          class="text-cobalt-500 font-semibold"
          :class="{ 'text-grey-500': loading }"
          :disabled="loading"
        >
          Resend
        </button>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAccountStore } from "@/stores/account";

const accountStore = useAccountStore();
const errorMessage = ref("");
const status = ref("idle");

async function resendVerificationEmail() {
  try {
    await accountStore.resendVerificationEmail();
    status.value = "resend-success";
  } catch (e) {
    status.value = "resend-failure";
    console.error(e);
  }
}
</script>
