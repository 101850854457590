<template>
  <div>
    <h3 class="text-denim-700 text-2xl font-normal text-center">Log in</h3>
    <form @submit.prevent="logIn" class="mt-8">
      <TextareaInput label="Token" id="token" name="token" v-model="ticket" />
      <div
        v-if="errorText"
        class="bg-rose-50 text-rose-500 text-sm rounded p-4 mt-4"
      >
        {{ errorText }}
      </div>
      <button
        :class="{ loading: saveStatus === 'saving' }"
        class="mt-4 bg-cobalt-500 rounded-sm w-full py-2 px-4 text-white font-semibold test-shadow"
      >
        Log in
      </button>
    </form>
    <div class="mt-6 text-center">
      Don't have an account?
      <router-link class="text-cobalt-500 font-semibold" to="/signup">
        Sign up
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAuth } from "@/stores/account";
import { useClerk } from "@/services/clerk";
import { useRouter, useRoute } from "vue-router";
import TextareaInput from "@/components/QuestionnaireFields/TextareaInput.vue";

const clerk = useClerk();
const route = useRoute();

const ticket = ref(route.query.token ? route.query.token : "");
const saveStatus = ref("idle");
const errorText = ref("");

let redirect;

const auth = useAuth();
const router = useRouter();

async function logIn() {
  saveStatus.value = "saving";
  errorText.value = "";
  try {
    const result = await clerk.value.signIn.create({
      strategy: "ticket",
      ticket: ticket.value,
    });

    const res = await clerk.value.setActive({
      session: result.createdSessionId,
    });

    router.push({ name: "dashboard" });
  } catch (error) {
    console.error(error);
  }
}
</script>

<!-- 0ed13fae-789e-4974-8c10-c998adc8b096 -->
<!-- user_2NNFGNg0z9AeKNyQAjygRs7YTYg -->
