<template>
  <div>
    <div class="max-w-5xl m-auto px-4 py-12">
      <div class="flex items-center font-semibold text-cobalt-500 space-x-2">
        <AppIcon icon="arrow-left" />
        <button @click="back">Go back</button>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

function back() {
  router.go(-1);
}
</script>
