<template>
  <div class="rounded bg-white shadow">
    <div class="md:flex max-md:space-y-2 justify-between p-6 border-b">
      <div class="flex items-center space-x-2">
        <AppIcon icon="helix" />
        <div>Genetic Test</div>
        <!-- <span
          class="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800"
        >
          {{ test.instance }} test
        </span> -->
      </div>
      <div>
        <div class="flex items-center space-x-2 text-base text-grey-700">
          <AppIcon icon="calendar" />
          <span>
            Updated
            {{ new Date(test.result.issue_time).toLocaleDateString("en-GB") }}
          </span>
        </div>
      </div>
    </div>
    <div class="p-6">
      <GeneticTrafficLights
        :genetic-test="test"
        v-slot="{
          goodResponse,
          typicalResponse,
          poorResponse,
          awaitingResult,
          locked,
        }"
      >
        <div class="flex max-md:flex-col gap-4 md:gap-8">
          <div class="flex flex-col gap-4">
            <div class="flex items-center">
              <div class="h-5 w-5 rounded-full mr-2 bg-tangerine-500"></div>
              {{ poorResponse }} x unfavorable response
            </div>
            <div class="flex items-center">
              <div class="h-5 w-5 rounded-full mr-2 bg-pacific-500"></div>
              {{ typicalResponse }}
              x typical response
            </div>
            <div class="flex items-center">
              <div class="h-5 w-5 rounded-full mr-2 bg-emerald-500"></div>
              {{ goodResponse }}
              x favorable response
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <div v-if="awaitingResult" class="flex items-center gap-2">
              <AppIcon icon="loader" class="text-cobalt-500" />
              {{ awaitingResult }}
              x awaiting result
            </div>
            <div v-if="locked" class="flex items-center gap-2">
              <AppIcon icon="lock" />
              {{ locked }}
              x locked
            </div>
          </div>
        </div>
      </GeneticTrafficLights>
      <div class="space-y-6 mt-8">
        <!-- <div v-html="test.result.medicalReportSummary"></div> -->

        <DoctorDetails />

        <router-link
          :to="{
            name: 'genetics-index',
          }"
          class="font-semibold text-cobalt-500 mt-4 flex items-center space-x-3"
        >
          View test
          <AppIcon icon="chevron-right" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useResultsStore } from "@/stores/results";
import DoctorDetails from "@/components/DoctorDetails.vue";
import GeneticTrafficLights from "../GeneticTrafficLights.vue";

const resultsStore = useResultsStore();

defineProps({
  test: Object,
});
</script>
