<template>
  <div class="text-center">
    <h3 class="text-denim-700 text-2xl font-normal">Reset password</h3>
    <p>
      Enter the email address associated with your account, and we'll email you
      a link to reset your password
    </p>
    <form @submit.prevent="sendResetEmail">
      <BaseTextInput
        v-model="email"
        label="email"
        type="email"
        class="mt-6"
        id="email"
        hide-label
      />
      <button
        :class="{
          loading: loading,
          'bg-grey-200 text-grey-500': !readyToSubmit,
          'bg-cobalt-500 text-white': readyToSubmit,
        }"
        :disabled="!readyToSubmit"
        class="mt-4 bg-cobalt-500 rounded w-full py-2 px-4 text-white font-semibold test-shadow"
      >
        Send reset link
      </button>
    </form>
    <div class="mt-4">
      <router-link to="/signin" class="text-grey-500">Cancel</router-link>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import endpoints from "@/endpoints";

const email = ref("");
const loading = ref(false);
const errors = ref([]);

const readyToSubmit = true;

async function sendResetEmail() {
  loading.value = true;
  axios
    .post(endpoints.accountResetPassword, {
      email: email.value,
    })

    .then(() => {
      loading.value = false;
      // this.$router.push({
      //   name: "reset-password-email-sent",
      //   query: { email: this.email },
      // });
    })
    .catch((err) => {
      console.log(err.response.data.errors);
      errors.value = err.response.data.errors;
      loading.value = false;
    });
}
</script>
