<template>
  <div class="flex items-center space-x-2">
    <div class="w-24">
      <BaseTextInput
        id="day"
        type="text"
        v-model="day"
        label="Day"
        reduce-label
      />
    </div>
    <div class="flex-grow">
      <div>
        <label for="month" class="block font-semibold text-grey-600 text-sm">
          Month
        </label>
        <select
          name="month"
          id="month"
          v-model="month"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-cobalt-500 focus:ring-cobalt-500 sm:text-sm mt-2"
        >
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
    </div>
    <div class="w-24">
      <BaseTextInput
        id="year"
        type="text"
        v-model="year"
        label="Year"
        reduce-label
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const props = defineProps({
  modelValue: String,
});
const emit = defineEmits(["update:modelValue"]);

const year = ref(null);
const month = ref(null);
const day = ref(null);

if (props.modelValue) {
  const [orignalYear, originalMonth, originalDay] = props.modelValue.split("-");
  year.value = orignalYear;
  month.value = originalMonth;
  day.value = originalDay;
}

// if (props.modelValue.value) {
//   const [originalYear, originalMonth, originalDay] =
//     props.value.modelValue.split("-");
//   year.value = originalYear;
//   month.value = originalMonth;
//   day.value = originalDay;
// }

const fullDate = computed(() => {
  if (year.value && month.value && day.value) {
    let paddedDay = day.value;

    if (paddedDay.length < 2) {
      paddedDay = `0${day.value}`;
    }
    console.log(paddedDay);
    return `${year.value}-${month.value}-${paddedDay}`;
  }
  return null;
});

watch(fullDate, () => {
  // console.log(fullDate.value);
  if (fullDate.value) {
    console.log("emitting", fullDate.value);
    emit("update:modelValue", fullDate.value);
  }
});
</script>
