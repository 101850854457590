<template>
  <PageLoader v-if="loadingStatus === 'loading'">Loading</PageLoader>
  <AppPage v-else stack>
    <div>
      <div
        class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24"
      >
        <div class="lg:col-start-2">
          <h1 class="text-sm font-semibold text-cobalt-500">
            Payment successful
          </h1>
          <p
            class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl"
          >
            Thanks for ordering
          </p>
          <p class="mt-6 text-base text-gray-500">
            We appreciate your order, we’re currently processing it. So hang
            tight and we’ll send you confirmation very soon!
          </p>

          <div
            class="mt-2 text-base text-gray-500"
            v-html="collectionMethodData.post_order_text"
          />

          <dl v-if="checkoutData.invoice" class="mt-8 text-sm font-medium">
            <dt class="text-gray-900">Order number</dt>
            <dd class="mt-2 text-cobalt-500">
              {{ checkoutData.invoice.split("_")[1] }}
            </dd>
          </dl>

          <ul
            role="list"
            class="mt-6 divide-y divide-gray-200 border-t border-gray-200 font-medium text-gray-500"
          >
            <li class="flex space-x-6 py-6">
              <img
                :src="productData.thumbnail_image[0].url"
                alt="Model wearing men&#039;s charcoal basic tee in large."
                class="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
              />
              <div class="flex-auto space-y-1">
                <h3 class="text-gray-900">
                  {{ productData.product_name }}
                </h3>
              </div>
              <p class="flex-none font-medium text-gray-900">
                {{
                  checkoutData.mode === "payment"
                    ? getPrice(productData)
                    : getSubscriptionPrice(productData)
                }}
              </p>
            </li>

            <!-- More products... -->
          </ul>

          <dl
            class="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500"
          >
            <div class="flex justify-between">
              <dt>Subtotal</dt>
              <dd class="text-gray-900">
                {{
                  checkoutData.mode === "payment"
                    ? getPrice(productData)
                    : getSubscriptionPrice(productData)
                }}
              </dd>
            </div>

            <div class="flex justify-between">
              <dt>Collection method</dt>
              <dd class="text-gray-900">
                {{ getPrice(collectionMethodData) }}
              </dd>
            </div>

            <div
              class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900"
            >
              <dt class="text-base">Total</dt>
              <dd class="text-base">
                {{ getCurrency(checkoutData.currency)
                }}{{ (checkoutData.amount_total / 100).toFixed(2) }}
              </dd>
            </div>
          </dl>

          <dl class="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
            <!-- <div>
              <dt class="font-medium text-gray-900">Shipping Address</dt>
              <dd class="mt-2">
                <address class="not-italic">
                  <span class="block">Kristin Watson</span>
                  <span class="block">7363 Cynthia Pass</span>
                  <span class="block">Toronto, ON N3Y 4H8</span>
                </address>
              </dd>
            </div> -->
            <!-- <div>
              <dt class="font-medium text-gray-900">Payment Information</dt>
              <dd class="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0"> 
                <div class="flex-none">
                  <svg
                    aria-hidden="true"
                    width="36"
                    height="24"
                    viewBox="0 0 36 24"
                    class="h-6 w-auto"
                  >
                    <rect width="36" height="24" rx="4" fill="#224DBA" />
                    <path
                      d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                      fill="#fff"
                    />
                  </svg>
                  <p class="sr-only">Visa</p>
                </div>
                <div class="flex-auto">
                  <p class="text-gray-900">Ending with 4242</p>
                  <p>Expires 12 / 21</p>
                </div>
              </dd>
            </div> -->
          </dl>

          <div class="mt-16 border-t border-gray-200 py-6 text-right">
            <router-link
              :to="{ name: 'dashboard' }"
              class="font-semibold text-cobalt-500 hover:text-cobalt-700 inline-flex items-center space-x-2"
            >
              <span>Back to dashboard</span>
              <AppIcon icon="arrow-right" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </AppPage>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import {
  getProductData,
  getPrice,
  getSubscriptionPrice,
} from "@/services/store";
import { useLoading } from "@/stores/loading";
import { makeHealthRequest } from "@/services/healthBackend";

const route = useRoute();
const productData = ref({});
const collectionMethodData = ref({});
const checkoutData = ref({});
const { loadingStatus, finishLoading } = useLoading();

function getCurrency(key) {
  return {
    usd: "$",
    gbp: "£",
  }[key];
}

makeHealthRequest({
  path: `/checkout/${route.query.session_id}`,
  method: "GET",
}).then((res) => {
  console.log(res);
  checkoutData.value = res.data;
  getProductData(route.query.product_id)
    .then((res) => {
      productData.value = res;
      collectionMethodData.value = productData.value.collection_methods[0];
    })
    .then(finishLoading);
});
</script>
