type CollectionMethod = "FPK" | "VPU" | "VPB";

interface ParsedSku {
  provider: string;
  testType: string;
  collectionMethod: CollectionMethod;
  version: string;
  region: string;
}

export function parseSku(sku: string): ParsedSku {
  const [provider, rest, region] = sku.split("-");
  return {
    provider,
    testType: rest.substring(0, 3),
    collectionMethod: rest.substring(3, 6) as CollectionMethod,
    version: rest.substring(6, 8),
    region,
  };
}
