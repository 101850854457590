<template>
  <div>
    <fieldset class="space-y-5 mt-2">
      <label
        class="relative flex items-center border rounded p-4 cursor-pointer"
        :class="
          isSelected(option)
            ? 'border-cobalt-50 bg-cobalt-50 text-cobalt-500 '
            : 'border-grey-50 bg-grey-50 text-grey-700 hover:border-cobalt-500 hover:bg-cobalt-50 hover:text-cobalt-500 focus:border-cobalt-500 focus:bg-cobalt-50 focus:text-cobalt-500'
        "
        v-for="option in options"
        :key="option.id"
      >
        <input
          v-model="selected"
          :id="option.id"
          :name="option.id"
          :value="option.id"
          type="checkbox"
          class="h-4 w-4 rounded border-gray-300 absolute"
          style="left: -1000px"
          @change="$emit('update:modelValue', selected)"
        />
        <AppIcon :icon="isSelected(option) ? 'check-circle' : 'circle'" />
        <div class="ml-3">
          <span class="font-medium cursor-pointer">
            {{ option.title }}
          </span>
          <p class="text-gray-500" v-if="option.description">
            {{ option.description }}
          </p>
        </div>
      </label>
    </fieldset>
    <div class="flex justify-end mt-8 space-x-6">
      <button
        @click="cancel"
        class="rounded py-2 px-6 font-semibold bg-grey-200 text-grey-700"
      >
        Cancel
      </button>
      <button
        @click="$emit('modalClosed')"
        class="rounded py-2 px-6 font-semibold bg-cobalt-500 text-white"
      >
        Save changes
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  options: Array,
  modelValue: undefined,
});

const emit = defineEmits(["modalClosed", "update:modelValue"]);

const original = Object.freeze(props.modelValue);
const selected = ref(props.modelValue);

function isSelected(option) {
  return selected.value.includes(option.id);
}

function cancel() {
  emit("update:modelValue", original);
  emit("modalClosed");
}
</script>
