<template>
  <router-view />
</template>

<script setup>
import { clerkLoaded } from "@/services/clerk";
import { useRouter } from "vue-router";
import { useAuth } from "./stores/account";
import { useFavicon, useTitle } from "@vueuse/core";

const { VUE_APP_AUTH_PROVIDER } = process.env;

useFavicon(
  VUE_APP_AUTH_PROVIDER === "clerk" ? "/favicon-wp.png" : "/favicon.ico"
);
useTitle(
  VUE_APP_AUTH_PROVIDER === "clerk" ? "DNA Workplace" : "Living DNA - Health"
);

const router = useRouter();

const auth = useAuth();

router.beforeEach(async (to) => {
  if (VUE_APP_AUTH_PROVIDER === "clerk") {
    await clerkLoaded;
    const user = auth.user();
    if (to.meta.auth === true && !user) {
      return { name: "login" };
    }
    if (to.meta.auth === false && user) {
      return { name: "dashboard" };
    }
  }
});
</script>
